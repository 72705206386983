import { apiClient } from "./ApiClient";
import UrlConstants from "../../constants/UrlConstants";
import { handleResponse, handleError } from "./GenericResponseHandler";

class NotificationService {

    getNotifications(page) {
        let url = UrlConstants.GET_NOTIFICATIONS.replace(/{page}/, page);

        return apiClient()
            .get(url)
            .then(handleResponse)
            .catch(handleError);
    }

    markNotificationAsRead(id) {
        let url = UrlConstants.MARK_NOTIFICATION_AS_READ.replace(/{id}/, id);

        return apiClient()
            .put(url)
            .then(handleResponse)
            .catch(handleError);
    }
    
    markAllRead() {
        let url = UrlConstants.MARK_ALL_READ

        return apiClient()
            .put(url)
            .then(handleResponse)
            .catch(handleError);
    }

}

export default new NotificationService();