import { SELF_REGULATION_ACTIONS } from "../../constants/ActionConstants";

let initialState = {
    loading: false,
    regulations: [],
    currentRegulation: {}
};

const regulation = (state = initialState, action) => {

    switch (action.type) {
        case SELF_REGULATION_ACTIONS.ADD_SELF_REGULATION:
            return {
                ...state,
                loading: true,
            };

        case SELF_REGULATION_ACTIONS.ADD_SELF_REGULATION_SUCCESS:
            return {
                ...state,
                loading: false
            };

        case SELF_REGULATION_ACTIONS.ADD_SELF_REGULATION_FAILURE:
            return {
                ...state,
                loading: false,
            };
        case SELF_REGULATION_ACTIONS.GET_SELF_REGULATION:
            return {
                ...state,
                loading: true,
            };

        case SELF_REGULATION_ACTIONS.GET_SELF_REGULATION_SUCCESS:
            return {
                ...state,
                loading: false,
                regulations: action.payload.data
            };

        case SELF_REGULATION_ACTIONS.GET_SELF_REGULATION_FAILURE:
            return {
                ...state,
                loading: false,
            };
        case SELF_REGULATION_ACTIONS.VIEW_SELF_REGULATION:
            return {
                ...state,
                loading: true,
            };

        case SELF_REGULATION_ACTIONS.VIEW_SELF_REGULATION_SUCCESS:
            return {
                ...state,
                loading: false,
                currentRegulation: action.payload.data
            };

        case SELF_REGULATION_ACTIONS.VIEW_SELF_REGULATION_FAILURE:
            return {
                ...state,
                loading: false,
            };

        default:
            return state;
    }
};

export default regulation;
