import { request, success, failure } from ".";
import { GET_NOTIFICATIONS_ACTIONS, MARK_NOTIFICATION_AS_READ_ACTIONS } from "../../constants/ActionConstants";
import NotificationService from "../../services/api/NotificationService";

export const getNotifications = (page, onSuccess) => {
    return async (dispatch) => {
        if (page === 1) {
            dispatch(request(GET_NOTIFICATIONS_ACTIONS.GET_NOTIFICATIONS_CLEAR_OLD_DATA, {}));
        } else {
            dispatch(request(GET_NOTIFICATIONS_ACTIONS.GET_NOTIFICATIONS, {}));
        }
        try {
            const response = await NotificationService.getNotifications(page);
            console.log("Notifications Response:");
            console.log(response);
            dispatch(success(GET_NOTIFICATIONS_ACTIONS.GET_NOTIFICATIONS_SUCCESS, { data: response.data }));
            onSuccess(response.data);
        } catch (error) {
            dispatch(failure(GET_NOTIFICATIONS_ACTIONS.GET_NOTIFICATIONS_FAILURE, {}));
        }
    };
}

export const markNotificationAsRead = (id, index) => {
    return async (dispatch) => {
        try {
            const response = await NotificationService.markNotificationAsRead(id);
            console.log("Mark Notification Read Response:");
            console.log(response);
            dispatch(success(MARK_NOTIFICATION_AS_READ_ACTIONS.MARK_NOTIFICATION_AS_READ_SUCCESS, { index: index }));
        } catch (error) {
            console.log(error);
        }
    };
}

export const markAllRead = () => {
    return async (dispatch) => {
        try {
            const response = await NotificationService.markAllRead();
            console.log("Mark Notification Read Response:");
            console.log(response);
            dispatch(success(MARK_NOTIFICATION_AS_READ_ACTIONS.MARK_ALL_READ_SUCCESS, {}));
        } catch (error) {
            console.log(error);
        }
    };
}
