import moment from "moment";
import { format, formatDistance, isToday } from "date-fns";


class DateHelper {
  formatDate = (date) => {
    console.log("Date for conversion", date);
    return moment.utc(date).local().format("MM/DD/YYYY")
  };
  formatDateSimple = (date) => {
    console.log("Date for conversion", date);
    return moment.utc(date).local().format("MMM DD,YYYY")
  };

  formatDateForNotifications(date) {
    const givenDate = new Date(date);
    let result = isToday(givenDate);
    if (result) {
      return formatDistance(givenDate, new Date(), { addSuffix: true })
    } else {
      return this.formatDate(givenDate, 'MMM dd, hh:mm aaa');
    }
  }
}

export default new DateHelper();
