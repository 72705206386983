import React from 'react';
import RouteConstants from './constants/RouteConstants';
import CustomerUser from './views/users/CustomerUser';
import ImageGallery from './views/imageGallery/ImageGallery';
import Activities from './views/activity/Activities';
import AddActivity from './views/activity/AddActivity';
import EditActivity from './views/activity/EditActivity';

import SelfRegulationList from './views/selfRegulation/SelfRegulationList';
import AddSelfRegulation from './views/selfRegulation/AddSelfRegulation';
import EditSelfRegulation from './views/selfRegulation/EditSelfRegulation';
import Payments from './views/payments/Payments';
import ViewPayment from './views/payments/ViewPayment';

import MyAccount from './views/myAccount/myAccount';


// const Dashboard = React.lazy(() => import('./views/dashboard/Dashboard'));

const Users = React.lazy(() => import('./views/users/Users'));
const CustomerUserDetail = React.lazy(() => import('./views/users/CustomerUserDetail'));
const AddUser = React.lazy(() => import('./views/users/AddUser'));
const EditUser = React.lazy(() => import('./views/users/EditUser'));

const routes = [
  { path: '/', exact: true, name: 'Home' },
  // { path: '/home/dashboard', name: 'Dashboard', component: Dashboard },
  

  { path: RouteConstants.USER_LIST, exact: true, name: 'Users', component: Users },
  { path: RouteConstants.CUSTOMER_USER, exact: true, name: 'Users', component: CustomerUser },
  { path: RouteConstants.IMAGE_LIB, exact: true, name: 'Image Gallery', component: ImageGallery },
  { path: RouteConstants.ACTIVITY, exact: true, name: 'Activities', component: Activities },
  
  { path: RouteConstants.ADD_ACTIVITY, exact: true, name: 'Add Activity', component: AddActivity },
  { path: RouteConstants.EDIT_ACTIVITY, exact: true, name: 'Edit Activity', component: EditActivity },
  { path: RouteConstants.MY_ACCOUNT, exact: true, name: 'My Account', component: MyAccount },
 
  { path: RouteConstants.SELF_REGULATION, exact: true, name: 'Emotion Regulation', component: SelfRegulationList },
  { path: RouteConstants.ADD_SELF_REGULATION, exact: true, name: 'Add Emotion Regulation', component: AddSelfRegulation },
  { path: RouteConstants.EDIT_SELF_REGULATION, exact: true, name: 'Edit Emotion Regulation', component: EditSelfRegulation },
 
  { path: RouteConstants.USER_ADD, exact: true, name: 'Add User', component: AddUser },
  { path: RouteConstants.CUSTOMER_USER_DETAIL, exact: true, name: 'Edit User', component: CustomerUserDetail },
  { path: RouteConstants.USER_DETAIL, exact: true, name: 'User Details', component: EditUser },


  { path: RouteConstants.PAYMENTS, exact: true, name: 'Payments', component: Payments },
  { path: RouteConstants.VIEW_PAYMENTS, exact: true, name: 'View Payment', component: ViewPayment },

];

export default routes;
