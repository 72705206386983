import { CModal, CModalBody, CModalFooter, CModalHeader, CButton } from "@coreui/react";
import React, { useState } from "react";

const Confirmation = (props) => {

  const [modal, setModal] = useState(true);

  const yesButtonClicked = () => {
    props.yesButtonClicked();
  }

  const noButtonClicked = () => {
    props.noButtonClicked();
  }

  // const toggle = () => {
  //     setModal(!modal);
  // }

  return (
    <CModal
      show={modal}
      onClose={() => noButtonClicked()}
    >
      <CModalHeader closeButton >Thrive</CModalHeader>
      <CModalBody>
        {props.body}
      </CModalBody>
      <CModalFooter className="footer-has-primary-secondary-btn">
        <CButton color="primary" onClick={() => yesButtonClicked()} >Yes</CButton>{' '}
        <CButton
          color="secondary"
          onClick={() => noButtonClicked()}
        >No</CButton>
      </CModalFooter>
    </CModal>

  );
};

export default Confirmation;
