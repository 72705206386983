import { ADD_USER, GET_EXTERNAL_USER, GET_USER, REMOVE_USER, UPDATE_USER, UPDATE_USER_STATUS } from "../../constants/ActionConstants";

let initialState = {
    loading: false,
    userAdminList: {}
};

const user = (state = initialState, action) => {

    switch (action.type) {
        case ADD_USER.ADD_USER:
            return {
                ...state,
                loading: true,
            };

        case ADD_USER.ADD_USER_SUCCESS:
            return {
                ...state,
                loading: false
            };

        case ADD_USER.ADD_USER_FAILURE:
            return {
                ...state,
                loading: false,
            };

        case GET_USER.GET_USER:
            return {
                ...state,
                loading: true,
            };

        case GET_USER.GET_USER_SUCCESS:
            return {
                ...state,
                loading: false,
                userAdminList: action.payload.data
            };

        case GET_USER.GET_USER_FAILURE:
            return {
                ...state,
                loading: false,
            };

        case GET_EXTERNAL_USER.GET_EXTERNAL_USER:
            return {
                ...state,
                loading: true,
            };

        case GET_EXTERNAL_USER.GET_EXTERNAL_USER_SUCCESS:
            return {
                ...state,
                loading: false,
                userAdminList: action.payload.data
            };

        case GET_EXTERNAL_USER.GET_EXTERNAL_USER_FAILURE:
            return {
                ...state,
                loading: false,
            };

        case UPDATE_USER.UPDATE_USER:
            return {
                ...state,
                loading: true,
            };

        case UPDATE_USER.UPDATE_USER_SUCCESS:
            return {
                ...state,
                loading: false
            };

        case UPDATE_USER.UPDATE_USER_FAILURE:
            return {
                ...state,
                loading: false,
            };

        case UPDATE_USER_STATUS.UPDATE_USER_STATUS:
            return {
                ...state,
                loading: true,
            };

        case UPDATE_USER_STATUS.UPDATE_USER_STATUS_SUCCESS:
            return {
                ...state,
                loading: false
            };

        case UPDATE_USER_STATUS.UPDATE_USER_STATUS_FAILURE:
            return {
                ...state,
                loading: false,
            };

        case REMOVE_USER.REMOVE_USER:
            return {
                ...state,
                loading: true,
            };

        case REMOVE_USER.REMOVE_USER_SUCCESS:
            return {
                ...state,
                loading: false
            };

        case REMOVE_USER.REMOVE_USER_FAILURE:
            return {
                ...state,
                loading: false,
            };




        default:
            return state;
    }
};

export default user;
