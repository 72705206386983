import React, { useState } from 'react'
import {
    CToast,
    CToastBody,
    CToastHeader,
    CToaster,
    CLabel,
    CModal,
    CModalHeader,
    CModalBody,
    CRow,
    CModalFooter,
    CButton,
} from '@coreui/react'

const LeavePage = ({openModal, handleModal, leaveThis }) => {


    const performOp=()=>{
        leaveThis()
        handleModal()
    }

    return (
        <>

            <CModal className="confirmPopup" show={openModal} onClose={() => handleModal()} size="md">
                <CModalHeader closeButton><h2>Are you sure you want to perform this action?</h2></CModalHeader>
                <CModalBody>
                    <CRow>
                        <CLabel style={{margin:10}} >All information you have entered will be lost.</CLabel>
                    </CRow>
                </CModalBody>
                <CModalFooter className="footer-has-primary-secondary-btn">
                    <CButton color="primary" onClick={() => performOp()} >Yes, leave this page</CButton>{' '}
                    <CButton color="primary" onClick={() => handleModal()} >Cancel</CButton>{' '}

                </CModalFooter>
            </CModal>



        </>
    )
}

export default LeavePage
