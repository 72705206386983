import {
    CModal, CModalBody, CModalFooter, CModalHeader, CButton,
    CCard,
    CCardBody,
    CCardHeader,
    CCol,
    CRow,
    CInput,
    CAlert,
    CImg,
    CInputCheckbox,
    CInputFile,
    CLabel,
} from "@coreui/react";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { validateMandatoryValue, validateTextLength } from "src/helpers/Validation";
import { addImageLib, getPresignedURL, uploadImage } from "src/store/actions/ImageLib";
import { removeUser } from "src/store/actions/User";
import imageCompression from 'browser-image-compression';
import Loader from "src/views/common/Loader/Loader";
import Cropper from 'react-easy-crop';
import { getCroppedImg } from "src/helpers/ImageCrop";
import LeavePage from "src/views/common/LeavePageModal";
import { useTranslation } from "react-i18next";
import { getPaymentStatus, getTimeInMS, getUserStatus } from "src/helpers/GenericHelper";
import imgPlaceholder from "src/assets/images/image-placeholder.svg"
import DateHelper from "src/helpers/DateHelper";
import CIcon from "@coreui/icons-react";
import downloadIcon from "src/assets/images/download.svg";
import { downloadInvoice } from "src/store/actions/Payment";

const InvoicePopup = ({ openModal, handleModal, setApiRes, data }) => {

    const dispatch = useDispatch();


    const { t } = useTranslation();

    const [apiError, setApiError] = useState("");
    const [apiSuccess, setApiSuccess] = useState("");


    useEffect(() => {

        return () => {

        }
    }, [openModal])

    const downloadInvoiceNow = (data) => {
        // alert(JSON.stringify(data))
        setApiError("")
        setApiSuccess("")
        dispatch(downloadInvoice(data.txnId, onSuccess, onFailure));

    }

    const onSuccess = (res) => {
        // alert("Success")
        // alert(JSON.stringify(res.data))
        const bs64 = Buffer.from(res.data).toString('base64')
        // alert(bs64)
        // var blobGot= new Blob([res.data], { type: 'application/pdf' })

        // const linkSource = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${response.data}`;
        const linkSource = `data:application/pdf;base64,${bs64}`;
        const downloadLink = document.createElement("a");
        const fileName = "Invoice "+data.name+" "+DateHelper.formatDate(data.paymentDate);
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
        setApiSuccess("Payment Invoice downloaded successfully.")
        setApiError("")
        // const url = window.URL.createObjectURL(blobGot);
        // const link = document.createElement('a');
        // link.href = url;
        // // link.download="abc111.pdf"
        // link.setAttribute('download', 'abc111.pdf');
        // document.body.appendChild(link);
        // link.click();
        // window.navigator.msSaveBlob(blobGot, "abc111.pdf");
    }

    const onFailure = (errorMessage) => {
        setApiError(errorMessage)
        setApiSuccess("")

    }

    return (
        <CModal
            show={openModal}
            onClose={handleModal}
            closeOnBackdrop={false}
            size="lg"
        >
            <CModalHeader><h2>{t("paymentInvoice")}</h2>
                <CButton onClick={handleModal}>X</CButton>

            </CModalHeader>
            <CModalBody>


                {apiError &&
                    <CAlert show={3} color="danger">
                        {apiError}
                    </CAlert>
                }
                {apiSuccess &&
                    <CAlert show={3} color="success">
                        {apiSuccess}
                    </CAlert>
                }

                

                <CRow>
                    <CCol>
                        <h3>{data && data.name}</h3>
                    </CCol>

                    <CCol style={{ display: "flex", justifyContent: "flex-end" }}>
                        <CButton color="primary"><img className="download-btn" src={downloadIcon}
                            onClick={() => {
                                downloadInvoiceNow(data)
                            }}
                        /></CButton>
                        {/* <CIcon name="cil-pencil"></CIcon> */}
                    </CCol>

                </CRow>
                <br></br>
                <br></br>
                <CRow>
                    <CCol>
                        <CRow>
                            <CCol>
                                <CLabel><strong>{t("dueDate")}</strong></CLabel>
                            </CCol>
                            <CCol>
                                <CLabel>{data && data.dueDate ? DateHelper.formatDate(data.dueDate) : "No any"}</CLabel>
                            </CCol>
                        </CRow>
                    </CCol>
                    <CCol>
                        <CRow>
                            <CCol>

                                <CLabel><strong>{t("transDate")} </strong></CLabel>
                            </CCol>
                            <CCol>
                                <CLabel>{data && data.paymentDate ? DateHelper.formatDate(data.paymentDate) : "No any"}</CLabel>
                            </CCol>
                        </CRow>

                    </CCol>
                </CRow>
                <br></br>
                <CRow>
                    <CCol>
                        <CRow>
                            <CCol>
                                <CLabel><strong>{t("subPlan")}</strong></CLabel> <br />
                            </CCol>
                            <CCol>
                                <CLabel>{data && data?.planType.charAt(0).toUpperCase() + data?.planType.slice(1).toLowerCase()}</CLabel>
                            </CCol>
                        </CRow>

                    </CCol>
                    <CCol>
                        <CRow>
                            <CCol>
                                <CLabel><strong>{t("paymentMethod")} </strong></CLabel> <br />
                            </CCol>
                            <CCol>
                                <CLabel>Stripe</CLabel>
                            </CCol>
                        </CRow>

                    </CCol>
                </CRow>
                <br></br>
                <hr></hr>

                <CRow>
                    <CCol>
                        <CLabel><strong>{t("subCharges")}</strong></CLabel> <br />
                    </CCol>
                    <CCol>
                        <CLabel>$ {data && data?.subscriptionAmount}</CLabel>
                    </CCol>
                </CRow>



                <hr></hr>

                <CRow>
                    <CCol>
                        <CLabel><strong>{t("totalPaid")}</strong></CLabel> <br />
                    </CCol>
                    <CCol>
                        <CLabel>$ {data && data?.totalAmount}</CLabel>
                    </CCol>
                </CRow>
            </CModalBody>
            <CModalFooter className="footer-has-primary-secondary-btn ml-1rem mr-1rem">
                {/* <CButton color="primary" onClick={() => { }} > {t("add")} </CButton>{' '} */}
                <CButton color="primary" onClick={() => handleModal()} > {t("cancel")} </CButton>{' '}

            </CModalFooter>
        </CModal>

    );
};

export default InvoicePopup;
