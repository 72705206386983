import React, { useState, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CRow,
  CButton,
  CInput,
  CAlert,
} from '@coreui/react'
import DataTablePayments from './components/DataTablePayments'
import RouteConstants from 'src/constants/RouteConstants'
import { useTranslation } from "react-i18next";
import { PRIV_CONSTS } from 'src/constants/StaticData'
import { getEditPermValue, getViewPermValue } from 'src/helpers/PermissionCalculator'
import { useSelector } from 'react-redux'


const Payments = () => {
  const history = useHistory()
  const location = useLocation()
  const queryPage = useLocation().search.match(/page=([0-9]+)/, '')
  const currentPage = Number(queryPage && queryPage[1] ? queryPage[1] : 1)
  const [page, setPage] = useState(currentPage)
  const [searchText, setSearchText] = useState("")

  const [viewAccess, setViewAccess] = useState(false)
  const [viewEditAccess, setViewEditAccess] = useState(false)
  const user = useSelector((state) => state.authentication.user);


  const { t } = useTranslation();

  useEffect(() => {
    if (user && user.accounts) {
      let viewPerm = getViewPermValue(PRIV_CONSTS.PAYMENT_PRIV)
      let editPerm = getEditPermValue(PRIV_CONSTS.PAYMENT_PRIV)
      setViewAccess(viewPerm)
      setViewEditAccess(editPerm)

    }


  }, [])

  useEffect(() => {
    currentPage !== page && setPage(currentPage)
  }, [currentPage, page])

  return (
    <CRow>
      <CCol xl={12}>
        <CCard>
          <CCardHeader>
            <CRow className="align-items-center">
              <CCol sm="5" xs="12">
                <h5 className="page-title py-2">{t("payments")}</h5>
              </CCol>
              {/* <small className="text-muted"> example</small> */}
              <CCol className="search-n-btn" sm="7" xs="12">
                <CInput
                  placeholder="Search"
                  size="sm"

                  onKeyUp={(e) => {
                    if (e.key === 'Enter') {
                      setSearchText(e.target.value)
                    }
                    if (e.key === 'Backspace') {
                      if (e.target.value === "")
                        setSearchText(e.target.value)
                    }
                  }}
                  size="md"
                />

              </CCol>
            </CRow>
            <CRow className="align-items-center">
              <CCol sm="5" xs="12">
                {/* <CButton
                  variant="outline" color="primary" className="my-2">{t("extractreport")}</CButton> */}
              </CCol>
              <CCol className="search-n-btn" sm="7" xs="12">

              
              </CCol>
            </CRow>

          </CCardHeader>
          <CCardBody className="payments-section">
            {location.state && location.state.msg ?
              <CAlert show={5} color="success">
                {location.state.msg}
              </CAlert>
              : null}
            {viewAccess ? <DataTablePayments searchTextGot={searchText} editAccess={viewEditAccess}/> : null}
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  )
}

export default Payments
