import { apiClient } from "./ApiClient";
import UrlConstants from "../../constants/UrlConstants";
import { handleResponse, handleError, handleResponseCode } from "./GenericResponseHandler";

class AuthenticationService {

    login(email, password) {
        let url = UrlConstants.LOGIN;
        const payload = {
            emailAddress: email,
            password,
        };
        return apiClient()
            .post(url, payload)
            .then(handleResponse)
            .catch(handleError);
    }

    setAdminPassword(password, confirmPassword, code) {
        let url = UrlConstants.SET_PASSWORD;
        const payload = {
            confirmationCode: code,
            password: password,
            confirmPassword: confirmPassword
        };
        return apiClient()
            .post(url, payload)
            .then(handleResponse)
            .catch(handleError);
    }

    verifyCode(payload) {
        let url = UrlConstants.VERIFY_CODE;

        return apiClient()
            .post(url, payload)
            .then(handleResponseCode)
            .catch(handleError);
    }


    registerDeviceForNotification(playerId) {
        let url = UrlConstants.REGISTER_DEVICE_FOR_NOTIFICATIONS;

        const payload = {
            deviceIdentifier: "deviceIdentifier",
            notficationIdentifier: playerId,
            deviceModel: "deviceModel",
            deviceOS: "deviceSystemName",
            deviceVersion: "deviceSystemVersion"
        };

        return apiClient()
            .post(url, payload)
            .then(handleResponse)
            .catch(handleError);
    }


    logout() {
        let url = UrlConstants.LOGOUT;

        return apiClient()
            .post(url, {})
            .then(handleResponse)
            .catch(handleError);
    }


}

export default new AuthenticationService();