import React, { useEffect, useState } from 'react'
import usersData from '../UsersData'
import {
  CBadge,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CDataTable,
  CRow,
  CPagination,
  CButton,
  CInput,
  CAlert,
  CSwitch,
  CInputCheckbox,
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { useHistory, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getCustomerUsers, getSearchedCustomerUsers, getSortedCustomerUsers, getSearchedSortedCustomerUsers } from 'src/store/actions/Customer'
import { updateUserCustomerStatus, updateUserStatus } from 'src/store/actions/User'
import { APP_CONSTANTS } from 'src/constants/GenericConstants'
import { getUserStatus } from 'src/helpers/GenericHelper'
import RouteConstants from 'src/constants/RouteConstants'
import Loader from 'src/views/common/Loader/Loader'
import disable from '../../../assets/images/user-disable.svg';
import DateHelper from 'src/helpers/DateHelper'
import PlaceHolder from 'src/views/common/PlaceHolder'
import Confirmation from 'src/views/common/Confirmation'
import { getEditPermValue } from 'src/helpers/PermissionCalculator'
import { PRIV_CONSTS } from 'src/constants/StaticData'
import { useTranslation } from "react-i18next";



const getBadge = status => {
  switch (status) {
    case 'Active': return 'success'
    case 'InActive': return 'secondary'
    case 'Disabled': return 'secondary'
    case 'Pending': return 'warning'
    case 'Banned': return 'danger'
    default: return 'primary'
  }
}

const DataTableCustomerUser = ({ searchTextGot }) => {

  const dispatch = useDispatch();
  const history = useHistory()

  const { t } = useTranslation();

  const userState = useSelector((state) => state.customer);
  const [userData, setUserData] = useState([]);

  const [page, setPage] = useState(1)
  const [apiError, setApiError] = useState("")

  const [selectedUser, setSelectedUser] = useState();

  const [fieldG, setFieldG] = useState("")
  const [orderG, setOrderG] = useState("")

  const [closeModal, setCloseModal] = useState(false);
  const [confirmationDialogText, setConfirmationDialogText] = useState(t("changeCustStatus"));

  const [updateApiMsg, setUpdateApiMsg] = useState("")

  const [userViewEditAccess, setUserViewEditAccess] = useState(false)



  const [sortingFields, setSortingFields] = useState({
    firstName: "no",
    lastName: "no",
    emailAddress: "no",
    createdAt: "no",
    status: "no",
    childCount: "no"
  });


  const pageChange = newPage => {
    setPage(newPage)
    if (searchTextGot === "") {
      if (fieldG !== "" && orderG !== "") {
        dispatch(getSortedCustomerUsers(newPage, APP_CONSTANTS.USER_LIST_COUNT, fieldG, orderG, onGetUserSuccess, onGetUserFailure));
      }
      else {
        dispatch(getCustomerUsers(newPage, APP_CONSTANTS.USER_LIST_COUNT, onGetUserSuccess, onGetUserFailure));

      }
    }
    else {
      if (fieldG !== "" && orderG !== "") {
        dispatch(getSearchedSortedCustomerUsers(newPage, APP_CONSTANTS.USER_LIST_COUNT, searchTextGot, fieldG, orderG, onGetUserSuccess, onGetUserFailure));
      }
      else {
        dispatch(getSearchedCustomerUsers(newPage, APP_CONSTANTS.USER_LIST_COUNT, searchTextGot, onGetUserSuccess, onGetUserFailure));
      }
    }
  }


  useEffect(() => {

    if (searchTextGot !== null && searchTextGot !== undefined && searchTextGot !== "") {
      setPage(1)
      dispatch(getSearchedCustomerUsers(1, APP_CONSTANTS.USER_LIST_COUNT, searchTextGot, onGetUserSuccess, onGetUserFailure));
    }
    else if (searchTextGot === "") {
      setPage(1)
      dispatch(getCustomerUsers(1, APP_CONSTANTS.USER_LIST_COUNT, onGetUserSuccess, onGetUserFailure));
    }
    return () => {
    }

  }, [searchTextGot])

  useEffect(() => {

    let userEditPerm = getEditPermValue(PRIV_CONSTS.PARENT_PRIV)
    setUserViewEditAccess(userEditPerm)


    dispatch(getCustomerUsers(page, APP_CONSTANTS.USER_LIST_COUNT, onGetUserSuccess, onGetUserFailure));
    return () => {
    }
  }, [])

  useEffect(() => {
    setUserData(userState.customerList.data)
    return () => {
    }
  }, [userState])

  const onGetUserSuccess = (message) => {

  }

  const onGetUserFailure = (errorMessage) => {

  }

  const updateUsetStatus = (user) => {

    setSelectedUser(user)
    setCloseModal(true)

  }

  const onUpdateUserSuccess = (message) => {
    var statusG = selectedUser.status == 2 ? "disabled" : "enabled"
    setUpdateApiMsg(t("customerStatusSuccess").replace("---",statusG))

    if (searchTextGot === "") {
      if (fieldG !== "" && orderG !== "") {
        dispatch(getSortedCustomerUsers(page, APP_CONSTANTS.USER_LIST_COUNT, fieldG, orderG, onGetUserSuccess, onGetUserFailure));
      }
      else {
        dispatch(getCustomerUsers(page, APP_CONSTANTS.USER_LIST_COUNT, onGetUserSuccess, onGetUserFailure));

      }
    }
    else {
      if (fieldG !== "" && orderG !== "") {
        dispatch(getSearchedSortedCustomerUsers(page, APP_CONSTANTS.USER_LIST_COUNT, searchTextGot, fieldG, orderG, onGetUserSuccess, onGetUserFailure));
      }
      else {
        dispatch(getSearchedCustomerUsers(page, APP_CONSTANTS.USER_LIST_COUNT, searchTextGot, onGetUserSuccess, onGetUserFailure));
      }
    }

  }

  const onUpdateUserFailure = (errorMessage) => {
    setApiError(errorMessage);
  }

  const applySorter = (columnName, sorttype) => {
    if (columnName) {
      console.log("columnName 1", columnName);
      const field = columnName;
      setPage(1)
      setFieldG(field)
      setOrderG(sorttype)
      if (searchTextGot === "") {
        dispatch(getSortedCustomerUsers(1, APP_CONSTANTS.USER_LIST_COUNT, field, sorttype, onGetUserSuccess, onGetUserFailure));
      }
      else {
        dispatch(getSearchedSortedCustomerUsers(1, APP_CONSTANTS.USER_LIST_COUNT, searchTextGot, field, sorttype, onGetUserSuccess, onGetUserFailure));
      }
    }
    else {
      setPage(1)
      if (searchTextGot !== null && searchTextGot !== undefined && searchTextGot !== "") {
        setPage(1)
        dispatch(getSearchedCustomerUsers(1, APP_CONSTANTS.USER_LIST_COUNT, searchTextGot, onGetUserSuccess, onGetUserFailure));
      }
      else if (searchTextGot === "") {
        setPage(1)
        dispatch(getCustomerUsers(1, APP_CONSTANTS.USER_LIST_COUNT, onGetUserSuccess, onGetUserFailure));
      }
    }
  }

  const sortFunc = (colName, e) => {
    var fields = sortingFields;
    Object.keys(fields).forEach(e => {
      fields[e] = "no"
    });
    let order = "";
    if (e.target.value === "no") {
      order = "ASC"
    }
    else if (e.target.value === "ASC") {
      order = "DESC"
    }
    else if (e.target.value === "DESC") {
      order = "no"
    }

    fields[colName] = order
    setSortingFields(fields)
    applySorter(order === "no" ? "" : colName, order === "no" ? "" : order)
  }

  const yesButton = () => {


    setUpdateApiMsg("")
    console.log("selectedUser", selectedUser);


    const status = selectedUser.status == 2 ? 3 : 2
    dispatch(updateUserCustomerStatus(selectedUser, status, onUpdateUserSuccess, onUpdateUserFailure));
    setCloseModal(false)

  }

  const noButtonClicked = () => {
    setCloseModal(false)
  }


  return (

    <div>
      {apiError &&
        <CAlert color="danger">
          {apiError}
        </CAlert>
      }

      {closeModal &&
        <Confirmation body={t("changeCustStatus")} yesButtonClicked={yesButton} noButtonClicked={noButtonClicked} />
      }

      {updateApiMsg &&
        <CAlert show={5} color="success">
          {updateApiMsg}
        </CAlert>
      }

      {
        userState.customerList.data &&
        <div className="table-responsive">

          <table className="table table-striped table-hover">
            <tr>
              <th>{t("firstNameTable")} <CButton 
              className={sortingFields.firstName==="no"? "check-no": sortingFields.firstName==="ASC" ? "check-asc": sortingFields.firstName==="DESC"? "check-desc" :"check-no" } 
              value={sortingFields.firstName} onClick={(e) => { sortFunc("firstName", e) }}
              >{sortingFields.firstName}</CButton> </th>
              <th>{t("lastNameTable")} <CButton className={sortingFields.lastName==="no"? "check-no": sortingFields.lastName==="ASC" ? "check-asc": sortingFields.lastName==="DESC"? "check-desc" :"check-no" } value={sortingFields.lastName} onClick={(e) => { sortFunc("lastName", e) }}
              >{sortingFields.lastName}</CButton></th>
              <th>{t("emailAddressTable")} <CButton className={sortingFields.emailAddress==="no"? "check-no": sortingFields.emailAddress==="ASC" ? "check-asc": sortingFields.emailAddress==="DESC"? "check-desc" :"check-no" } value={sortingFields.emailAddress} onClick={(e) => { sortFunc("emailAddress", e) }}
              >{sortingFields.emailAddress}</CButton></th>
              <th>{t("dateRegTable")} <CButton className={sortingFields.createdAt==="no"? "check-no": sortingFields.createdAt==="ASC" ? "check-asc": sortingFields.createdAt==="DESC"? "check-desc" :"check-no" } value={sortingFields.createdAt} onClick={(e) => { sortFunc("createdAt", e) }}
              >{sortingFields.createdAt}</CButton></th>
              <th>{t("accountStatusTable")}<CButton className={sortingFields.status==="no"? "check-no": sortingFields.status==="ASC" ? "check-asc": sortingFields.status==="DESC"? "check-desc" :"check-no" } value={sortingFields.status} onClick={(e) => { sortFunc("status", e) }}
              >{sortingFields.status}</CButton></th>
              <th>{t("childProfileTable")}
                </th>
              <th> </th>

            </tr>
            {userState.customerList.data && userState.customerList.data.map(item => {
              return (

                <tr>

                  <td>{item.firstName}</td>
                  <td>{item.lastName}</td>
                  <td>{item.emailAddress}</td>
                  <td>{DateHelper.formatDate(item.createdDate)}</td>
                  <td>
                    <CBadge color={getBadge(getUserStatus(item.status))}>
                      {getUserStatus(item.status)}
                    </CBadge>
                  </td>
                  <td>{item.childCount}</td>

                  <td className="has-action-icons">
                    <div className="multi-icons">
                      <CIcon name="cil-pencil"
                        onClick={(e) => {
                          history.push({
                            pathname: RouteConstants.CUSTOMER_USER_DETAIL,
                            user: item
                          });
                        }}
                      />

                      {item.status === 1 ?
                        <CSwitch
                          className="mr-2"
                          color="success"
                          shape="pill"
                          size="sm"
                          defaultChecked
                          checked={false}
                          disabled={true}

                        /> : null}

                      {item.status === 2 ?
                        <CSwitch
                          className="mr-2"
                          color="success"
                          onClick={() => updateUsetStatus(item)}
                          shape="pill"
                          size="sm"
                          defaultChecked
                          disabled={!userViewEditAccess}
                          checked={true}

                        /> : null}
                      {item.status === 3 ?
                        <CSwitch
                          className="mr-2"
                          color="success"
                          onClick={() => updateUsetStatus(item)}
                          shape="pill"
                          size="sm"
                          disabled={!userViewEditAccess}
                          checked={false}
                          defaultChecked={false}
                        /> : null}
                    </div>
                  </td>

                </tr>

              )

            })}

            {userState.customerList.data.length === 0 ?
              <tr> <td colspan="7"> <PlaceHolder title={t("noUserFound")} ></PlaceHolder></td> </tr>
              : null}



          </table>



          <CPagination
            dots={true}
            limit={6}
            activePage={page}
            onActivePageChange={pageChange}
            pages={userState.customerList.meta.pageCount}
            doubleArrows={true}
            align="center"
          />

        </div>
      }
      {userState.loading ? <Loader /> : null}

    </div>
  )
}

export default DataTableCustomerUser
