import { LOGIN_ACTIONS, SIGN_UP_ACTIONS, CONFIRM_EMAIL_ACTIONS, SIGN_OUT_ACTIONS, SET_PASSWORD_ACTIONS, LOGOUT_ACTIONS, VERIFY_CODE_ACTIONS } from "../../constants/ActionConstants";
import { LOCAL_STORAGE_KEYS } from '../../constants/GenericConstants';

const userDetails = localStorage.getItem(LOCAL_STORAGE_KEYS.USER) ? JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.USER)) : {};

let initialState = {
  loading: false,
  user: userDetails,
};

const authentication = (state = initialState, action) => {

  switch (action.type) {
    case LOGIN_ACTIONS.LOGIN:
      return {
        ...state,
        loading: true,
      };

    case LOGIN_ACTIONS.LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        user: action.payload.data,
      };

    case LOGIN_ACTIONS.LOGIN_FAILURE:
      return {
        ...state,
        loading: false,
      };

    case LOGOUT_ACTIONS.LOGOUT_ACTIONS:
      return {
        ...state,
        loading: true,
      };

    case LOGOUT_ACTIONS.LOGOUT_ACTIONS_SUCCESS:
      return {
        initialState
      };

    case LOGOUT_ACTIONS.LOGOUT_ACTIONS_FAILURE:
      return {
        ...state,
        loading: false,
      };

    case SET_PASSWORD_ACTIONS.SET_PASSWORD:
      return {
        ...state,
        loading: true,
      };

    case SET_PASSWORD_ACTIONS.SET_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        user: action.payload.data,
      };

    case SET_PASSWORD_ACTIONS.SET_PASSWORD_FAILURE:
      return {
        ...state,
        loading: false,
      };

    case VERIFY_CODE_ACTIONS.VERIFY_CODE:
      return {
        ...state,
        loading: true,
      };
    case VERIFY_CODE_ACTIONS.VERIFY_CODE_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case VERIFY_CODE_ACTIONS.VERIFY_CODE_FAILURE:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
};

export default authentication;
