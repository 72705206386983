import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CRow,
  CInput,
  CAlert,
} from '@coreui/react'

import DataTableCustomerUser from './components/DataTableCustomerUser'
import { getEditPermValue, getViewPermValue } from 'src/helpers/PermissionCalculator'
import { PRIV_CONSTS } from 'src/constants/StaticData'
import { useSelector } from 'react-redux'
import { useTranslation } from "react-i18next";



const CustomerUser = () => {

  const queryPage = useLocation().search.match(/page=([0-9]+)/, '')
  const currentPage = Number(queryPage && queryPage[1] ? queryPage[1] : 1)
  const [page, setPage] = useState(currentPage)
  const [searchText, setSearchText] = useState("")
  const location = useLocation();
  const [userViewAccess, setUserViewAccess] = useState(false)
  const [userViewEditAccess, setUserViewEditAccess] = useState(false)

  const user = useSelector((state) => state.authentication.user);

  const { t } = useTranslation();


  useEffect(() => {
    if (user.accounts) {
      let userViewPerm = getViewPermValue(PRIV_CONSTS.PARENT_PRIV)
      let userEditPerm = getEditPermValue(PRIV_CONSTS.PARENT_PRIV)
      setUserViewAccess(userViewPerm)
      setUserViewEditAccess(userEditPerm)
    }
    return () => {

    }
  }, [])


  useEffect(() => {
    currentPage !== page && setPage(currentPage)
  }, [currentPage, page])

  return (
    <CRow>
      <CCol xl={12}>
        <CCard>
          <CCardHeader className="align-items-center">
            <CRow>
              <CCol sm="5" xs="12">
                <h5 className="page-title py-2"> {t("customerAcc")} </h5>
              </CCol>
              <CCol className="search-n-btn" sm="7" xs="12">
                <CInput
                  placeholder="Search"
                  size="sm"

                  onKeyUp={(e) => {
                    if (e.key === 'Enter') {
                      setSearchText(e.target.value)
                    }
                    if (e.key === 'Backspace') {
                      if (e.target.value === "")
                        setSearchText(e.target.value)
                    }
                  }}
                  size="md"
                />
              </CCol>
            </CRow>
          </CCardHeader>
          <CCardBody>
            {location.state && location.state.msg ?
              <CAlert show={5} color="success">
                {location.state.msg}
              </CAlert>
              : null}
            {userViewAccess ?
              <DataTableCustomerUser searchTextGot={searchText} />
              : null}
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  )
}

export default CustomerUser
