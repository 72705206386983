import { CImg } from '@coreui/react'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { getImageURL } from 'src/store/actions/ImageLib';

const ImageProfile = ({ openEditModal, data }) => {

    const{id, imageTitle, categories, imageURL} = data;
    

    const dispatch = useDispatch();
    const [imgSrc, setImgSrc] = useState("https://i.stack.imgur.com/l60Hf.png");
    const [loaded, setLoaded] = useState(false);


    useEffect(() => {
       if(imageURL!== ""){
           setImgSrc(imageURL)
       }
    }, [data])

    const onGetUrl = (res) => {
        setImgSrc(res.data.url)
    }
    const onFailedUrl = (err) => {
        // alert(JSON.stringify(err))
    }

    return (
        //remove loading.. and onload event for getting the preovious work

        <figure>
            {!loaded?  <p className="loader"></p> : null}

            <CImg
                src={imgSrc}
                onLoad={
                    ()=>{
                        setLoaded(true)
                    }
                }
                alt={imageTitle}
            />
            <span onClick={() => {
                openEditModal(data)
            }} ><span className="conText">{imageTitle}</span></span>
        </figure>
    )
}

export default ImageProfile
