import { GET_CHILDREN } from "./ActionConstants";

export default {
  LOGIN: "/admin/user/login",
  LOGOUT:"/admin/user/logout",
  SET_PASSWORD: "/admin/user/setPassword",
  VERIFY_CODE: "/auth/verifyConfirmationCode",
  ADD_COADMIN: "/admin/user",
  GET_USERS: "/admin/user",

  GET_PRESIGNED_URL: "/image/",


  GET_EXTERNAL_USERS: "/admin/user/external",
  UPDATE_EXTERNAL_USER_STATUS:"/user/external/updateStatus",
  GET_CHILDREN: "/admin/user/childList/:exUserId",
  GET_PARENT_EX_USERS: "/admin/user/externalUserList/:exUserId",
  UPDATE_USER_STATUS: "/admin/user/updateStatus",
  UPDATE_USER: "/admin/user/updateUser",
  REMOVE_USER: "/admin/user/removeUser",

  GET_IMAGE_CATEGORIES:"/image/library/categories",
  GET_IMAGE_LIBRARY_LIST:"/image/library",
  ADD_IMAGE_LIB:"/image/library",
  EDIT_IMAGE_LIB:"/image/library",
  GET_IMAGE_SKINTONES: "/image/library/skin-tone",

  GET_PRESIGNED_URL: "/image/",

  ADD_ACTIVITY:"/activity",
  UPDATE_ACTIVITY:"/activity",
  GET_ACTIVITIES:"/activity/allActivities",
  GET_ACTIVITY:"/activity/",

  GET_META_DATA: "/activity/meta_data?key=activityColor",

  ADD_SELF_REGULATION:"/selfRegulation",
  UPDATE_SELF_REGULATION:"/selfRegulation",
  GET_SELF_REGULATION_LIST:"/selfRegulation/allSelfRegulation",
  GET_SELF_REGULATION:"/selfRegulation/",



  GET_NOTIFICATIONS: "/notification/adminNotification?page={page}",
  REGISTER_DEVICE_FOR_NOTIFICATIONS: "/auth/registerDevice",
  MARK_NOTIFICATION_AS_READ: "/notification/{id}/read",
  MARK_ALL_READ: "/notification/adminRead",


  GET_PAYMENT_LIST:"/subscription/admin/list",
  GET_PAYMENT_HISTORY:"/subscription/admin/payments",
  CANCEL_SUBSCRIPTION:"/subscription/admin/cancel",
  DOWNLOAD_INVOICE:"/subscription/admin/invoiceDownload",

};
