import React, { useState, useEffect } from "react";
import { CButton, CCard, CCardBody, CCardHeader, CCol, CFormGroup, CInput, CLabel, CRow, CAlert } from '@coreui/react'
import { addCoAdmin } from 'src/store/actions/User'
import { useSelector, useDispatch } from "react-redux";
import { APP_CONSTANTS } from "src/constants/GenericConstants";
import { validatePasswordValue, validateMandatoryValue } from "src/helpers/Validation";
import { TheFooter, TheHeader } from "src/containers";
import { setAdminPassword, verifyCode } from "src/store/actions/Authentication";
import { useHistory } from "react-router-dom";
import RouteConstants from "src/constants/RouteConstants";
import Loader from "src/views/common/Loader/Loader";
import logo from 'src/assets/images/admin-logo.png';

const SetPassword = (props) => {

  const dispatch = useDispatch();
  const userState = useSelector((state) => state.authentication);
  const history = useHistory();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [code, setCode] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [apiError, setApiError] = useState("");
  const [apiSuccess, setApiSuccess] = useState("");
  const [formSubmitted, setFormSubmitted] = useState(false);


  const [isCodeValid, setIsCodeValid] = useState(false);
  const [loaded, setLoaded] = useState(false);


  useEffect(() => {

    const search = props.location.search;
    const params = new URLSearchParams(search);
    var code = params.get('confirmationCode');

    if (code) {
      setCode(code);
      dispatch(verifyCode({ "confirmationCode": code}, onVerifiedSuccess, onVerifiedFailure));
    } else {
      setCode("")
    }

  }, [])

  const onVerifiedSuccess = (res) => {
    setIsCodeValid(res.data)
    setLoaded(true)
  }
  const onVerifiedFailure = (err) => {
    setIsCodeValid(false)
    alert(err)
    setApiError(err)
  }

  const handleConfirmPasswordChange = (event) => {
    const input = event.target && event.target.value ? event.target.value : "";
    setConfirmPassword(input);
    if (formSubmitted) {
      validateConfirmPassword(input)
    }
  }

  const handlePasswordChange = (event) => {
    const input = event.target && event.target.value ? event.target.value : "";
    setPassword(input);
    if (formSubmitted) {
      validatePassword(input)
    }
  }


  const onSubmit = () => {

    emptyErrorMessage();
    setFormSubmitted(true)
    const isFormValid = validateConfirmPassword(confirmPassword) & validatePassword(password);
    if (isFormValid) {
      dispatch(setAdminPassword(password, confirmPassword, code, onSetPasswordSuccess, onSetPasswordFailure))
    }

  }


  const validateConfirmPassword = (password) => {
    console.log("password", password);
    if (!validateMandatoryValue(password)) {
      setConfirmPasswordError("Please enter password");
      return false;
    } else if (!validatePasswordValue(password)) {
      console.log("password", password);
      setConfirmPasswordError("Password must be 8 to 30 characters long.");
      return false;
    } else {
      setConfirmPasswordError("");
      return true;
    }
  }

  const validatePassword = (password) => {
    if (!validateMandatoryValue(password)) {
      setPasswordError("Please enter password");
      return false;
    } else if (!validatePasswordValue(password)) {
      setPasswordError("Password must be 8 to 30 characters long.");
      return false;
    }
    else {
      setPasswordError("");
      return true;
    }

  }

  const onSetPasswordSuccess = (message) => {
    emptyErrorMessage();
    setApiSuccess(message);
    history.replace({ pathname: RouteConstants.DASHBOARD });

  }

  const onSetPasswordFailure = (errorMessage) => {
    emptyErrorMessage();
    setApiError(errorMessage);

  }

  const emptyErrorMessage = () => {
    setApiError(APP_CONSTANTS.EMPTY_STRING);
    setPasswordError(APP_CONSTANTS.EMPTY_STRING);
    setConfirmPasswordError(APP_CONSTANTS.EMPTY_STRING);
  }

  return (

    <div className="c-app ">
      <div className="c-wrapper">
        {userState.loading && <Loader />}
        <CRow>
          <CCol xs={12} className="only-logo-bar">
            <p>
              <img src={logo} />
            </p>
          </CCol>
        </CRow>
        <div className="c-body has-center-center">
          <CRow>
            {apiError &&
              <CAlert className="alert-setpass" color="danger">
                {apiError}
              </CAlert>
            }
            {apiSuccess &&
              <CAlert className="alert-setpass" color="success">
                {apiSuccess}
              </CAlert>
            }

            {!isCodeValid && loaded ?

              <p>
                Page Not Found!
              </p>
              : loaded?
              <CCol lg={12} className="d-flex justify-content-center">
                <CCard className="set-pass-card">
                  <CCardHeader>
                    <h5>Set Password</h5>
                  </CCardHeader>
                  <CCardBody>
                    <CRow>
                      <CCol xs="12">
                        <CFormGroup>
                          <CLabel htmlFor="name">Password</CLabel>
                          <CInput id="name" type="password" placeholder="Password" value={password} onChange={handlePasswordChange} />
                          {
                            passwordError && <CLabel span className="form-error-text">{passwordError}</CLabel>
                          }

                        </CFormGroup>
                      </CCol>
                      <CCol xs="12">
                        <CFormGroup>
                          <CLabel htmlFor="ccnumber">Confirm Password</CLabel>
                          <CInput id="ccnumber" type="password" placeholder="Confirm password" value={confirmPassword} onChange={handleConfirmPasswordChange} />
                          {
                            confirmPasswordError && <CLabel span className="form-error-text">{confirmPasswordError}</CLabel>
                          }

                        </CFormGroup>
                      </CCol>
                    </CRow>


                    <CRow >
                      <CCol >
                        <CButton
                          variant="outline" color="primary" style={{ float: "right", marginRight: 10 }} onClick={onSubmit}>Create User</CButton>
                      </CCol>

                    </CRow>

                  </CCardBody>
                </CCard>
              </CCol>
            :<p>...</p>}
          </CRow>
        </div>
        <TheFooter />
      </div>
    </div>



  )

}

export default SetPassword
