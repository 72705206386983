import UserService from "src/services/api/UserService";
import { request, success, failure } from ".";
import { ADD_USER, GET_USER, GET_EXTERNAL_USER, UPDATE_USER_STATUS, UPDATE_USER, REMOVE_USER, UPDATE_CUST_USER } from "../../constants/ActionConstants";


export const addCoAdmin = (firstName, lastName, email, permission, onSuccess, onFailure) => {
    return async (dispatch) => {
        dispatch(request(ADD_USER.ADD_USER, {}));
        try {
            const response = await UserService.addCoAdmin(firstName, lastName, email, permission);


            dispatch(success(ADD_USER.ADD_USER_SUCCESS, {}));
            onSuccess(response.message);
        } catch (error) {
            dispatch(failure(ADD_USER.ADD_USER_FAILURE, {}));
            onFailure(error.data.message);
        }
    };
};


export const getUsers = (page, take, onSuccess, onFailure) => {
    return async (dispatch) => {
        dispatch(request(GET_USER.GET_USER, {}));
        try {
            const response = await UserService.getUsers(page, take)
            dispatch(success(GET_USER.GET_USER_SUCCESS, { data: response.data }));
            onSuccess(response.data);
        } catch (error) {
            dispatch(failure(GET_USER.GET_USER_FAILURE, {}));
            error.data && error.data.message && onFailure(error.data.message);
        }
    };
};

export const getSearchedUsers = (page, take, q, onSuccess, onFailure) => {
    return async (dispatch) => {
        dispatch(request(GET_USER.GET_USER, {}));
        try {
            const response = await UserService.getSearchedUsers(page, take, q)
            dispatch(success(GET_USER.GET_USER_SUCCESS, { data: response.data }));
            onSuccess(response.data);
        } catch (error) {
            dispatch(failure(GET_USER.GET_USER_FAILURE, {}));
            error.data && error.data.message && onFailure(error.data.message);
        }
    };
};

export const getSortedUsers = (page, take, field, order, onSuccess, onFailure) => {
    return async (dispatch) => {
        dispatch(request(GET_USER.GET_USER, {}));
        try {
            const response = await UserService.getSortedUsers(page, take, field, order)
            dispatch(success(GET_USER.GET_USER_SUCCESS, { data: response.data }));
            onSuccess(response.data);
        } catch (error) {
            dispatch(failure(GET_USER.GET_USER_FAILURE, {}));
            error.data && error.data.message && onFailure(error.data.message);
        }
    };
};

export const getSearchedSortedUsers = (page, take, q, field, order, onSuccess, onFailure) => {
    return async (dispatch) => {
        dispatch(request(GET_USER.GET_USER, {}));
        try {
            const response = await UserService.getSearchedSortedUsers(page, take, q, field, order)
            dispatch(success(GET_USER.GET_USER_SUCCESS, { data: response.data }));
            onSuccess(response.data);
        } catch (error) {
            dispatch(failure(GET_USER.GET_USER_FAILURE, {}));
            error.data && error.data.message && onFailure(error.data.message);
        }
    };
};


export const getCustomerUsers = (page, take, onSuccess, onFailure) => {
    return async (dispatch) => {
        dispatch(request(GET_EXTERNAL_USER.GET_EXTERNAL_USER, {}));
        try {
            const response = await UserService.getCustomerUsers(page, take)

            dispatch(success(GET_EXTERNAL_USER.GET_EXTERNAL_USER_SUCCESS, { data: response.data }));
            onSuccess(response.message);
        } catch (error) {
            dispatch(failure(GET_EXTERNAL_USER.GET_EXTERNAL_USER_FAILURE, {}));
            onFailure(error.data.message);
        }
    };
};

export const updateUserStatus = (user, status, onSuccess, onFailure) => {
    return async (dispatch) => {
        dispatch(request(UPDATE_USER_STATUS.UPDATE_USER_STATUS, {}));
        try {
            const response = await UserService.updateUserStatus(user, status)

            dispatch(success(UPDATE_USER_STATUS.UPDATE_USER_STATUS_SUCCESS, { data: response.data }));
            onSuccess(response.message);
        } catch (error) {
            dispatch(failure(UPDATE_USER_STATUS.UPDATE_USER_STATUS_FAILURE, {}));
            onFailure(error.data.message);
        }
    };
};

export const updateUserCustomerStatus = (user, status, onSuccess, onFailure) => {
    return async (dispatch) => {
        dispatch(request(UPDATE_CUST_USER.UPDATE_CUST_USER, {}));
        try {
            const response = await UserService.updateUserStatus(user, status)

            dispatch(success(UPDATE_CUST_USER.UPDATE_CUST_USER_SUCCESS, { data: response.data }));
            onSuccess(response.message);
        } catch (error) {
            dispatch(failure(UPDATE_CUST_USER.UPDATE_CUST_USER_FAILURE, {}));
            onFailure(error.data.message);
        }
    };
};

export const updateUser = (userId, firstName, lastName, accountId, permissions, onSuccess, onFailure) => {
    return async (dispatch) => {
        dispatch(request(UPDATE_USER.UPDATE_USER, {}));
        try {
            const response = await UserService.updateUser(userId, firstName, lastName, accountId, permissions)
            dispatch(success(UPDATE_USER.UPDATE_USER_SUCCESS, { data: response.data }));
            onSuccess(response.message);

        } catch (error) {
            dispatch(failure(UPDATE_USER.UPDATE_USER, {}));
            onFailure(error.data.message);
        }
    };
};

export const removeUser = (userId, onSuccess, onFailure) => {
    return async (dispatch) => {
        dispatch(request(REMOVE_USER.REMOVE_USER, {}));
        try {
            const response = await UserService.removeUser(userId)
            dispatch(success(REMOVE_USER.REMOVE_USER_SUCCESS, { data: response.data }));
            onSuccess(response.message);

        } catch (error) {
            dispatch(failure(REMOVE_USER.REMOVE_USER_FAILURE, {}));
            onFailure(error.data.message);
        }
    };
};

