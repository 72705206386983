import React, { useEffect, useState } from "react";
import {CirclePicker} from 'react-color';

const SkinTonePicker = ({skinToneData, skinToneId, onSkinToneChange }) => {

    const [displayColorPicker, setDisplayColorPicker] = useState(false);
    const [color, setColor] = useState("");
    const [colors, setColors] = useState([]);
    const [selectedSkinToneId, setSelectedSkinToneId] = useState(0);

    const popover = {
        position: 'absolute',
        zIndex: '2',
    };
    const cover = {
        position: 'fixed',
        top: '0px',
        right: '0px',
        bottom: '0px',
        left: '0px',
    };
    
    useEffect(() => {
      let colors = skinToneData.map((skintone, index) => skintone.skinTone);
      setColors(colors);
      if(skinToneId) {
          setSelectedSkinToneId(skinToneId);
          let selectedSkinToneObj = skinToneData.find(skintone => skintone.id === skinToneId);
          if(selectedSkinToneObj) setColor(selectedSkinToneObj.skinTone);
      }
    }, [skinToneData])

    const handleClick = () => {
        setDisplayColorPicker(!displayColorPicker);
    };
    
    const handleClose = () => {
        setDisplayColorPicker(false);
    };

    const onColorChange = (val) => {
        setColor(val.hex);

        let selectedSkinToneObj = skinToneData.find(skintone => skintone.skinTone.toLowerCase() === val.hex.toLowerCase());
        if(selectedSkinToneObj.id == "none")
            setSelectedSkinToneId(0);  
        else 
            setSelectedSkinToneId(selectedSkinToneObj.id);
        
        if(onSkinToneChange) onSkinToneChange(selectedSkinToneObj.id);
        
        setDisplayColorPicker(false);
    } 

    return <div className="ImageLib-Select-dropdown">
        <button onClick={ handleClick }>
            <div style={{
                overflow:'hidden',
                width:'100px'
            }}>
                <div style={{
                    borderRadius:"50%", 
                    backgroundColor: color !== "" ? color: 'transparent',
                    width:"20px", height: "20px", float:'left'
                }}></div>
                {"Skin tone"}
            </div>
            {/* <ArrowDropDownIcon /> */}
        </button>
        { displayColorPicker ? <div style={ popover }>
        <div style={ cover } onClick={ handleClose }/>
            <CirclePicker colors={colors} onChange={onColorChange}/>
        </div> : null }
    </div>
}

export default SkinTonePicker;