import React, { useState, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import {
    CCard,
    CCardBody,
    CCardHeader,
    CCol,
    CRow,
    CButton,
    CInput,
    CImg,
    CInputCheckbox,
    CInputFile,
    CLabel,
    CAlert,
} from '@coreui/react'
import DataTableActivity from './components/DataTableActivity'
import RouteConstants from 'src/constants/RouteConstants'
import { useTranslation } from "react-i18next";
import AddFromImageLibModal from './components/AddFromImageLibModal'
import TaskItem from './components/TaskItem'
import { useDispatch, useSelector } from 'react-redux'
import { getCategories } from 'src/store/actions/Categories'
import Loader from '../common/Loader/Loader'
import { addActivity, getColorList } from 'src/store/actions/Activity'
import { validateActNameLength, validateMandatoryValue, validateObject } from 'src/helpers/Validation'
import { object } from 'prop-types'
import LeavePage from '../common/LeavePageModal'
import { ActivityColor } from 'src/constants/GenericConstants'
import appleWatch from "src/assets/images/applewatchgray.png"
import imgPlaceholder from "src/assets/images/image-placeholder.svg"

const Activities = () => {
    const history = useHistory()
    const location = useLocation()
    const dispatch = useDispatch()
    const [categories, setCategories] = useState([])

    const [activityName, setActivityName] = useState("")
    const [subTasks, setSubtasks] = useState([{ "id": 1, "imageId": 0, "order": 1, "image": "https://i.stack.imgur.com/l60Hf.png", "name": "", enable: true }])

    const imageLibraryState = useSelector((state) => state.imageLibrary);
    const categoriesState = useSelector((state) => state.categories);
    const activityState = useSelector((state) => state.activity);
    const user = useSelector((state) => state.authentication.user);


    const [openModalAdd, setOpenModalAdd] = useState(false)
    const [onHold, setOnHold] = useState(true)
    const [formFilled, setFormFilled] = useState(false);
    const [leaveModal, setLeaveModal] = useState(false);

    const { t } = useTranslation();

    const [actImage, setActImage] = useState({});
    const [activityColors, setActivityColors] = useState([
        // { colorCode: '#f9be02', selected: false, code: ActivityColor.YELLOW },
        // { colorCode: '#f24c4e', selected: false, code: ActivityColor.RED },
        // { colorCode: '#7cdbdf', selected: false, code: ActivityColor.GREEN_BLUE },
        // { colorCode: '#02c8a7', selected: false, code: ActivityColor.SEA_GREEN },
        // { colorCode: '#0f3171', selected: false, code: ActivityColor.DARK_BLUE },

        // // { colorCode: '#103170', selected: false, code: ActivityColor.NAVY_BLUE },
        // // { colorCode: '#6dd401', selected: false, code: ActivityColor.LIGHT_GREEN },
        // // { colorCode: '#32c5ff', selected: false, code: ActivityColor.SKY_BLUE },
        // { colorCode: '#ef661b', selected: false, code: ActivityColor.ORANGE },
        // { colorCode: '#6d7278', selected: false, code: ActivityColor.GREY },

    ]);

    const [activityColorError, setActivityColorError] = useState("");

    const [activityNameError, setactivityNameError] = useState("");
    const [subTasksError, setSubTasksError] = useState("");
    const [actImageError, setActImageError] = useState("");
    const [apiError, setApiError] = useState("");


    const [internalCheck, setInternalCheck] = useState(false)



    useEffect(() => {
        dispatch(getCategories(onSuccess, onFailure))
        dispatch(getColorList());
    }, [])

    useEffect(() => {
      if (!activityState.activityColorList) { return; }
      const updatedActivity = activityState.activityColorList.map(color => {
        return ({
          colorCode: color.value,
          selected: false,
          code: color.value
        });
      })
      setActivityColors(updatedActivity);
  }, [activityState.activityColorList])

    const onSuccess = (res) => {
        setCategories(res)
    }

    const onFailure = (err) => {

    }


    const checkChanged = () => {
        let changed = false;
        if (Object.keys(actImage).length !== 0 || activityName !== "" || subTasks.filter(i => i.enable === false).length !== 0 || (subTasks.filter(i => i.enable === true).length !== 0 && internalCheck === true)) {
            changed = true
        }

        return changed;
    }
    const handleLeaveModal = () => {
        setLeaveModal(prev => !prev)
    }

    const LeaveThisPage = () => {
        history.goBack()
    }

    const handleModalAdd = () => {
        setOpenModalAdd(prev => !prev)
    }

    const setActivityImage = (id) => {
        let imgObj = imageLibraryState.imagesList.filter((i) => i.id === id)[0];
        setActImage(imgObj)
        setOpenModalAdd(prev => !prev)
        if (formFilled) {
            validateActImage(imgObj)
        }
    }

    const validateActImage = (imgObj) => {
        if (!validateObject(imgObj)) {
            setActImageError(t("imageRequired"))
            return false;
        }
        else {
            setActImageError("")
            return true;
        }
    }

    const validateActName = (actName) => {
        if (!validateMandatoryValue(actName)) {
            setactivityNameError(t("actNameRequired"))
            return false;
        } else if (!validateActNameLength(actName)) {
            setactivityNameError(t("actNameLength"))
        }
        else {
            setactivityNameError("")
            return true;
        }
    }
    const validateSubTask = (selectedSubTasks) => {
        if (selectedSubTasks.filter(i => i.enable === false).length === 0) {
            setSubTasksError(t("oneSubtaskRequired"))
            return false;
        }
        else {
            setSubTasksError("")
            return true;
        }
    }

    const isFormValid = () => {
        setFormFilled(true)
        return validateActImage(actImage) & validateActName(activityName) & validateColor(activityColors);
        // return validateActImage(actImage) & validateActName(activityName) & validateSubTask(subTasks);
    }

    const selectColor = (index) => {
        const colorsArray = activityColors.map((color, currentIndex) => {
            let selected = false;
            if (currentIndex === index) {
                selected = true;
            }
            return {
                colorCode: color.colorCode,
                selected: selected,
                code: color.code,
            };
        });

        setActivityColors(colorsArray);
        validateColor(colorsArray);
    }

    const validateColor = (colorsArray) => {
        const selectedColor = colorsArray.filter((color) => color.selected);
        if (selectedColor.length > 0) {
            setActivityColorError("");
            return true;
        } else {
            setActivityColorError(t("actColorRequired"));
            return false;
        }
    }

    const onSubmit = () => {

        if (isFormValid()) {
            var subTasksGot = []
            subTasks.map(subT => {
                if (!subT.enable) {
                    let { id, order, name, imageId } = subT;
                    subTasksGot.push({ order: order, name, imageId })
                }
            })
            const data = {
                name: activityName,
                imageId: actImage.id,
                color: activityColors.filter((color) => color.selected)[0].colorCode,
                accountId: user.accounts[0].id,
                subTasks: subTasksGot
            }
            setApiError("")
            dispatch(addActivity(data, onAdded, onFailed))
        }


    }

    const onAdded = (message) => {
        history.replace({
            pathname: RouteConstants.ACTIVITY,
            state: { msg: message }
        })
    }
    const onFailed = (err) => {
        setApiError(err)
    }

    return (
        <CRow>

            {activityState.loading && <Loader />}


            <CCol xl={12}>
                <CCard>
                    <CCardHeader>
                        <CRow className="align-items-center">
                            <CCol sm="5" xs="12">
                                <h5 className="page-title py-2">{t("addNewActivity")}</h5>
                            </CCol>
                            {/* <small className="text-muted"> example</small> */}

                        </CRow>


                    </CCardHeader>
                    <CCardBody>
                        <CRow>
                            <CCol xl={4}>
                                <CCard>

                                    <CCardBody>

                                        <div className="profile-image-section">
                                            <figure className="appleWatch">
                                                <img className="appleWatch-container" src={appleWatch} />
                                                <CImg className="d-placeholder" alt="Child Profile Picture" src={Object.keys(actImage).length !== 0 ? actImage.imageURL : imgPlaceholder} />
                                            </figure>
                                            {
                                                actImageError && <CLabel className="field-error">{actImageError}</CLabel>
                                            }
                                            <div className="upload-photo">
                                                <div className="btn upload-btn" onClick={() => handleModalAdd()}>
                                                    {t("addImage")}
                                                </div>
                                            </div>
                                            <br></br>
                                            <div className="secDisabled">
                                                <CLabel><strong>{t("imageName")}</strong></CLabel>
                                                <CInput
                                                    placeholder="Image Name"
                                                    size="md"
                                                    className="mb-20"
                                                    value={Object.keys(actImage).length !== 0 ? actImage.imageTitle : ""}
                                                    disabled={true}
                                                />

                                                <CLabel><strong>Image Categories</strong></CLabel>

                                                <div className="round-check-filters">
                                                    {categories.length !== 0 && categories.map(cat => {
                                                        var exist = false;

                                                        if (Object.keys(actImage).length !== 0 && actImage.categories.includes(cat.name)) {
                                                            exist = true;
                                                        }

                                                        return (
                                                            <div className="check-items">
                                                                <CInputCheckbox
                                                                    id="checkbox1"
                                                                    checked={exist}
                                                                    class="btn btn-pill btn-dark mr-1"
                                                                    name={cat.name}
                                                                    value={cat.value}
                                                                />
                                                                <span>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024" role="img" className="c-check-icon">
                                                                        <path fill="var(--ci-primary-color, currentColor)" d="M910.585 151.653l-561.834 577.221-234.61-240.968c-25.801-26.538-67.544-26.538-93.441 0-25.801 26.538-25.801 69.388 0 95.926l281.056 288.611c25.801 26.538 67.544 26.538 93.344 0l608.274-624.772c25.801-26.631 25.801-70.125-0.646-96.757-24.513-25.801-66.348-25.801-92.148 0.737z" class="ci-primary"></path>
                                                                    </svg>
                                                                </span>
                                                                {cat.value}
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                            </div>
                                        </div>

                                    </CCardBody>



                                </CCard>
                            </CCol>

                            <CCol xl={8}>
                                <CCard>

                                    <CCardBody>
                                        <CLabel>{t("actName")}</CLabel>
                                        <CInput
                                            placeholder="Activity Name"
                                            size="md"
                                            value={activityName}
                                            onChange={(e) => {
                                                setActivityName(e.target.value)
                                                if (formFilled) {
                                                    validateActName(e.target.value)
                                                }
                                            }}

                                        />
                                        {
                                            activityNameError && <CLabel className="field-error">{activityNameError}</CLabel>
                                        }
                                        <br></br>

                                        <CLabel>{t("subtasks")}</CLabel>

                                        <div class="row">
                                            {subTasks.length !== 0 ? subTasks.sort((a, b) => a.order - b.order).map((sub,index) => {
                                                return (
                                                    <TaskItem
                                                        dataGot={{...sub,index}}
                                                        setChanged={setInternalCheck}
                                                        formFilled={formFilled}
                                                        validateIt={validateSubTask}
                                                        setSubtasks={setSubtasks}
                                                        subTasks={subTasks}
                                                        setOnHold={setOnHold} />
                                                )
                                            }) :

                                                <CLabel>{t("noSubtasks")}</CLabel>

                                            }
                                        </div>

                                        {
                                            subTasksError && <CLabel className="field-error">{subTasksError}</CLabel>
                                        }



                                        <CButton
                                            variant="outline" color="primary" className="my-2"
                                            disabled={onHold}
                                            onClick={() => {
                                                setSubtasks(prev => prev.concat({ "id": subTasks[subTasks.length - 1] ? subTasks[subTasks.length - 1].id + 1 : 0, "order": subTasks[subTasks.length - 1] ? subTasks[subTasks.length - 1].order + 1 : 1, "imageId": 0, "image": "https://i.stack.imgur.com/l60Hf.png", "name": "Empty", enable: true }))
                                                setOnHold(true)
                                            }} >{t("addNewTask")}</CButton>

                                        <br></br>


                                        {apiError !== "" ?
                                            <CAlert show={10} color="danger">
                                                {apiError}
                                            </CAlert>
                                            : null}
                                        <br></br>
                                        <br></br>

                                        <CLabel>{t("assignColor")}</CLabel>

                                        <div>
                                            <div className="colorChanger">
                                                {
                                                    activityColors.map((color, index) => {
                                                        if (color.selected) {
                                                            return <div className="cc-oval-selected" style={{ backgroundColor: color.colorCode }} onClick={() => selectColor(index)}>
                                                                <span></span>
                                                            </div>;
                                                        }
                                                        return <div className="cc-oval" style={{ backgroundColor: color.colorCode }} onClick={() => selectColor(index)}></div>;
                                                    })
                                                }
                                            </div>

                                            {
                                                activityColorError && <CLabel className="field-error">{activityColorError}</CLabel>
                                            }
                                        </div>


                                        <CRow >
                                            <CCol className="footer-has-primary-secondary-btn pt-3">

                                                <CButton
                                                    variant="outline" color="dark" style={{ float: "right" }} onClick={() => {
                                                        if (checkChanged()) {
                                                            setLeaveModal(true)
                                                        } else {
                                                            LeaveThisPage()
                                                        }
                                                    }}  >{t("cancel")}</CButton>
                                                <CButton
                                                    variant="outline" color="dark" style={{ float: "right", marginRight: 10 }}
                                                    onClick={() => {
                                                        onSubmit()
                                                    }}
                                                >{t("createAct")}</CButton>
                                            </CCol>

                                        </CRow>




                                    </CCardBody>



                                </CCard>
                            </CCol>
                        </CRow>

                    </CCardBody>


                    {openModalAdd ? <AddFromImageLibModal openModal={openModalAdd} handleModal={handleModalAdd} categories={categories} setActivityImage={setActivityImage} /> : null}

                    <LeavePage openModal={leaveModal} handleModal={handleLeaveModal} leaveThis={LeaveThisPage} />

                </CCard>
            </CCol>
        </CRow>
    )
}

export default Activities
