import React, { Suspense, useEffect } from 'react'
import {
  Redirect,
  Route,
  Switch,
  useLocation
} from 'react-router-dom'
import { CContainer, CFade } from '@coreui/react'

// routes config
import routes from '../routes'
import RouteConstants from 'src/constants/RouteConstants'
import { getViewPermValue } from 'src/helpers/PermissionCalculator'
import { PRIV_CONSTS } from 'src/constants/StaticData'
import { getNotifications } from 'src/store/actions/Notifications'
import { useDispatch, useSelector } from 'react-redux'
import { ADMIN_CONST } from 'src/constants/GenericConstants'

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

const TheContent = () => {

  const location = useLocation();
  const dispatch = useDispatch();
  const userState = useSelector((state) => state.authentication.user);



  useEffect(() => {
    if (userState.accounts.filter(u => u.role === ADMIN_CONST.SUPER_ADMIN).length > 0) {
      dispatch(getNotifications(1, () => { }));
    }
  }, [location]);


  return (
    <main className="c-main">
      <CContainer fluid>
        <Suspense fallback={loading}>
          <Switch>
            {routes.map((route, idx) => {
              return route.component && (
                <Route
                  key={idx}
                  path={route.path}
                  exact={route.exact}
                  name={route.name}
                  render={props => (
                    <CFade>
                      <route.component {...props} />
                    </CFade>
                  )} />
              )
            })}
            <Redirect from="/" to={getViewPermValue(PRIV_CONSTS.PARENT_PRIV) ? RouteConstants.CUSTOMER_USER : getViewPermValue(PRIV_CONSTS.USER_PRIV) ? RouteConstants.USER_LIST : RouteConstants.MY_ACCOUNT} />
          </Switch>
        </Suspense>
      </CContainer>
    </main>
  )
}

export default React.memo(TheContent)
