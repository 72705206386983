import { REGEX_CONSTANTS, APP_CONSTANTS } from '../constants/GenericConstants';

export function validateEmail(str) {
    return (REGEX_CONSTANTS.Email.test(str))
}

export function validateMandatoryValue(str) {
    return !(!str || str === '' || str.trim().length < 1)
}

export function validateAtLeastPriv(permissions) {
    return permissions>0;
}


export function validateTextLength(str) {
    return str.length <= 100;
}

export function validateActNameLength(str) {
    return str.length <= 18;
}
export function validateTaskNameLength(str) {
    return str.length <= 18;
}

export function validateArray(arr) {
    return arr.length>0;
}

export function validateObject(obj) {
    return Object.keys(obj).length>0;
}


export function validatePasswordValue(str) {
    return !(str.trim().length < 8 || str.trim().length > 30)
}



export function isEmpty(errors) {
    return !Object.keys(errors).length;
}

export function validatePasswordLength(str) {
    return str.length >= APP_CONSTANTS.MIN_PASSWORD_LENGTH && str.length <= APP_CONSTANTS.MAX_PASSWORD_LENGTH;
}


