import { request, success, failure } from ".";
import { LOGIN_ACTIONS, SIGN_UP_ACTIONS, CONFIRM_EMAIL_ACTIONS, SIGN_OUT_ACTIONS, SET_PASSWORD_ACTIONS, LOGOUT_ACTIONS, VERIFY_CODE_ACTIONS } from "../../constants/ActionConstants";
import { LOCAL_STORAGE_KEYS } from "../../constants/GenericConstants";
import AuthenticationService from "../../services/api/AuthenticationService";

export const login = (email, password, onSuccess, onFailure) => {
  return async (dispatch) => {
    dispatch(request(LOGIN_ACTIONS.LOGIN, {}));
    try {
      const response = await AuthenticationService.login(email, password);
      // Storing access token and user details in local storage
      localStorage.setItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN, response.data.token.accessToken);
      localStorage.setItem(LOCAL_STORAGE_KEYS.USER, JSON.stringify(response.data.user));

      dispatch(success(LOGIN_ACTIONS.LOGIN_SUCCESS, { data: response.data.user }));
      onSuccess();

    } catch (error) {
      dispatch(failure(LOGIN_ACTIONS.LOGIN_FAILURE, {}));
      error.data && error.data.message && onFailure(error.data.message);

    }
  };
};

export const logout = (onSuccess, onFailure) => {
  return async (dispatch) => {
    dispatch(request(LOGOUT_ACTIONS.LOGOUT_ACTIONS, {}));
    try {

      await AuthenticationService.logout();

      // Removing access token and user details in local storage
      localStorage.removeItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN);
      localStorage.removeItem(LOCAL_STORAGE_KEYS.USER);

      dispatch(success(LOGOUT_ACTIONS.LOGOUT_ACTIONS_SUCCESS, {}));
      onSuccess();
    } catch (error) {
      dispatch(failure(LOGOUT_ACTIONS.LOGOUT_ACTIONS_FAILURE, {}));
      onFailure(error.data.message);
    }
  };
};

export const setAdminPassword = (password, confirmPassword, code, onSuccess, onFailure) => {
  return async (dispatch) => {
    dispatch(request(SET_PASSWORD_ACTIONS.SET_PASSWORD, {}));
    try {
      const response = await AuthenticationService.setAdminPassword(password, confirmPassword, code)
      // Storing access token and user details in local storage
      localStorage.setItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN, response.data.token.accessToken);
      localStorage.setItem(LOCAL_STORAGE_KEYS.USER, JSON.stringify(response.data.user));

      dispatch(success(SET_PASSWORD_ACTIONS.SET_PASSWORD_SUCCESS, { data: response.data.user }));
      onSuccess();
    } catch (error) {
      dispatch(failure(SET_PASSWORD_ACTIONS.SET_PASSWORD_FAILURE, {}));
      onFailure(error.data.message);
    }
  };
};

export const verifyCode = (data, onSuccess, onFailure) => {
  return async (dispatch) => {
    dispatch(request(VERIFY_CODE_ACTIONS.VERIFY_CODE, {}));
    try {
      const response = await AuthenticationService.verifyCode(data);
      dispatch(success(VERIFY_CODE_ACTIONS.VERIFY_CODE_SUCCESS, {}));
      onSuccess(response);
    } catch (error) {
      dispatch(failure(VERIFY_CODE_ACTIONS.VERIFY_CODE_FAILURE, {}));
      onFailure(error.data.message);
    }
  };
};




export const registerDeviceForPushNotifications = async () => {
  try {
    const OneSignal = window.OneSignal;
    OneSignal.isPushNotificationsEnabled(function (isEnabled) {
      if (isEnabled) {
        OneSignal.getUserId(async function (userId) {
          // console.log('player_id of the subscribed user is : ' + userId);
          // OneSignal.registerForPushNotifications();      
          await AuthenticationService.registerDeviceForNotification(userId);
        });
      }
    });
  } catch (error) {
    console.log(error);
  }
}