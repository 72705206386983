import { GET_CATEGORIES } from "../../constants/ActionConstants";

let initialState = {
    loading: false,
    categories: {}
};

const categories = (state = initialState, action) => {

    switch (action.type) {
        case GET_CATEGORIES.GET_CATEGORIES:
            return {
                ...state,
                loading: true,
            };

        case GET_CATEGORIES.GET_CATEGORIES_FAILURE:
            return {
                ...state,
                loading: false
            };

    
        case GET_CATEGORIES.GET_CATEGORIES_SUCCESS:
            return {
                ...state,
                loading: false,
                categories: action.payload.data
            };

        default:
            return state;
    }
};

export default categories;
