import { apiClient } from "./ApiClient";
import UrlConstants from "../../constants/UrlConstants";
import { handleResponse, handleError, handleS3ImageUploadResponse } from "./GenericResponseHandler";

const ITEMS_NO = 30;

class ImageLibService {

    addImageLib(data) {
        let url = UrlConstants.ADD_IMAGE_LIB;

        return apiClient()
            .post(url, data)
            .then(handleResponse)
            .catch(handleError);
    }

    editImageLib(data) {
        let url = UrlConstants.EDIT_IMAGE_LIB;

        return apiClient()
            .put(url, data)
            .then(handleResponse)
            .catch(handleError);
    }

    getImageCategories() {
        let url = UrlConstants.GET_IMAGE_CATEGORIES
        return apiClient()
            .get(url)
            .then(handleResponse)
            .catch(handleError);
    }
    
    getImageSkintones() {
        let url = UrlConstants.GET_IMAGE_SKINTONES;

        return apiClient()
            .get(url)
            .then(handleResponse)
            .catch(handleError);
    }

    getImageLibList() {
        let url = UrlConstants.GET_IMAGE_LIBRARY_LIST + "?take=" + ITEMS_NO
        return apiClient()
            .get(url)
            .then(handleResponse)
            .catch(handleError);
    } 

    getImageLibPageList(page) {
        let url = UrlConstants.GET_IMAGE_LIBRARY_LIST + "?page=" + page + "&take=" + ITEMS_NO
        return apiClient()
            .get(url)
            .then(handleResponse)
            .catch(handleError);
    }


    getSearchedImageLibList(q) {
        const encodedQuery = encodeURIComponent(q);
        var url = ""

        if (encodedQuery !== "") {
            url = UrlConstants.GET_IMAGE_LIBRARY_LIST + "?q=" + encodedQuery + "&take=" + ITEMS_NO;
        }
        else {
            url = UrlConstants.GET_IMAGE_LIBRARY_LIST + "?take=" + ITEMS_NO

        }
        return apiClient()
            .get(url)
            .then(handleResponse)
            .catch(handleError);
    }

    getCatFilterImageLibList(catList) {
        let categoryFilter = "";
        catList.map(cat => {
            if (categoryFilter !== "") {
                categoryFilter += "," + cat;
            }
            else {
                categoryFilter = cat
            }
        })
        var url = "";

        if (categoryFilter !== "") {
            url = UrlConstants.GET_IMAGE_LIBRARY_LIST + "?category=" + categoryFilter+ "&take=" + ITEMS_NO;
        }
        else {
            url = UrlConstants.GET_IMAGE_LIBRARY_LIST + "?take=" + ITEMS_NO
        }

        return apiClient()
            .get(url)
            .then(handleResponse)
            .catch(handleError);
    }

    getSearchedCatFilterImageLibList(q, catList) {
        let categoryFilter = "";
        const encodedQuery = encodeURIComponent(q);

        catList.map(cat => {
            if (categoryFilter !== "") {
                categoryFilter += "," + cat;
            }
            else {
                categoryFilter = cat
            }
        })

        var url = "";

        if (categoryFilter !== "" && encodedQuery !== "") {
            url = UrlConstants.GET_IMAGE_LIBRARY_LIST + "?category=" + categoryFilter + "&q=" + encodedQuery+ "&take=" + ITEMS_NO;;
        }
        else if (categoryFilter !== "" && encodedQuery === "") {
            url = UrlConstants.GET_IMAGE_LIBRARY_LIST + "?category=" + categoryFilter+ "&take=" + ITEMS_NO;;
        }
        else if (categoryFilter === "" && encodedQuery !== "") {
            url = UrlConstants.GET_IMAGE_LIBRARY_LIST + "?q=" + encodedQuery+ "&take=" + ITEMS_NO;;
        }
        else {
            url = UrlConstants.GET_IMAGE_LIBRARY_LIST+ "?take=" + ITEMS_NO
        }

        return apiClient()
            .get(url)
            .then(handleResponse)
            .catch(handleError);
    }
    
    getImageList(page,q,catList) {
        let categoryFilter = "";
        const encodedQuery = encodeURIComponent(q);

        catList.map(cat => {
            if (categoryFilter !== "") {
                categoryFilter += "," + cat;
            }
            else {
                categoryFilter = cat
            }
        })

        var url = "";

        if (categoryFilter !== "" && encodedQuery !== "") {
            url = UrlConstants.GET_IMAGE_LIBRARY_LIST + "?category=" + categoryFilter + "&q=" + encodedQuery+ "&take=" + ITEMS_NO+"&page="+page;
        }
        else if (categoryFilter !== "" && encodedQuery === "") {
            url = UrlConstants.GET_IMAGE_LIBRARY_LIST + "?category=" + categoryFilter+ "&take=" + ITEMS_NO+"&page="+page;
        }
        else if (categoryFilter === "" && encodedQuery !== "") {
            url = UrlConstants.GET_IMAGE_LIBRARY_LIST + "?q=" + encodedQuery+ "&take=" + ITEMS_NO+"&page="+page
        }
        else {
            url = UrlConstants.GET_IMAGE_LIBRARY_LIST+ "?take=" + ITEMS_NO+"&page="+page
        }

        return apiClient()
            .get(url)
            .then(handleResponse)
            .catch(handleError);
    }



    getPresignedURL(file) {
        let url = UrlConstants.GET_PRESIGNED_URL + "?key=library&op=upload&file=" + file;
        return apiClient()
            .get(url)
            .then(handleResponse)
            .catch(handleError);
    }

    getImageURL(fileName) {
        let url = UrlConstants.GET_PRESIGNED_URL + "?key=library&op=get&file=" + fileName;
        return apiClient()
            .get(url)
            .then(handleResponse)
            .catch(handleError);
    }


    uploadImagePut(imageData, urlAws) {

        let url = urlAws
        var options = {
            headers: {
                'Content-Type': "image/*"
            }
        };

        return apiClient()
            .uploadImage(url, imageData, options)
            .then(handleS3ImageUploadResponse)
            .catch(handleError);
    }


}

export default new ImageLibService();