import CategoryService from "src/services/api/CategoryService";
import { request, success, failure } from ".";
import {GET_CATEGORIES } from "../../constants/ActionConstants";


export const getCategories = (onSuccess, onFailure) => {
    return async (dispatch) => {
        dispatch(request(GET_CATEGORIES.GET_CATEGORIES, {}));
        try {
            const response = await CategoryService.getImageCategories();
            dispatch(success(GET_CATEGORIES.GET_CATEGORIES_SUCCESS, { data: response.data }));
            onSuccess(response.data);
        } catch (error) {
            dispatch(failure(GET_CATEGORIES.GET_CATEGORIES_FAILURE, {}));
            error.data && error.data.message && onFailure(error.data.message);
        }
    };
};

