import React, { useEffect, useState } from 'react'
import { CCol, CRow, CSwitch } from '@coreui/react'
import { getPermissionOfView, getPermissionOfEdit } from 'src/helpers/PermissionCalculator';

const UserPrivileges = (props) => {

  const [view, setView] = useState(props.privileges.view);
  const [edit, setEdit] = useState(props.privileges.edit);

  const {disabled}= props
  
  useEffect(() => {

    setView(getPermissionOfView(props.permissions, props.privileges));
    setEdit(getPermissionOfEdit(props.permissions, props.privileges));


  }, [props])

  const viewSwitchChanged = (value, object) => {
    console.log("viewSwitchChanged", value);
    props.viewSwitchChanged(value, object);
    if (value == false && edit == true) {
      setEdit(value)
      props.editSwitchChanged(value, object);
    }
    setView(value);
  }

  const editSwitchChanged = (value, object) => {
    console.log("editSwitchChanged", value);
    props.editSwitchChanged(value, object);
    if (value == true && view == false) {
      setView(value)
      props.viewSwitchChanged(value, object);
    }

    setEdit(value);
  }

  return (

    <CRow>
      <CCol md={4} xs={6} tag="label" className="col-form-label">
        {props.privileges.name}
      </CCol>
      <CCol xs={2}>
        <CSwitch
          className="mr-1"
          color="dark"
          shape="pill"
          variant="opposite"
          disabled={disabled? disabled: props.privileges.disabled}
          checked={view}
          onChange={e => { viewSwitchChanged(e.target.checked, props.privileges) }}
        />
      </CCol>
      <CCol xs={2}>
        <CSwitch
          className="mr-1"
          color="dark"
          shape="pill"
          variant="opposite"
          disabled={disabled? disabled: props.privileges.disabled}
          checked={edit}
          onChange={e => { editSwitchChanged(e.target.checked, props.privileges) }}
        />
      </CCol>
    </CRow>

  )
}

export default UserPrivileges
