import { ACTIVITY_ACTIONS, COLOR_LIST_ACTIONS } from "../../constants/ActionConstants";

let initialState = {
    loading: false,
    activities: [],
    currentActivity: {}
};

const activity = (state = initialState, action) => {

    switch (action.type) {
        case ACTIVITY_ACTIONS.ADD_ACTIVITY:
            return {
                ...state,
                loading: true,
            };

        case ACTIVITY_ACTIONS.ADD_ACTIVITY_SUCCESS:
            return {
                ...state,
                loading: false
            };

        case ACTIVITY_ACTIONS.ADD_ACTIVITY_FAILURE:
            return {
                ...state,
                loading: false,
            };
        case ACTIVITY_ACTIONS.GET_ACTIVITIES:
            return {
                ...state,
                loading: true,
            };

        case ACTIVITY_ACTIONS.GET_ACTIVITIES_SUCCESS:
            return {
                ...state,
                loading: false,
                activities: action.payload.data
            };

        case ACTIVITY_ACTIONS.GET_ACTIVITIES_FAILURE:
            return {
                ...state,
                loading: false,
            };
        case ACTIVITY_ACTIONS.VIEW_ACTIVITY:
            return {
                ...state,
                loading: true,
            };

        case ACTIVITY_ACTIONS.VIEW_ACTIVITY_SUCCESS:
            return {
                ...state,
                loading: false,
                currentActivity: action.payload.data
            };

        case ACTIVITY_ACTIONS.VIEW_ACTIVITY_FAILURE:
            return {
                ...state,
                loading: false,
            };

        case COLOR_LIST_ACTIONS.GET_COLOR_LIST:
          return {
              ...state,
              loading: true,
          };

        case COLOR_LIST_ACTIONS.GET_COLOR_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                activityColorList: action.payload.data,
            };

        case COLOR_LIST_ACTIONS.GET_COLOR_LIST_FAILURE:
          return {
              ...state,
              loading: false,
          };

        default:
            return state;
    }
};

export default activity;
