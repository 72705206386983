import { apiClient } from "./ApiClient";
import UrlConstants from "../../constants/UrlConstants";
import { handleResponse, handleError } from "./GenericResponseHandler";

class CustomerService {

    getCustomerUsers(page, take) {
        let url = UrlConstants.GET_EXTERNAL_USERS + "?page=" + page + "&take=" + take;
        return apiClient()
            .get(url)
            .then(handleResponse)
            .catch(handleError);
    }

    getSearchedCustomerUsers(page, take, q) {

        const encodedQuery = encodeURIComponent(q);
        let url = UrlConstants.GET_EXTERNAL_USERS + "?page=" + page + "&take=" + take + "&q=" + encodedQuery;
        return apiClient()
            .get(url)
            .then(handleResponse)
            .catch(handleError);
    }

    getSortedCustomerUsers(page, take, field, order) {
        let url = UrlConstants.GET_EXTERNAL_USERS + "?page=" + page + "&take=" + take + "&field=" + field + "&order=" + order;
        return apiClient()
            .get(url)
            .then(handleResponse)
            .catch(handleError);
    }

    getSearchedSortedCustomerUsers(page, take, q, field, order) {
        const encodedQuery = encodeURIComponent(q);

        let url = UrlConstants.GET_EXTERNAL_USERS + "?page=" + page + "&take=" + take + "&q=" + encodedQuery + "&field=" + field + "&order=" + order;
        return apiClient()
            .get(url)
            .then(handleResponse)
            .catch(handleError);
    }

    //GET CHILDREN FOR THE USERS

    getChildren(parentId) {
        let url = UrlConstants.GET_CHILDREN.replace(":exUserId", parentId)
        return apiClient()
            .get(url)
            .then(handleResponse)
            .catch(handleError);
    }

    getImageURL(fileName) {
        let url = UrlConstants.GET_PRESIGNED_URL + "?key=child&op=get&file=" + fileName;
        return apiClient()
            .get(url)
            .then(handleResponse)
            .catch(handleError);
    }

    getParentExUsers(parentId) {
        let url = UrlConstants.GET_PARENT_EX_USERS.replace(":exUserId", parentId)
        return apiClient()
            .get(url)
            .then(handleResponse)
            .catch(handleError);
    }


    updateExternalUserStatus(userId, accountId, status) {
        let url = UrlConstants.UPDATE_EXTERNAL_USER_STATUS;

        const payload = {
            userId: userId,
            accountId: accountId,
            status: status,
            fromAdmin: true
        };

        return apiClient()
            .post(url, payload)
            .then(handleResponse)
            .catch(handleError);
    }



}

export default new CustomerService();