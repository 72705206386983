import { apiClient } from "./ApiClient";
import UrlConstants from "../../constants/UrlConstants";
import { handleResponse, handleError, handleResponseSelfRegulation, handleResponseSelfRegulationEdit } from "./GenericResponseHandler";

const ITEMS_NO = 10;

class SelfRegulationService {

    addRegulation(data) {
        let url = UrlConstants.ADD_SELF_REGULATION;
        return apiClient()
            .post(url, data)
            .then(handleResponseSelfRegulation)
            .catch(handleError);
    }
    
    editRegulation(data) {
        let url = UrlConstants.UPDATE_SELF_REGULATION;
        
        return apiClient()
            .put(url, data)
            .then(handleResponseSelfRegulationEdit)
            .catch(handleError);
    }


    getRegulationList(page, take, q, field, order) {

        const encodedQuery = encodeURIComponent(q);
        var url = "";


        if (encodedQuery !== "" && field!=="" && order!=="") {
            url = UrlConstants.GET_SELF_REGULATION_LIST + "?q=" + encodedQuery+ "&take=" + ITEMS_NO+"&page="+page+"&field="+field+"&order="+order;
        }
        else if (encodedQuery === "" && field!=="" && order!=="") {
            url = UrlConstants.GET_SELF_REGULATION_LIST + "?take=" + ITEMS_NO+"&page="+page+"&field="+field+"&order="+order;
        }
        else if (encodedQuery !== "" && field==="") {
            url = UrlConstants.GET_SELF_REGULATION_LIST + "?q="+encodedQuery+"&take=" + ITEMS_NO+"&page="+page;
        }
        else if (encodedQuery === "" && field==="" && order==="") {
            url = UrlConstants.GET_SELF_REGULATION_LIST + "?take=" + ITEMS_NO+"&page="+page;
        }
        else {
            url = UrlConstants.GET_SELF_REGULATION_LIST+ "?take=" + ITEMS_NO+"&page="+page
        }


        return apiClient()
            .get(url)
            .then(handleResponseSelfRegulation)
            .catch(handleError);
    }
    
    getRegulation(id) {

        let url = UrlConstants.GET_SELF_REGULATION+"?id="+id

        return apiClient()
            .get(url)
            .then(handleResponse)
            .catch(handleError);
    }
    

}

export default new SelfRegulationService();