import React, { useState, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import {
    CCard,
    CCardBody,
    CCardHeader,
    CCol,
    CRow,
    CButton,
    CInput,
    CImg,
    CInputCheckbox,
    CInputFile,
    CLabel,
    CAlert,
} from '@coreui/react'
import RouteConstants from 'src/constants/RouteConstants'
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from 'react-redux'
import { getCategories } from 'src/store/actions/Categories'
import Loader from '../common/Loader/Loader'
import { editActivity, getActivity } from 'src/store/actions/Activity'
import { validateActNameLength, validateMandatoryValue, validateObject } from 'src/helpers/Validation'
import { PRIV_CONSTS } from 'src/constants/StaticData'
import { getEditPermValue } from 'src/helpers/PermissionCalculator'
import LeavePage from '../common/LeavePageModal'
import { ActivityColor } from 'src/constants/GenericConstants'
import appleWatch from "src/assets/images/applewatchgray.png"
import DataTablePayments from './components/DataTablePayments';
import DataTablePayHistory from './components/DataTablePayHistory';
import { getPaymentStatus, getPlanType, getSubscriptionStatus, getUserStatus } from 'src/helpers/GenericHelper';
import DateHelper from 'src/helpers/DateHelper';
import { cancelSubscription } from 'src/store/actions/Payment';



const Activities = (props) => {
    const history = useHistory()
    const location = useLocation()
    const dispatch = useDispatch()


    const paymentState = useSelector((state) => state.activity);
    const user = useSelector((state) => state.authentication.user);
    const [viewEditAccess, setViewEditAccess] = useState(false)
    const [apiError, setApiError] = useState("");



    const { t } = useTranslation();


    const [leaveModal, setLeaveModal] = useState(false);


    useEffect(() => {

        if (user && user.accounts) {
            let editPerm = getEditPermValue(PRIV_CONSTS.PAYMENT_PRIV)
            setViewEditAccess(editPerm)

        }

        const userID = decodeURIComponent(props.match.params.customerId);
        // if (activityId) {
        //     setActId(activityId)
        //     dispatch(getActivity(activityId, onLoadSuccess, onLoadFailure));
        // }

        if (location.data) {

            // dispatch(getParentExUsers(user.id, onExUsersRetrieved, onExUsersFailed))

        } else {
            history.replace(RouteConstants.PAYMENTS)

        }

    }, [])

    const getOwnerName = () => {
        var name = "";
        try {
            name = location.data.firstName + " " + location.data.lastName
        }
        catch (e) {

        }
        return name
    }


    const cancelSub = () => {
        let payload = {
            userId: location.data && location.data.userId,
            planId: location.data && location.data.subscriptionId
        }
        // alert(JSON.stringify(payload))
        dispatch(cancelSubscription(payload, (message) => {
            history.replace({ pathname: RouteConstants.PAYMENTS, state: { msg: message } });

        }), (errorMessage) => {
            setApiError(errorMessage);
        });

    }

    return (
        <CRow>

            {paymentState.loading && <Loader />}


            {apiError &&
                <CAlert color="danger">
                    {apiError}
                </CAlert>
            }

            <CCol xl={12}>
                <CCard>
                    <CCardHeader>
                        <CRow className="align-items-center">
                            <CCol sm="5" xs="12">
                                <h5 className="page-title py-2">{t("paymentDetails")}</h5>
                            </CCol>
                            {/* <small className="text-muted"> example</small> */}

                        </CRow>


                    </CCardHeader>
                    <CCardBody>
                        <CRow>
                            <CCol xl={5}>
                                <CCard>

                                    <CCardBody>
                                        <CRow>
                                            <CCol>
                                                <CLabel><strong>{t("accountOwner")}</strong></CLabel> <br />
                                                <CLabel>{getOwnerName()}</CLabel>
                                            </CCol>
                                            <CCol>
                                                <CLabel><strong>{t("accountStatus")}</strong></CLabel> <br />
                                                <CLabel>{location.data && getUserStatus(location.data?.accountStatus)}</CLabel>
                                            </CCol>
                                        </CRow>
                                        <br></br>
                                        <CRow>
                                            <CCol>
                                                <CLabel><strong>{t("dueDate")}</strong></CLabel> <br />
                                                <CLabel>{location.data && location.data.dueDate ? DateHelper.formatDate(location.data.dueDate) : "No any"}</CLabel>

                                            </CCol>
                                            <CCol>
                                                <CLabel><strong>{t("paymentStatus")}</strong></CLabel> <br />
                                                <CLabel>{location.data && getPaymentStatus(location.data?.paymentStatus)}</CLabel>

                                            </CCol>
                                        </CRow>
                                        <br></br>

                                        {/* <CRow>
                                            <CCol>
                                                <CLabel><strong>{t("subscription")}</strong></CLabel>

                                                <CButton
                                                    variant="outline" color="dark" style={{ float: "right" }}
                                                    disabled={!viewEditAccess}
                                                    onClick={() => {
                                                        cancelSub()
                                                    }}
                                                >{t("cancelSubscription")}</CButton>
                                            </CCol>

                                        </CRow> */}
                                        <CRow>
                                            <CCol>
                                                <CLabel><strong>{t("subscriptionStatus")}: </strong>  {getSubscriptionStatus(location.data?.paymentStatus)} </CLabel>
                                            </CCol>

                                            <CCol>
                                                <CButton
                                                    variant="outline" color="dark" style={{ float: "right" }}
                                                    disabled={!viewEditAccess || location.data?.cancelledAt !== null}
                                                    onClick={() => {
                                                        cancelSub()
                                                    }}
                                                >{t("cancelSubscription")}</CButton>
                                            </CCol>
                                        </CRow>
                                        <hr></hr>
                                        <CRow>
                                            <CCol>
                                                {location.data?.cancelledAt ? <CLabel><strong>{t("cancelledAt")}: </strong>  {location.data && location.data.cancelledAt ? DateHelper.formatDate(location.data.cancelledAt) : "No any"}</CLabel> : null}

                                            </CCol>

                                            <CCol>
                                                {location.data?.cancelledAt ? <CLabel><strong>{t("expireText").replace("{date}",location.data && location.data.dueDate ? DateHelper.formatDate(location.data.dueDate) : "No any")}   </strong></CLabel> : null}

                                            </CCol>
                                        </CRow>
                                        <br></br>

                                        <CCard>

                                            <CCardBody>
                                                <CRow>
                                                    <CCol>
                                                        <CLabel><strong>{t("plan")}</strong></CLabel>
                                                    </CCol>
                                                </CRow>
                                                <CRow>
                                                    <CCol>
                                                        <CCard>
                                                            <CCardBody>
                                                                <h4>${location.data && location.data.planAmount}/{location.data && location.data.planType ? getPlanType(location.data.planType) : "--"}</h4>
                                                            </CCardBody>
                                                        </CCard>
                                                    </CCol>
                                                </CRow>
                                                <br></br>
                                                <CRow>
                                                    <CCol>
                                                        <CLabel><strong>{t("paymentMethod")}</strong></CLabel>
                                                    </CCol>
                                                </CRow>
                                                <CRow>
                                                    <CCol>
                                                        <CCard>
                                                            <CCardBody>
                                                                <h4>Stripe</h4>
                                                            </CCardBody>
                                                        </CCard>
                                                    </CCol>
                                                </CRow>
                                            </CCardBody>

                                        </CCard>

                                    </CCardBody>



                                </CCard>
                            </CCol>

                            <CCol xl={7}>
                                <CCard>

                                    <CCardHeader>
                                        <CRow className="align-items-center">
                                            <CCol sm="5" xs="12">
                                                <h6 className="page-title py-2">{t("paymentHistory")}</h6>
                                            </CCol>
                                            {/* <small className="text-muted"> example</small> */}

                                        </CRow>


                                    </CCardHeader>
                                    <CCardBody>

                                        <CRow>
                                            <DataTablePayHistory data={location.data ? location.data : null} name={getOwnerName()} />
                                        </CRow>

                                        <CRow >
                                            <CCol className="footer-has-primary-secondary-btn pt-3">

                                                <CButton
                                                    variant="outline" color="dark" style={{ float: "right" }}
                                                    onClick={() => {
                                                        history.goBack()
                                                    }}
                                                >{t("cancel")}</CButton>

                                            </CCol>

                                        </CRow>




                                    </CCardBody>



                                </CCard>
                            </CCol>
                        </CRow>

                    </CCardBody>





                </CCard>
            </CCol>
        </CRow>
    )
}

export default Activities
