import { PAYMENT_ACTIONS } from "../../constants/ActionConstants";

let initialState = {
    loading: false,
    payments: [],
    history: [],
};

const payment = (state = initialState, action) => {

    switch (action.type) {

        case PAYMENT_ACTIONS.GET_PAYMENT_LIST:
            return {
                ...state,
                loading: true,
            };

        case PAYMENT_ACTIONS.GET_PAYMENT_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                payments: action.payload.data
            };

        case PAYMENT_ACTIONS.GET_PAYMENT_LIST_FAILURE:
            return {
                ...state,
                loading: false,
            };


        case PAYMENT_ACTIONS.GET_PAYMENT_HISTORY:
            return {
                ...state,
                history: [],
                loading: true,
            };

        case PAYMENT_ACTIONS.GET_PAYMENT_HISTORY_SUCCESS:
            return {
                ...state,
                loading: false,
                history: action.payload.data
            };

        case PAYMENT_ACTIONS.GET_PAYMENT_HISTORY_FAILURE:
            return {
                ...state,
                loading: false,
            };

        case PAYMENT_ACTIONS.CANCEL_SUBS:
            return {
                ...state,
                loading: true,
            };
        case PAYMENT_ACTIONS.CANCEL_SUBS_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case PAYMENT_ACTIONS.CANCEL_SUBS_FAILURE:
            return {
                ...state,
                loading: false,
            };

        case PAYMENT_ACTIONS.GENERAL:
            return {
                ...state,
                loading: true,
            };
        case PAYMENT_ACTIONS.GENERAL_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case PAYMENT_ACTIONS.GENERAL_FAILURE:
            return {
                ...state,
                loading: false,
            };


        default:
            return state;
    }
};

export default payment;
