export const USER_PRIVILEGES = [

    { id: 1, name: 'User Management', view: true, edit: true, disabled: false },
    { id: 2, name: 'Activity Template', view: true, edit: true, disabled: false },
    { id: 3, name: 'Parent Account Management', view: true, edit: true, disabled: false },
    { id: 4, name: 'Image Library', view: true, edit: true, disabled: false },
    { id: 5, name: 'Emotion Regulation', view: true, edit: true, disabled: false },
    { id: 6, name: 'Payment and Billing', view: true, edit: true, disabled: false }

];

export const PRIV_CONSTS ={
    USER_PRIV : 0,
    ACTIVITY_PRIV :1,
    PARENT_PRIV: 2,
    IMAGE_PRIV: 3,
    EMOTION_PRIV: 4,
    PAYMENT_PRIV: 5,
}