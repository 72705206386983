import { apiClient } from "./ApiClient";
import UrlConstants from "../../constants/UrlConstants";
import { handleResponse, handleError } from "./GenericResponseHandler";

const ITEMS_NO = 10;

class ActivityService {

    addActivity(data) {
        let url = UrlConstants.ADD_ACTIVITY;
        return apiClient()
            .post(url, data)
            .then(handleResponse)
            .catch(handleError);
    }

    editActivity(data) {
        let url = UrlConstants.UPDATE_ACTIVITY;

        return apiClient()
            .put(url, data)
            .then(handleResponse)
            .catch(handleError);
    }


    getActivities(page, take, q, field, order) {

        const encodedQuery = encodeURIComponent(q);
        var url = "";


        if (encodedQuery !== "" && field!=="" && order!=="") {
            url = UrlConstants.GET_ACTIVITIES + "?q=" + encodedQuery+ "&take=" + ITEMS_NO+"&page="+page+"&field="+field+"&order="+order;
        }
        else if (encodedQuery === "" && field!=="" && order!=="") {
            url = UrlConstants.GET_ACTIVITIES + "?take=" + ITEMS_NO+"&page="+page+"&field="+field+"&order="+order;
        }
        else if (encodedQuery !== "" && field==="") {
            url = UrlConstants.GET_ACTIVITIES + "?q="+encodedQuery+"&take=" + ITEMS_NO+"&page="+page;
        }
        else if (encodedQuery === "" && field==="" && order==="") {
            url = UrlConstants.GET_ACTIVITIES + "?take=" + ITEMS_NO+"&page="+page;
        }
        else {
            url = UrlConstants.GET_ACTIVITIES+ "?take=" + ITEMS_NO+"&page="+page
        }


        return apiClient()
            .get(url)
            .then(handleResponse)
            .catch(handleError);
    }

    getActivity(id) {

        let url = UrlConstants.GET_ACTIVITY+"?id="+id

        return apiClient()
            .get(url)
            .then(handleResponse)
            .catch(handleError);
    }

    getColorList(id) {
      let url = UrlConstants.GET_META_DATA;
      return apiClient()
          .get(url)
          .then(handleResponse)
          .catch(handleError);
  }


}

export default new ActivityService();
