export const PERMISSIONS = {

    PAYMENT_VIEW: 1, //Payment
    PAYMENT_CREATE: 2, //Payment

    ADMIN_VIEW: 4, //User Management (YES) =1
    ADMIN_CREATE: 8,

    PARENT_VIEW: 16, //Parent Account Management (YES) =3
    PARENT_CREATE: 32,


    ACTIVITY_VIEW: 64, // Activity Template (YES) =2
    ACTIVITY_CREATE: 128,

    IMAGE_LIB_VIEW: 256, //Image Library (YES) =4
    IMAGE_LIB_CREATE: 512,  //Sum = 1020

    EMO_REGULATION_VIEW: 1024, //Emotion Regulation (YES)=5  sum=2044
    EMO_REGULATION_CREATE: 2048  //sum=4092
};

