import React, { useEffect, useState } from 'react'
import {
    CAlert,
    CBadge,
    CButton,
    CDataTable,
    CImg,
    CInputCheckbox,
    CLabel,
    CPagination,
    CSwitch,

} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { useHistory, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getUsers, getSearchedUsers, updateUserStatus, getSortedUsers, getSearchedSortedUsers } from 'src/store/actions/User'
import { APP_CONSTANTS } from 'src/constants/GenericConstants'
import { getPaymentStatus, getUserStatus } from 'src/helpers/GenericHelper'
import Loader from 'src/views/common/Loader/Loader'
import RouteConstants from 'src/constants/RouteConstants'
import disable from '../../../assets/images/user-disable.svg';
import DateHelper from 'src/helpers/DateHelper'
import Confirmation from 'src/views/common/Confirmation'
import PlaceHolder from 'src/views/common/PlaceHolder'
import ShowAlert from 'src/views/common/ShowAlert'
import { freeSet } from '@coreui/icons'
import { getPaymentList } from 'src/store/actions/Payment'
import ImageMiniItem from './ImageMiniItem'
import RemoveIcon from '../../../assets/images/remove.svg';

import { useTranslation } from "react-i18next";



const getBadge = status => {
    switch (status) {
        case 'Active': return 'success'
        case 'InActive': return 'secondary'
        case 'Pending': return 'warning'
        case 'Banned': return 'danger'
        default: return 'primary'
    }
}

const DataTablePayments = ({ searchTextGot, editAccess }) => {

    const dispatch = useDispatch();
    const history = useHistory()
    const location = useLocation()
    const userState = useSelector((state) => state.user);
    const [activityData, setActivityData] = useState([]);
    const [selectedUser, setSelectedUser] = useState();
    const [page, setPage] = useState(1)
    const [closeModal, setCloseModal] = useState(false);
    const [confirmationDialogText, setConfirmationDialogText] = useState("Are you sure you want to change user status?");
    const [fieldG, setFieldG] = useState("")
    const [orderG, setOrderG] = useState("")

    const { t } = useTranslation();


    const [sortingFields, setSortingFields] = useState({
        firstName: "no",
        emailAddress: "no",
        planType: "no",
        lastPaid: "no",
        accountStatus: "no",
        paymentStatus: "no",
    });

    const paymentState = useSelector((state) => state.payment);

    const [updateApiMsg, setUpdateApiMsg] = useState("")

    const pageChange = newPage => {
        setPage(newPage)
        dispatch(getPaymentList(newPage, 10, searchTextGot, fieldG, orderG, onSuccess, onFailure));

    }

    useEffect(() => {
        setPage(1)
        dispatch(getPaymentList(1, 10, searchTextGot, fieldG, orderG, onSuccess, onFailure));

    }, [searchTextGot])

    useEffect(() => {
        dispatch(getPaymentList(1, 10, "", "", "", onSuccess, onFailure));

    }, [])




    const onSuccess = (res) => {
        setActivityData(res.data)
    }

    const onFailure = (errorMessage) => {
    }



    const yesButton = () => {


    }

    const noButtonClicked = () => {
        setCloseModal(false)
    }



    const applySorter = (columnName, sorttype) => {
        if (columnName) {

            const field = columnName;
            setPage(1)
            setFieldG(field)
            setOrderG(sorttype)
            dispatch(getPaymentList(1, 10, searchTextGot, field, sorttype, onSuccess, onFailure));

        }
        else {
            setPage(1)
            dispatch(getPaymentList(1, 10, searchTextGot, columnName, sorttype, onSuccess, onFailure));
        }
    }



    const sortFunc = (colName, e) => {
        var fields = sortingFields;
        Object.keys(fields).forEach(e => {
            fields[e] = "no"
        });

        let order = "";
        if (e.target.value === "no") {
            order = "ASC"
        }
        else if (e.target.value === "ASC") {
            order = "DESC"
        }
        else if (e.target.value === "DESC") {
            order = "no"
        }


        fields[colName] = order
        setSortingFields(fields)
        applySorter(order === "no" ? "" : colName, order === "no" ? "" : order)
    }


    return (

        <div>
            {closeModal &&
                <Confirmation body={confirmationDialogText} yesButtonClicked={yesButton} noButtonClicked={noButtonClicked} />
            }
            {updateApiMsg &&
                <CAlert show={5} color="success">
                    {updateApiMsg}
                </CAlert>
            }

            {paymentState.loading ? <Loader /> : null}

            {paymentState.payments.data &&
                <div className="table-responsive">
                    <table className="table table-striped table-hover">
                        <tr>
                            {/* <th>{t("accountIdTable")} <CButton value={sortingFields.name} className={sortingFields.name === "no" ? "check-no" : sortingFields.name === "ASC" ? "check-asc" : sortingFields.name === "DESC" ? "check-desc" : "check-no"} onClick={(e) => { sortFunc("name", e) }}
                            >{sortingFields.name}</CButton> </th> */}

                            <th>{t("usernameTable")} <CButton value={sortingFields.firstName} className={sortingFields.firstName === "no" ? "check-no" : sortingFields.firstName === "ASC" ? "check-asc" : sortingFields.firstName === "DESC" ? "check-desc" : "check-no"} onClick={(e) => { sortFunc("firstName", e) }}
                            >{sortingFields.firstName}</CButton> </th>

                            <th>{t("emailAddressTable")} <CButton value={sortingFields.emailAddress} className={sortingFields.emailAddress === "no" ? "check-no" : sortingFields.emailAddress === "ASC" ? "check-asc" : sortingFields.emailAddress === "DESC" ? "check-desc" : "check-no"} onClick={(e) => { sortFunc("emailAddress", e) }}
                            >{sortingFields.emailAddress}</CButton> </th>

                            <th>{t("planTypeTable")} 
                            {/* <CButton value={sortingFields.planType} className={sortingFields.planType === "no" ? "check-no" : sortingFields.planType === "ASC" ? "check-asc" : sortingFields.planType === "DESC" ? "check-desc" : "check-no"} onClick={(e) => { sortFunc("planType", e) }}
                            >{sortingFields.planType}</CButton>  */}
                            </th>

                            <th>{t("lastPaymentTable")} 
                            {/* <CButton value={sortingFields.lastPaid} className={sortingFields.lastPaid === "no" ? "check-no" : sortingFields.lastPaid === "ASC" ? "check-asc" : sortingFields.lastPaid === "DESC" ? "check-desc" : "check-no"} onClick={(e) => { sortFunc("lastPaid", e) }}
                            >{sortingFields.lastPaid}</CButton>  */}
                            </th>

                            <th>{t("accountStatusTable")} 
                            {/* <CButton value={sortingFields.accountStatus} className={sortingFields.accountStatus === "no" ? "check-no" : sortingFields.accountStatus === "ASC" ? "check-asc" : sortingFields.accountStatus === "DESC" ? "check-desc" : "check-no"} onClick={(e) => { sortFunc("accountStatus", e) }}
                            >{sortingFields.createdBy}</CButton>  */}
                            </th>

                            <th>{t("paymentStatusTable")} 
                            {/* <CButton value={sortingFields.paymentStatus} className={sortingFields.paymentStatus === "no" ? "check-no" : sortingFields.paymentStatus === "ASC" ? "check-asc" : sortingFields.paymentStatus === "DESC" ? "check-desc" : "check-no"} onClick={(e) => { sortFunc("paymentStatus", e) }}
                            >{sortingFields.paymentStatus}</CButton>  */}
                            </th>

                        </tr>
                        {paymentState.payments.data && paymentState.payments.data.map(item => {
                            return (

                                <tr 
                                onClick={(e) => {
                                    history.push({
                                        pathname: RouteConstants.VIEW_PAYMENTS.replace(":customerId", item.userId),
                                        data: item
                                    });
                                }
                                }>

                                    <td>{item.firstName && item.firstName} {item.lastName && item.lastName}</td>
                                    <td>{item.emailAddress}</td>
                                    <td>{item.planType}</td>
                                    <td>{DateHelper.formatDate(item.lastPaid)}</td>
                                    <td>
                                        <CBadge color={getBadge(getUserStatus(item.accountStatus))}>
                                        {getUserStatus(item.accountStatus)}</CBadge>                                        
                                    </td>
                                    <td>{getPaymentStatus(item.paymentStatus)}</td>

                                    
                                </tr>

                            )

                        })}

                        {paymentState.payments.data && paymentState.payments.data.length === 0 ?
                            <tr> <td colspan="6"> <PlaceHolder title="No Payments found."></PlaceHolder></td> </tr>
                            : null}



                    </table>


                    < CPagination
                        dots={true}
                        limit={6}
                        activePage={page}
                        onActivePageChange={pageChange}
                        pages={paymentState.payments.meta.pageCount}
                        doubleArrows={true}
                        align="center"
                    />
                </div>

            }


        </div>
    )
}

export default DataTablePayments
