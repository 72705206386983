import ActivityService from "src/services/api/ActivityService";
import PaymentService from "src/services/api/PaymentService";
import { request, success, failure } from ".";
import { PAYMENT_ACTIONS } from "../../constants/ActionConstants";


export const getPaymentList = (page, take, q, field, order, onSuccess, onFailure) => {
    return async (dispatch) => {
        dispatch(request(PAYMENT_ACTIONS.GET_PAYMENT_LIST, {}));
        try {
            const response = await PaymentService.getPaymentList(page, q, field, order);
            dispatch(success(PAYMENT_ACTIONS.GET_PAYMENT_LIST_SUCCESS, { data: response.data }));
            onSuccess(response.data);
        } catch (error) {
            dispatch(failure(PAYMENT_ACTIONS.GET_PAYMENT_LIST_FAILURE, {}));
            error.data && error.data.message && onFailure(error.data.message);
        }
    };
};

export const getPaymentHistory = (userId, subscriptionId, page, onSuccess, onFailure) => {
    return async (dispatch) => {
        dispatch(request(PAYMENT_ACTIONS.GET_PAYMENT_HISTORY, {}));
        try {
            const response = await PaymentService.getPaymentHistory(userId, subscriptionId, page);
            dispatch(success(PAYMENT_ACTIONS.GET_PAYMENT_HISTORY_SUCCESS, { data: response.data }));
            onSuccess(response.data);
        } catch (error) {
            dispatch(failure(PAYMENT_ACTIONS.GET_PAYMENT_HISTORY_FAILURE, {}));
            error.data && error.data.message && onFailure(error.data.message);
        }
    };
};


export const cancelSubscription = (data,onSuccess, onFailure) => {
    return async (dispatch) => {
        dispatch(request(PAYMENT_ACTIONS.CANCEL_SUBS, {}));
        try {
            const response = await PaymentService.cancelSubscription(data)

            dispatch(success(PAYMENT_ACTIONS.CANCEL_SUBS_SUCCESS, {}));
            onSuccess(response.message);
        } catch (error) {
            dispatch(failure(PAYMENT_ACTIONS.CANCEL_SUBS_FAILURE, {}));
            onFailure(error.data.message);
        }
    };
};

export const downloadInvoice = (id,onSuccess, onFailure) => {
    return async (dispatch) => {
        dispatch(request(PAYMENT_ACTIONS.GENERAL, {}));
        try {
            const response = await PaymentService.downloadInvoice(id)

            dispatch(success(PAYMENT_ACTIONS.GENERAL_SUCCESS, {}));
            onSuccess(response.data);
        } catch (error) {
            dispatch(failure(PAYMENT_ACTIONS.GENERAL_FAILURE, {}));
            onFailure(error.data.message);
        }
    };
};
