import React, { Component, useEffect } from 'react';
import { HashRouter, Redirect, Route, Switch } from 'react-router-dom';
import { LOCAL_STORAGE_KEYS } from './constants/GenericConstants';
import './scss/style.scss';
import { AppEnvironment } from './services/api/ApiClient';
import SetPassword from './views/pages/auth/SetPassword';
import { registerDeviceForPushNotifications } from "./store/actions/Authentication";
import InactivityLogout from './routes/InactivityLogout';

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'));

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'));
const Register = React.lazy(() => import('./views/pages/register/Register'));
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'));
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'));

const PrivateRoute = ({ component: Component, ...rest }) => {
  return (

    <Route {...rest} render={props => (
      isLogin() ?
        <TheLayout {...props} />
        : <Redirect to="/" />
    )} />
  );
};

const PrivateRouteLoggedIn = ({ component: Component, ...rest }) => {
  return (

    <Route {...rest} render={props => (
      !isLogin() ?
        <Login {...props} />
        : <Redirect to="/home" />
    )} />
  );
};

const isLogin = () => {
  const userGot = localStorage.getItem(LOCAL_STORAGE_KEYS.USER); // Set your access token here
  if (userGot !== null) {
    return true;
  }
  else {
    return false;
  }
}


const App = () => {

  useEffect(() => {

    // window.OneSignal = window.OneSignal || [];
    // const OneSignal = window.OneSignal;

    // OneSignal.push(function () {
    //   OneSignal.init({
    //     appId: AppEnvironment.config().oneSignal.appId,
    //     safari_web_id: AppEnvironment.config().oneSignal.safariId,
    //     notifyButton: {
    //       enable: true,
    //     },
    //     allowLocalhostAsSecureOrigin: true,
    //     notificationClickHandlerMatch: 'origin',
    //     notificationClickHandlerAction: 'focus',
    //   });
    // });
    // if ('serviceWorker' in navigator && !navigator.serviceWorker.controller) {
    //   navigator.serviceWorker.register('/OneSignalSDKWorker.js');
    // }


    // OneSignal.push(function () {
    //   // Occurs when the user's subscription changes to a new value.
    //   OneSignal.on('subscriptionChange', function (isSubscribed) {
    //     // console.log("The user's subscription state is:", isSubscribed);
    //     if (isSubscribed) {
    //       registerDeviceForPushNotifications();
    //     }
    //   });
    // });

  }, [])

  return (
    <HashRouter>
      <InactivityLogout/>
      <React.Suspense fallback={loading}>
        <Switch>
          <PrivateRouteLoggedIn exact path="/" name="Login Page" />
          <Route exact path="/register" name="Register Page" render={props => <Register {...props} />} />
          <Route exact path="/404" name="Page 404" render={props => <Page404 {...props} />} />
          <Route exact path="/500" name="Page 500" render={props => <Page500 {...props} />} />
          <PrivateRoute path="/home" name="Home" />
          <Route exact path="/setPassword" name="Set Password" render={props => <SetPassword {...props} />} />
        </Switch>
      </React.Suspense>
    </HashRouter>
  );

}

export default App;
