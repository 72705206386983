import axios from "axios";
import { LOCAL_STORAGE_KEYS } from "src/constants/GenericConstants";
import UrlConstants from "src/constants/UrlConstants";

export const apiClient = () => {
  const baseUrl = AppEnvironment.config().baseURL;
  const token = localStorage.getItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN); // Set your access token here
  const defaultOptions = {
    headers: {
      Authorization: token ? `Bearer ${token || null}` : "",
      "Content-Type": "application/json",
    },
  };

  const imageUploadHeaders = {
    headers: {
      Authorization: token ? `Bearer ${token || null}` : "",
      "Content-Type": "image/*",
    },
  };

  const pdfDownloadHeaders = {
    headers: {
      Authorization: token ? `Bearer ${token || null}` : "",
      responseType: 'blob'
    },
  };

  return {
    get: (url, options = {}) =>
      axios.get(`${baseUrl}${url}`, { ...defaultOptions, ...options }),
    downloadGet: (url, options = {}) =>
      axios.get(`${baseUrl}${url}`, { ...pdfDownloadHeaders, ...options }),
    post: (url, data, options = {}) =>
      axios.post(`${baseUrl}${url}`, data, { ...defaultOptions, ...options }),
    put: (url, data, options = {}) =>
      axios.put(`${baseUrl}${url}`, data, { ...defaultOptions, ...options }),
    uploadImage: (url, data, options = {}) =>
      axios.put(url, data, { ...imageUploadHeaders, ...options }),
    delete: (url, options = {}) =>
      axios.delete(`${baseUrl}${url}`, { ...defaultOptions, ...options }),
  };
};

export const BuildMode = {
  BUILD_MODE_LOCAL: "local",
  BUILD_MODE_DEV: "dev",
  BUILD_MODE_QA: "qa",
  BUILD_MODE_STAGING: "staging",
  BUILD_MODE_PROD: "prod",
  BUILD_MODE_DEMO: "demo",
};

export const AppEnvironment = {
  // comment out one from following buildMode from which server you want to get data

  // buildMode: BuildMode.BUILD_MODE_LOCAL,
//  buildMode: BuildMode.BUILD_MODE_QA,
 //  buildMode: BuildMode.BUILD_MODE_QA,
  // buildMode: BuildMode.BUILD_MODE_DEMO,
 //  buildMode: BuildMode.BUILD_MODE_STAGING,
  buildMode: BuildMode.BUILD_MODE_PROD,

  allConfigurations: {
    local: {
      baseURL: "http://localhost:3000",
      oneSignal: { appId: "", safariId: "" }

    },
    dev: {
      baseURL: "https://demo4.folio3.com:3280",
      oneSignal: { appId: "f101cdea-4518-4e6b-9dbd-b4b1e5b89a06", safariId: "web.onesignal.auto.05605657-a1ec-46ab-8d61-441038586900" }

    },
    qa: {
      baseURL: "https://demo4.folio3.com:4000",
      oneSignal: { appId: "280db4bd-d35a-4e4a-9b1a-f181d94aae77", safariId: "web.onesignal.auto.09206a8d-cae3-491c-ad76-7a8d47a79aca" }

    },
    staging: {
      baseURL: "https://thrive.folio3.com:3028",
      oneSignal: { appId: "f5972a34-fa96-4403-83d7-c5a820f6b408", safariId: "web.onesignal.auto.0654afc0-ce06-4b31-9757-37165e976417" }

    },
    prod: {
      baseURL: "https://api.thrivingonthespectrum.org",
      oneSignal: { appId: "", safariId: "" }

    },
    demo: {
      baseURL: "",
      oneSignal: { appId: "", safariId: "" }

    },
  },

  config(buildMode) {
    const mode = buildMode || this.buildMode;
    return this.allConfigurations[mode];
  },
};
