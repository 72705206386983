export const STATUS_CODES = {
    SUCCESS: 0,
    BAD_REQUEST: 400,
    UNAUTHORIZED: 401,
    FORBIDDEN: 403,
    NOT_FOUND: 404,
    INTERNAL_SERVER_ERROR: 500,
    VERIFY_CODE: 31,
    FOR_SELF_CODE: 33,
    FOR_SELF_CODE_EDIT: 36,
}; 