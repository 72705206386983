import { GET_CHILDREN, GET_EXTERNAL_USER, GET_PARENT_EX_USERS, UPDATE_CUST_USER, UPDATE_EXUSER_STATUS } from "../../constants/ActionConstants";

let initialState = {
    loading: false,
    customerList: {}
};

const customer = (state = initialState, action) => {

    switch (action.type) {
        case GET_EXTERNAL_USER.GET_EXTERNAL_USER:
            return {
                ...state,
                loading: true,
            };

        case GET_EXTERNAL_USER.GET_EXTERNAL_USER_SUCCESS:
            return {
                ...state,
                loading: false,
                customerList: action.payload.data

            };

        case GET_EXTERNAL_USER.GET_EXTERNAL_USER_FAILURE:
            return {
                ...state,
                loading: false,
            };

        case GET_CHILDREN.GET_CHILDREN:
            return {
                ...state,
                loading: true,
            };

        case GET_CHILDREN.GET_CHILDREN_SUCCESS:
            return {
                ...state,
                loading: false,
            };

        case GET_CHILDREN.GET_CHILDREN_FAILURE:
            return {
                ...state,
                loading: false,
            };

        case GET_PARENT_EX_USERS.GET_PARENT_EX_USERS:
            return {
                ...state,
                loading: true,
            };

        case GET_PARENT_EX_USERS.GET_PARENT_EX_USERS_SUCCESS:
            return {
                ...state,
                loading: false,
            };

        case GET_PARENT_EX_USERS.GET_PARENT_EX_USERS_FAILURE:
            return {
                ...state,
                loading: false,
            };

        case UPDATE_CUST_USER.UPDATE_CUST_USER:
            return {
                ...state,
                loading: true,
            };

        case UPDATE_CUST_USER.UPDATE_CUST_USER_SUCCESS:
            return {
                ...state,
                loading: false
            };

        case UPDATE_CUST_USER.UPDATE_CUST_USER_FAILURE:
            return {
                ...state,
                loading: false,
            };

        case UPDATE_EXUSER_STATUS.UPDATE_EXUSER_STATUS:
            return {
                ...state,
                loading: true,
            };

        case UPDATE_EXUSER_STATUS.UPDATE_EXUSER_STATUS_SUCCESS:
            return {
                ...state,
                loading: false,
            };

        case UPDATE_EXUSER_STATUS.UPDATE_EXUSER_STATUS_FAILURE:
            return {
                ...state,
                loading: false,
            };

        default:
            return state;
    }
};

export default customer;
