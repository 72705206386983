import { apiClient } from "./ApiClient";
import UrlConstants from "../../constants/UrlConstants";
import { handleResponse, handleError } from "./GenericResponseHandler";

class UserService {

    addCoAdmin(firstName, lastName, email, permission) {
        let url = UrlConstants.ADD_COADMIN;
        const payload = {
            firstName: firstName,
            lastName: lastName,
            emailAddress: email,
            permissions: permission
        };
        return apiClient()
            .post(url, payload)
            .then(handleResponse)
            .catch(handleError);
    }

    getUsers(page, take) {
        let url = UrlConstants.GET_USERS + "?page=" + page + "&take=" + take;
        return apiClient()
            .get(url)
            .then(handleResponse)
            .catch(handleError);
    }

    getSearchedUsers(page, take, q) {
        const encodedQuery = encodeURIComponent(q);

        let url = UrlConstants.GET_USERS + "?page=" + page + "&take=" + take + "&q=" + encodedQuery;
        return apiClient()
            .get(url)
            .then(handleResponse)
            .catch(handleError);
    }

    getSortedUsers(page, take, field, order) {
        let url = UrlConstants.GET_USERS + "?page=" + page + "&take=" + take + "&field=" + field + "&order=" + order;
        return apiClient()
            .get(url)
            .then(handleResponse)
            .catch(handleError);
    }

    getSearchedSortedUsers(page, take, q, field, order) {
        const encodedQuery = encodeURI(q);
        let url = UrlConstants.GET_USERS + "?page=" + page + "&take=" + take + "&q=" + encodedQuery + "&field=" + field + "&order=" + order;
        return apiClient()
            .get(url)
            .then(handleResponse)
            .catch(handleError);
    }


    getSearchedCustomerUsers(page, take, q) {
        const encodedQuery = encodeURI(q);
        let url = UrlConstants.GET_EXTERNAL_USERS + "?page=" + page + "&take=" + take + "&q=" + encodedQuery;
        return apiClient()
            .get(url)
            .then(handleResponse)
            .catch(handleError);
    }

    getCustomerUsers(page, take) {
        let url = UrlConstants.GET_EXTERNAL_USERS + "?page=" + page + "&take=" + take;
        return apiClient()
            .get(url)
            .then(handleResponse)
            .catch(handleError);
    }

    updateUserStatus(user, status) {

        let url = UrlConstants.UPDATE_USER_STATUS;
        const payload = {
            userId: user.id,
            status: status,
            accountId: user.accountId

        };
        return apiClient()
            .post(url, payload)
            .then(handleResponse)
            .catch(handleError);
    }


    updateUser(userId, firstName, lastName, accountId, permissions) {

        let url = UrlConstants.UPDATE_USER;
        const payload = {
            userId: userId,
            firstName: firstName,
            lastName: lastName,
            permissions: parseInt(permissions),
            accountId: accountId
        };
        return apiClient()
            .post(url, payload)
            .then(handleResponse)
            .catch(handleError);
    }

    removeUser(userId) {

        let url = UrlConstants.REMOVE_USER;
        const payload = {
            userId: userId
        };

        return apiClient()
            .post(url, payload)
            .then(handleResponse)
            .catch(handleError);
    }


}

export default new UserService();