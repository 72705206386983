import React, { useState, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CRow,
  CButton,
  CInput,
  CAlert,
  CImg,
  CModal, CModalBody, CModalFooter, CModalHeader, CInputCheckbox
} from '@coreui/react'
import RouteConstants from 'src/constants/RouteConstants'
import AddImageModal from './components/AddImageModal'
import { useTranslation } from "react-i18next";
import EditImageModal from './components/EditImageModal'
import { useDispatch, useSelector } from 'react-redux'
import { getCategories } from 'src/store/actions/Categories'
import { getCategoryFilterImageLibList, getImageLibList, getImageLibPageList, getSearchedCategoryFilterImageLibList, getSearchedImageLibList } from 'src/store/actions/ImageLib'
import ImageProfile from './components/ImageProfile'
import Loader from '../common/Loader/Loader'
import InfiniteScroll from 'react-infinite-scroller';
import PlaceHolder from '../common/PlaceHolder'
import { PRIV_CONSTS } from 'src/constants/StaticData'
import { getEditPermValue, getViewPermValue } from 'src/helpers/PermissionCalculator'



const ImageGallery = () => {
  const history = useHistory()
  const location = useLocation()
  const dispatch = useDispatch();
  const [searchText, setSearchText] = useState("")
  const [openModal, setOpenModal] = useState(false)
  const [openModalAdd, setOpenModalAdd] = useState(false)
  const [loadMoreText, setLoadMoreText] = useState("Load more")

  const imageLibraryState = useSelector((state) => state.imageLibrary);
  const user = useSelector((state) => state.authentication.user);

  const [ref, setRef] = useState(null);



  const [categories, setCategories] = useState([])
  const [localCategories, setLocalCategories] = useState([])
  const [selectedCat, setSelectedCat] = useState([]);

  const [imageLibList, setImageLibList] = useState([])
  const [selectedImageProfile, setSelectedImageProfile] = useState({})
  const [apiRes, setApiRes] = useState("");
  const [isEmpty, setIsEmpty] = useState(false);
  const [allSelected, setAllSelected] = useState(false);

  const [imageViewAccess, setImageViewAccess] = useState(false)
  const [imageViewEditAccess, setImageViewEditAccess] = useState(false)

  const { t } = useTranslation();


  const handleModal = (imageProfile) => {
    setSelectedImageProfile(imageProfile)
    setOpenModal(prev => !prev)

  }
  const handleModalAdd = () => {
    setOpenModalAdd(prev => !prev)
  }

  useEffect(() => {
    if (user.accounts) {
      let viewPerm = getViewPermValue(PRIV_CONSTS.IMAGE_PRIV)
      let editPerm = getEditPermValue(PRIV_CONSTS.IMAGE_PRIV)
      setImageViewAccess(viewPerm)
      setImageViewEditAccess(editPerm)
      if (viewPerm) {
        dispatch(getCategories(onSuccess, onFailure))
        dispatch(getImageLibList(1, searchText, selectedCat, onLibRetrieved, onFailure))
      }
    }


  }, [])

  useEffect(() => {
    if (user.accounts) {
      let viewPerm = getViewPermValue(PRIV_CONSTS.IMAGE_PRIV)
      let editPerm = getEditPermValue(PRIV_CONSTS.IMAGE_PRIV)
      setImageViewAccess(viewPerm)
      setImageViewEditAccess(editPerm)
      if (viewPerm) {
        dispatch(getCategories(onSuccess, onFailure))
        dispatch(getImageLibList(1, searchText, selectedCat, onLibRetrievedNotif, onFailure))
      }
    }
    
  }, [location.id])



  useEffect(() => {
    if (apiRes !== "") {
      dispatch(getImageLibList(1, searchText, selectedCat, onLibRetrieved, onFailure))
    }
  }, [apiRes])

  useEffect(() => {
    dispatch(getImageLibList(1, searchText, selectedCat, onLibRetrieved, onFailure))
    setApiRes(imageLibraryState.addedImage.message)

  }, [imageLibraryState.addedImage])

  const onSuccess = (res) => {
    setCategories(res)
    let temp = [];
    res.map(c => {
      temp.push({ name: c.name, value: c.value, selected: false })
    })
    temp = temp.concat({ name: "all", value: "Show all", selected: true })

    setLocalCategories(temp)
  }
  const onLibRetrieved = (res) => {

    if (res.meta && res.meta.page !== 1) {
      let objDiv = document.getElementById("scrollDiv");
      objDiv.scrollTop = objDiv.scrollHeight;
    }

    setLoadMoreText("Load more")


    if (res.data && res.data.length === 0) {
      setIsEmpty(true)
    }
  }
  const onLibRetrievedNotif = (res) => {

    if (res.meta && res.meta.page !== 1) {
      let objDiv = document.getElementById("scrollDiv");
      objDiv.scrollTop = objDiv.scrollHeight;
    }

    setLoadMoreText("Load more")


    if (res.data && res.data.length === 0) {
      setIsEmpty(true)
    }

    if (location.id) {
      var foundImg = null;
      res.data?.map(img => {
        if (img.id === location.id) {
          foundImg = img
        }
      })
      // alert(location.id+"---"+JSON.stringify(foundImg)+"---"+imageLibraryState.imagesList.length+"---"+JSON.stringify(res))
      foundImg && handleModal(foundImg)
    }

  }

  const onFailure = (err) => {

  }


  const searchLib = (text) => {
    setIsEmpty(false)
    setImageLibList([])
    dispatch(getImageLibList(1, text, selectedCat, onLibRetrieved, onFailure))
  }

  const categoryFilter = (catList) => {
    setIsEmpty(false)
    setImageLibList([])
    dispatch(getImageLibList(1, searchText, catList, onLibRetrieved, onFailure))

  }

  const getImagesGrid = () => {
    return imageLibraryState.imagesList.map((iprofile, index) => (
      <ImageProfile data={iprofile} openEditModal={handleModal} />
    ));
  }

  const fetchMoreImages = () => {
    if (!imageLibraryState.loading && !imageLibraryState.secondLoading && imageLibraryState.metaImagesList.hasNextPage) {
      setLoadMoreText("Loading...")
      dispatch(getImageLibList(imageLibraryState.metaImagesList.page + 1, searchText, selectedCat, onLibRetrieved, onFailure));
    }
  }



  return (
    <CRow>
      {imageLibraryState.loading && <Loader />}




      <CCol xl={12}>
        <CCard>
          <CCardHeader>
            <CRow className="align-items-center">
              <CCol sm="7" xs="12">
                <h5 className="page-title py-2">{t("imageLib")}</h5>
              </CCol>
              {/* <small className="text-muted"> example</small> */}
              <CCol className="search-n-btn" sm="5" xs="12">
                <CInput
                  placeholder="Search"
                  size="sm"
                  disabled={!imageViewAccess}
                  onKeyUp={(e) => {
                    if (e.key === 'Enter') {
                      setSearchText(e.target.value)
                      searchLib(e.target.value)
                    }
                    if (e.key === 'Backspace') {
                      if (e.target.value === "") {
                        setSearchText("")
                        searchLib(e.target.value)
                      }
                    }
                  }}
                  size="md"
                />

              </CCol>
            </CRow>

            <CRow className="align-items-center mt-2">
              <CCol sm="9" xs="12" className="check-filters">
                {localCategories.map(cat => {
                  let props = {}
                  if (cat.name === "all") {
                    props = { defaultChecked: true }
                  }
                  else {
                    props = {}
                  }

                  return (
                    <div className="filter-items">
                      <CInputCheckbox
                        id="checkbox1"
                        // {...props}
                        checked={cat.selected}
                        name={cat.name}
                        value={cat.value}
                        onChange={(e) => {
                          var catSelected = selectedCat;
                          if (e.target.checked) {
                            if (e.target.name === "all") {
                              let temp = [];
                              localCategories.map(c => {
                                if (c.name !== "all") {
                                  temp.push({ ...c, selected: false })
                                }
                              })
                              temp.push({ name: "all", value: "Show all", selected: true })
                              setLocalCategories(temp)
                              setSelectedCat([])
                              categoryFilter([])

                            }
                            else {
                              let temp = [];
                              localCategories.map(c => {
                                if (c.name === e.target.name) {
                                  temp.push({ ...c, selected: true })
                                }
                                else if (c.selected === true && c.name !== "all") {
                                  temp.push({ ...c, selected: true })
                                }
                                else if (c.name === "all") {
                                  temp.push({ ...c, selected: false })
                                }
                                else {
                                  temp.push({ ...c, selected: false })
                                }
                              })
                              setLocalCategories(temp)

                              catSelected = catSelected.filter(item => item !== "all")
                              catSelected.push(e.target.name)
                              setSelectedCat(catSelected)
                              categoryFilter(catSelected)
                            }
                          }
                          else {
                            let temp = [];
                            let allDeselect = true
                            localCategories.map(c => {
                              if (c.name === e.target.name) {
                                temp.push({ ...c, selected: false })
                              }
                              else {
                                temp.push({ ...c })
                              }
                              if (c.name !== e.target.name && c.selected === true) {
                                allDeselect = false;
                              }
                            })
                            if (allDeselect) {
                              temp = temp.map(p =>
                                p.name === 'all'
                                  ? { ...p, selected: true }
                                  : p
                              );
                            }

                            setLocalCategories(temp)

                            catSelected = catSelected.filter(item => item !== e.target.name)
                            setSelectedCat(catSelected)
                            categoryFilter(catSelected)


                          }
                        }}
                      />
                      <span>{cat.value}</span>
                    </div>
                    // <CButton type="button" class="btn btn-pill btn-dark mr-1">{cat.name}</CButton>
                  )
                })}

              </CCol>
              {/* <small className="text-muted"> example</small> */}
              <CCol className="search-n-btn" sm="3" xs="12">

                <CButton
                  disabled={!imageViewEditAccess}
                  onClick={() => handleModalAdd()}
                  variant="outline" color="primary" className="my-2">{t("addNew")}</CButton>
              </CCol>
            </CRow>

            {apiRes &&
              <CAlert show={5} color="success">
                {apiRes}
              </CAlert>
            }

          </CCardHeader>

          <CCardBody className="image-thumb-list">


            {isEmpty ? <PlaceHolder title={t("noImageFound")}></PlaceHolder> : null}



            <div id="scrollDiv" ref={ref => { setRef(ref); }} className="scrollDiv">
              <div class="px-15">
                <InfiniteScroll
                  pageStart={1}
                  // loadMore={fetchMoreImages}
                  hasMore={imageLibraryState.metaImagesList.hasNextPage}
                  // loader={<div className="loader mr-auto"> Loading..</div>}

                  useWindow={false}
                  initialLoad={true}
                  threshold={10}
                  getScrollParent={() => ref}>
                  <CRow>
                    {imageViewAccess && imageLibraryState.imagesList.map((iprofile, index) => {
                      return <ImageProfile data={iprofile} key={iprofile.id} openEditModal={handleModal} />
                    })}
                  </CRow>
                </InfiniteScroll>
              </div>
              <div className="loadmore-imgLib">
                {imageLibraryState.metaImagesList.hasNextPage ?
                  <CButton onClick={() => {
                    fetchMoreImages()
                  }}
                  >{loadMoreText}</CButton> : null}</div>
            </div>


          </CCardBody>
          {openModalAdd ?
            <AddImageModal
              openModal={openModalAdd}
              handleModal={handleModalAdd}
              categories={categories}
              setApiRes={setApiRes}
            />
            : null}

          {openModal ?
            <EditImageModal
              openModal={openModal}
              handleModal={handleModal}
              categoriesGot={categories}
              data={selectedImageProfile}
              setApiRes={setApiRes}
              editPerm={imageViewEditAccess}
            />
            : null}


        </CCard>
      </CCol>
    </CRow>
  )
}

export default ImageGallery
