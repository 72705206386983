import React, { useEffect, useState } from 'react'
import {
    CAlert,
    CBadge,
    CButton,
    CDataTable,
    CImg,
    CInputCheckbox,
    CLabel,
    CPagination,
    CSwitch,

} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { useHistory, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getUsers, getSearchedUsers, updateUserStatus, getSortedUsers, getSearchedSortedUsers } from 'src/store/actions/User'
import { APP_CONSTANTS } from 'src/constants/GenericConstants'
import { getPlanType, getUserStatus } from 'src/helpers/GenericHelper'
import Loader from 'src/views/common/Loader/Loader'
import RouteConstants from 'src/constants/RouteConstants'
import disable from '../../../assets/images/user-disable.svg';
import DateHelper from 'src/helpers/DateHelper'
import Confirmation from 'src/views/common/Confirmation'
import PlaceHolder from 'src/views/common/PlaceHolder'
import ShowAlert from 'src/views/common/ShowAlert'
import { freeSet } from '@coreui/icons'
import { getPaymentHistory } from 'src/store/actions/Payment'
import ImageMiniItem from './ImageMiniItem'
import RemoveIcon from '../../../assets/images/remove.svg';

import { useTranslation } from "react-i18next";
import InvoicePopup from './InvoicePopup'



const getBadge = status => {
    switch (status) {
        case 'Active': return 'success'
        case 'InActive': return 'secondary'
        case 'Pending': return 'warning'
        case 'Banned': return 'danger'
        default: return 'primary'
    }
}

const DataTablePayHistory = ({ searchTextGot, editAccess, data, name }) => {

    const dispatch = useDispatch();
    const history = useHistory()
    const location = useLocation()
    const userState = useSelector((state) => state.user);
    const [activityData, setActivityData] = useState([]);
    const [selectedData, setSelectedData] = useState({});
    const [page, setPage] = useState(1)
    const [closeModal, setCloseModal] = useState(false);
    const [invoiceModal, setInvoiceModal] = useState(false);
    const [confirmationDialogText, setConfirmationDialogText] = useState("Are you sure you want to change user status?");
    const [fieldG, setFieldG] = useState("")
    const [orderG, setOrderG] = useState("")

    const { t } = useTranslation();

    const handleInvoiceModal = () => {
        setInvoiceModal(prev => !prev)
    }

    const [sortingFields, setSortingFields] = useState({
        name: "no",
        subTasksCount: "no",
        createdAt: "no",
        createdBy: "no",
    });

    const paymentState = useSelector((state) => state.payment);

    const [updateApiMsg, setUpdateApiMsg] = useState("")

    const pageChange = newPage => {
        setPage(newPage)
        dispatch(getPaymentHistory(data.userId, data.subscriptionId, newPage, onSuccess, onFailure));

    }

    useEffect(() => {
        setPage(1)
        if (data !== null) {
            dispatch(getPaymentHistory(data.userId, data.subscriptionId, 1, onSuccess, onFailure));
        }

    }, [data])

    useEffect(() => {
        if (data !== null) {
            dispatch(getPaymentHistory(data.userId, data.subscriptionId, 1, onSuccess, onFailure));
        }
    }, [])




    const onSuccess = (res) => {
        setActivityData(res.data)
    }

    const onFailure = (errorMessage) => {
    }



    const yesButton = () => {


    }

    const noButtonClicked = () => {
        setCloseModal(false)
    }



    const applySorter = (columnName, sorttype) => {
        if (columnName) {

            const field = columnName;
            setPage(1)
            setFieldG(field)
            setOrderG(sorttype)
            dispatch(getPaymentHistory(data.userId, data.subscriptionId, 1, onSuccess, onFailure));

        }
        else {
            setPage(1)
            dispatch(getPaymentHistory(data.userId, data.subscriptionId, 1, onSuccess, onFailure));
        }
    }



    const sortFunc = (colName, e) => {
        var fields = sortingFields;
        Object.keys(fields).forEach(e => {
            fields[e] = "no"
        });

        let order = "";
        if (e.target.value === "no") {
            order = "ASC"
        }
        else if (e.target.value === "ASC") {
            order = "DESC"
        }
        else if (e.target.value === "DESC") {
            order = "no"
        }


        fields[colName] = order
        setSortingFields(fields)
        applySorter(order === "no" ? "" : colName, order === "no" ? "" : order)
    }


    return (

        <div className="full-width">
            {closeModal &&
                <Confirmation body={confirmationDialogText} yesButtonClicked={yesButton} noButtonClicked={noButtonClicked} />
            }
            {updateApiMsg &&
                <CAlert show={5} color="success">
                    {updateApiMsg}
                </CAlert>
            }

            {paymentState.loading ? <Loader /> : null}

            {paymentState.history.data &&
                <div className="table-responsive payment-history">
                    <table className="table table-striped table-hover">
                        <tr>
                            <th>{t("datePaidTable")}
                                {/* <CButton value={sortingFields.name} className={sortingFields.name === "no" ? "check-no" : sortingFields.name === "ASC" ? "check-asc" : sortingFields.name === "DESC" ? "check-desc" : "check-no"} onClick={(e) => { sortFunc("name", e) }}
                            >{sortingFields.name}</CButton>  */}
                            </th>

                            <th>{t("dueDateTable")}
                                {/* <CButton value={sortingFields.name} className={sortingFields.name === "no" ? "check-no" : sortingFields.name === "ASC" ? "check-asc" : sortingFields.name === "DESC" ? "check-desc" : "check-no"} onClick={(e) => { sortFunc("name", e) }}
                            >{sortingFields.name}</CButton>  */}
                            </th>

                            <th>{t("planTable")}
                                {/* <CButton value={sortingFields.subTasksCount} className={sortingFields.subTasksCount === "no" ? "check-no" : sortingFields.subTasksCount === "ASC" ? "check-asc" : sortingFields.subTasksCount === "DESC" ? "check-desc" : "check-no"} onClick={(e) => { sortFunc("subTasksCount", e) }}
                            >{sortingFields.subTasksCount}</CButton>  */}
                            </th>

                            <th>{t("paymentMethodTable")}
                                {/* <CButton value={sortingFields.createdAt} className={sortingFields.createdAt === "no" ? "check-no" : sortingFields.createdAt === "ASC" ? "check-asc" : sortingFields.createdAt === "DESC" ? "check-desc" : "check-no"} onClick={(e) => { sortFunc("createdAt", e) }}
                            >{sortingFields.createdAt}</CButton>  */}
                            </th>

                            <th></th>


                        </tr>
                        {paymentState.history.data && paymentState.history.data.map(item => {
                            return (

                                <tr>

                                    <td>{DateHelper.formatDate(item.paymentDate)}</td>
                                    <td>{DateHelper.formatDate(item.dueDate)}</td>
                                    <td>${item.subscriptionAmount}/{getPlanType(item.planType)}</td>
                                    <td>Stripe</td>
                                    <td>
                                        <span className="btn viewButton" onClick={(e) => {
                                            setSelectedData(item)
                                            setInvoiceModal(true)
                                        }
                                        }>VIEW</span>
                                    </td>
                                </tr>

                            )

                        })}

                        {paymentState.history.data && paymentState.history.data.length === 0 ?
                            <tr> <td colspan="6"> <PlaceHolder title="No History found."></PlaceHolder></td> </tr>
                            : null}



                    </table>


                    < CPagination
                        dots={true}
                        limit={6}
                        activePage={page}
                        onActivePageChange={pageChange}
                        pages={paymentState.history.meta.pageCount}
                        doubleArrows={true}
                        align="center"
                    />
                </div>

            }

            {invoiceModal ? <InvoicePopup openModal={invoiceModal} handleModal={handleInvoiceModal} data={{...selectedData, name}} /> : null}

        </div>
    )
}

export default DataTablePayHistory
