import React, { useState } from 'react'
import {
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
  CImg
} from '@coreui/react'
import RouteConstants from 'src/constants/RouteConstants'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { logout } from "src/store/actions/Authentication";
import Confirmation from 'src/views/common/Confirmation'
import { useTranslation } from "react-i18next";
import CIcon from "../assets/images/admin-logo.png";


const TheHeaderDropdown = () => {

  const history = useHistory();
  const dispatch = useDispatch();
  const [closeModal, setCloseModal] = useState(false);
  const { t } = useTranslation();



  const noButtonClicked = () => {
    setCloseModal(false)
  }
  
  const openModal = () => {
    setCloseModal(true)
  }

  const logoutPressed = () => {
    dispatch(logout(onSuccessLogout, onFailureLogout))
    setCloseModal(false)
  }

  const onSuccessLogout = () => {
    history.push(RouteConstants.BASE)
  }

  const onFailureLogout = (errorMessage) => {

  }

  return (
    <CDropdown
      inNav
      className="c-header-nav-items mx-2"
      direction="down"
    >
      <CDropdownToggle className="c-header-nav-link" caret={false}>
        <div className="c-avatar">
          <CImg
            // src={'avatars/8.jpg'}
            src={CIcon}
            className="c-avatar-img"
            alt="admin@bootstrapmaster.com"
          />
        </div>
      </CDropdownToggle>
      <CDropdownMenu className="pt-0" placement="bottom-end">
        <CDropdownItem onClick={() => openModal()}>
          Log Out
        </CDropdownItem>
      </CDropdownMenu>
      { closeModal &&
        <Confirmation body={t("logoutMsg")} yesButtonClicked={logoutPressed} noButtonClicked={noButtonClicked} />
      }
    </CDropdown>
  )
}

export default TheHeaderDropdown
