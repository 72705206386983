import React from 'react'
import CIcon from '@coreui/icons-react'
import RouteConstants from 'src/constants/RouteConstants'
import customer from '../assets/images/customer.svg';
import ImgLibIcon from '../assets/images/img-lib-icon.svg';
import ImgActivityIcon from '../assets/images/activity-icon.svg';
import EmotionRegulationIcon from '../assets/images/emotion-regulaton.svg';
import PaymentIcon from '../assets/images/payment-icon.png';
import { PRIV_CONSTS } from 'src/constants/StaticData';
const _nav = [


  {
    _tag: 'CSidebarNavItem',
    name: 'Customer Accounts',
    to: RouteConstants.CUSTOMER_USER,
    show: true,
    priv: PRIV_CONSTS.PARENT_PRIV,
    icon: <span className="icon-holder"><CIcon className="sidebar-icon" src={customer} customClasses="c-sidebar-nav-icon" /></span>,
  },
  {
    _tag: 'CSidebarNavItem',
    name: 'Users Management',
    to: RouteConstants.USER_LIST,
    show: true,
    priv: PRIV_CONSTS.USER_PRIV,

    icon: <CIcon name="cil-people" customClasses="c-sidebar-nav-icon" />,
  },
  {
    _tag: 'CSidebarNavItem',
    name: 'Image Library',
    to: RouteConstants.IMAGE_LIB,
    show: true,
    priv: PRIV_CONSTS.IMAGE_PRIV,
    icon: <span className="icon-holder"><CIcon className="sidebar-icon" src={ImgLibIcon} customClasses="c-sidebar-nav-icon" /></span>,
  },

  {
    _tag: 'CSidebarNavItem',
    name: 'Activities',
    to: RouteConstants.ACTIVITY,
    show: true,
    priv: PRIV_CONSTS.ACTIVITY_PRIV,
    icon: <span className="icon-holder"><CIcon className="sidebar-icon" src={ImgActivityIcon} customClasses="c-sidebar-nav-icon" /></span>,
  },

  {
    _tag: 'CSidebarNavItem',
    name: 'Emotion Regulation',
    to: RouteConstants.SELF_REGULATION,
    show: true,
    priv: PRIV_CONSTS.EMOTION_PRIV,
    icon: <span className="icon-holder"><CIcon className="sidebar-icon" src={EmotionRegulationIcon} customClasses="c-sidebar-nav-icon" /></span>,
  },
  
  {
    _tag: 'CSidebarNavItem',
    name: 'Payments',
    to: RouteConstants.PAYMENTS,
    show: true,
    priv: PRIV_CONSTS.PAYMENT_PRIV,
    icon: <span className="icon-holder"><CIcon className="sidebar-icon" src={PaymentIcon} customClasses="c-sidebar-nav-icon" /></span>,
  },

]

export default _nav
