import { PAYMENT_STATUS, PLAN_TYPE, STATUS, SUBSCRIPTION_STATUS } from "src/constants/GenericConstants";
import usersData from "src/views/users/UsersData";

export function formatTimerNumber(num) {
    if (num < 10) {
        return "0" + num;
    }
    return "" + num;
}

export function getUserStatus(status) {

    var userStatus = STATUS.USER_INACTIVE;
    switch (status) {
        case 1:
            userStatus = STATUS.USER_INACTIVE;
            break;

        case 2:
            userStatus = STATUS.USER_ACTIVE;
            break;

        case 3:
            userStatus = STATUS.USER_DISABLED;
            break;

        default:
            break;
    }

    return userStatus;
}

export function getPaymentStatus(status) {

    var userStatus = PAYMENT_STATUS.UNPAID;
    switch (status) {
        case 1:
            userStatus = PAYMENT_STATUS.PAID;
            break;

        default:
            userStatus = PAYMENT_STATUS.UNPAID
            break;
    }

    return userStatus;
}

export function getPlanType(plan) {

    var userStatus = PLAN_TYPE.NO_ANY;
    switch (plan) {
        case "MONTHLY":
            userStatus = PLAN_TYPE.MONTH;
            break;
        case "ANNUAL":
            userStatus = PLAN_TYPE.YEAR;
            break;
        case "DAY":
            userStatus = PLAN_TYPE.DAY;
            break;
        case "DAILY":
            userStatus = PLAN_TYPE.DAY;
            break;

        default:
            userStatus = plan
            break;
    }

    return userStatus;
}
export function getExUserStatus(status) {

    var userStatus = "";
    switch (status) {
        case false:
            userStatus = "Disabled";
            break;

        case true:
            userStatus = "Active";
            break;
        default:
            break;
    }

    return userStatus;
}






export function differenceYears(date1, date2) {
    let diff = Math.abs(date2 - date1) / 1000;
    diff /= (60 * 60 * 24);
    return Math.abs(Math.floor(diff / 365.25)).toString();
}


export function getTimeInMS(fileName) {
    let extIdx = fileName.indexOf(".");
    let ext = fileName.substring(extIdx);
    var d = new Date();
    var s = Date.UTC(d.getFullYear(), d.getMonth(), d.getDate(), d.getHours(), d.getMinutes(), d.getSeconds());
    return fileName.substring(0, extIdx) + "_" + s.toString() + ext;
}


export function getSubscriptionStatus(status) {

    var userStatus = SUBSCRIPTION_STATUS.UNSUBSCRIBED;
    switch (status) {
        case 0:
            userStatus = SUBSCRIPTION_STATUS.UNSUBSCRIBED;
            break;
        case 1:
            userStatus = SUBSCRIPTION_STATUS.SUBSCRIBED;
            break;
        case 2:
            userStatus = SUBSCRIPTION_STATUS.CANCELLED;
            break;
        case 3:
            userStatus = SUBSCRIPTION_STATUS.EXPIRED;
            break;

        default:
            userStatus = SUBSCRIPTION_STATUS.UNSUBSCRIBED
            break;
    }

    return userStatus;
}
