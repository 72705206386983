import {
    CModal, CModalBody, CModalFooter, CModalHeader, CButton,
    CCard,
    CCardBody,
    CCardHeader,
    CCol,
    CRow,
    CInput,
    CAlert,
    CImg,
    CInputCheckbox,
    CInputFile,
    CLabel,
} from "@coreui/react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { validateMandatoryValue, validateTextLength } from "src/helpers/Validation";
import { addImageLib, getPresignedURL, uploadImage, getImageSkintoneList } from "src/store/actions/ImageLib";
import { removeUser } from "src/store/actions/User";
import imageCompression from 'browser-image-compression';
import Loader from "src/views/common/Loader/Loader";
import Cropper from 'react-easy-crop';
import { getCroppedImg } from "src/helpers/ImageCrop";
import LeavePage from "src/views/common/LeavePageModal";
import { useTranslation } from "react-i18next";
import { getTimeInMS } from "src/helpers/GenericHelper";
import imgPlaceholder from "src/assets/images/image-placeholder.svg";

import SkinTonePicker from "./SkinTonePicker";

const AddImageModal = ({ openModal, handleModal, categories, setApiRes }) => {

    const dispatch = useDispatch();

    const [childImage, setChildImage] = useState({});

    const [imageTitle, setImageTitle] = useState("");
    const [selectedCat, setSelectedCat] = useState([]);

    const [imageTitleError, setImageTitleError] = useState("");
    const [selectedCatError, setSelectedCatError] = useState("");
    const [ImageError, setImageError] = useState("");
    const [formFilled, setFormFilled] = useState(false);
    const [compressing, setCompressing] = useState(false);
    const [leaveModal, setLeaveModal] = useState(false);

    var compressedFileTemp = {};
    let croppedImageFile;

    const { t } = useTranslation();



    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1.5);
    const [imgWidth, setImgWidth] = useState(0);
    const [imgHeight, setImgheight] = useState(0);

    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
    const [sliderValue, setSliderValue] = useState(1.5);

    const [currentSkinToneId, setCurrentSkinToneId] = useState(7); // default skin tone id 7
    const skinToneList = useSelector(state => state.imageLibrary.imageSkinTones);


    const handleSliderValueChange = (e) => {
        setSliderValue(e.target.value);
        setZoom(e.target.value);
    }

    const onCropComplete = (croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels);
    }

    const checkChanged = () => {
        let changed = false;
        if (Object.keys(childImage).length !== 0 || imageTitle !== "" || selectedCat.length !== 0) {
            changed = true
        }
        return changed;
    }
    const handleLeaveModal = () => {
        setLeaveModal(prev => !prev)
    }

    const LeaveThisPage = () => {
        clearAll()
        handleModal()
    }

    useEffect(() => {
        dispatch(getImageSkintoneList(onSkinToneFetchSuccess, onSkinToneFetchFailure));
    },[]);


    useEffect(() => {
        setChildImage({})
        let tempCat = []
        if (categories.length !== 0) {
            categories.map(c => {
                tempCat.push(c.name)
            })
        }
        // setSelectedCat(tempCat)
        console.log("called ", selectedCat);

        return () => {

        }
    }, [openModal])

    const clearAll = () => {
        setImageTitle("")
        setImageTitleError("")

        setSelectedCat([])
        setSelectedCatError("")

        setChildImage(null)
        setImageError("")

        setFormFilled(false)
    }

    const handleModalClear = () => {
        // event.preventDefault()
        if (checkChanged()) {
            setLeaveModal(true)
        } else {
            LeaveThisPage()
        }

    }


    const handleImageUpload = (event) => {
        event.preventDefault();
        let imageObject = null;
        let fileObject = null;

        if (event.target.files.length > 0) {
            fileObject = event.target.files[0];

            let fileName = fileObject.name
            var idxDot = fileName.lastIndexOf(".") + 1;
            var extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
            if (extFile == "jpg" || extFile == "jpeg" || extFile == "png") {
                setCompressing(true)
                const fileURL = URL.createObjectURL(fileObject);
                imageObject = new Image();
                imageObject.src = fileURL;
                imageObject.onload = () => {
                    // alert(imageObject.width + " " + imageObject.height);
                    setCompressing(false)
                    setImgWidth(imageObject.width)
                    setImgheight(imageObject.hegi)
                }
                setChildImage({ file: fileObject, image: imageObject });
                setImageError("")
                event.target.value = null

            }
            else {
                setImageError(t("typeInvalid"))
                setChildImage({});

            }

        }
        else {
            setImageError(t("imageRequired"))
        }


    }

    const validateImage = () => {
        if (Object.keys(childImage).length === 0) {
            setImageError(t("imageRequired"))
            return false;
        }
        else {
            setImageError("")
            return true;
        }
    }
    const validateImageTitle = (imageTitle) => {
        if (!validateMandatoryValue(imageTitle)) {
            setImageTitleError(t("imageTitleRequired"))
            return false;
        }
        else if (!validateTextLength(imageTitle)) {
            setImageTitleError(t("imageTitleLength"))
            return false;
        }
        else {
            setImageTitleError("")
            return true;
        }
    }
    const validateCategories = (selectedCat) => {
        if (selectedCat.length === 0) {
            setSelectedCatError(t("catRequired"))
            return false;
        }
        else {
            setSelectedCatError("")
            return true;
        }
    }


    const isFormValid = () => {
        setFormFilled(true)
        return validateImage() & validateImageTitle(imageTitle) & validateCategories(selectedCat);
    }

    const onSubmit = async () => {
        if (isFormValid()) {
            // compressImage()
            setCompressing(true)
            getCroppedImg(childImage.image.src, croppedAreaPixels, childImage.file.type,0, afterCrop);
        }
    }
    const afterCrop = (blob) => {
        croppedImageFile = new File([blob], childImage.file.name, { type: blob.type });
        compressImage(croppedImageFile)
    }

    const compressImage = (croppedImageFile) => {
        // setCompressing(true)
        var options = {
            maxSizeMB: 1,
            maxWidthOrHeight: 200,
            useWebWorker: true
        }
        if (croppedImageFile) {
            imageCompression(croppedImageFile, options)
                .then(function (compressedFile) {

                    setChildImage(prev => ({ ...prev, file: compressedFile }));
                    setImageError("")
                    setCompressing(false)
                    compressedFileTemp = compressedFile;
                    compressedFileTemp.name = getTimeInMS(compressedFileTemp.name);

                    dispatch(getPresignedURL(compressedFileTemp.name, onGetUrl, onFailedUrl))


                })
                .catch(function (error) {
                    setImageError("Compression failed!")
                });
        }
    }

    const onGetUrl = (res) => {
        dispatch(uploadImage(compressedFileTemp, res.data.url,
            (res) => {
                console.log("Success Called", res);
                dispatch(addImageLib({
                    "imagePath": compressedFileTemp.name,
                    "imageTitle": imageTitle,
                    "categories": selectedCat,
                    "skinToneId": currentSkinToneId
                }, onAddImageSuccess, onAddImageFailure));
            }
            ,
            (err) => {
                console.log("Error Called", err);
            }
        ))
    }




    const onAddImageSuccess = (res) => {
        setChildImage({})
        setApiRes(t("imageSuccess"))
        LeaveThisPage()
    }

    const onAddImageFailure = (err) => {
        setApiRes("Error: Operation Failed! " + err)
        // alert(err)
    }
    const onFailedUrl = (err) => {
        // alert(err)
    }

    const onSkinToneFetchSuccess = (res) => {}

    const onSkinToneFetchFailure = (res) => {}

    const onSkinToneChange = (skinToneId) => {
        setCurrentSkinToneId(skinToneId);
    }

    return (
        <CModal
            show={openModal}
            onClose={handleModalClear}
            closeOnBackdrop={false}
            size="xl"
        >
            <CModalHeader > {t("uploadNImage")}
                <CButton onClick={handleModalClear}>X</CButton>

            </CModalHeader>
            <CModalBody>

                {compressing && <Loader />}



                <CRow>
                    <CCol xl={6}>
                        <CCard>

                            <CCardBody>

                                {/* <div className="profile-image-section"> */}
                                {/* <figure> */}
                                {
                                    childImage && childImage.image ?

                                        <>
                                            <div className="imgCropContainer">
                                                <Cropper
                                                    image={childImage.image.src}
                                                    crop={crop}
                                                    zoom={zoom}
                                                    aspect={1}
                                                    showGrid={false}
                                                    onCropChange={(value) => {
                                                        setCrop(value)
                                                    }}
                                                    onCropComplete={onCropComplete}
                                                />
                                            </div>
                                            <div className="imgCropSlider">
                                                <div className="slider">
                                                    <div className="c-slider">
                                                        <input type="range" min={1} max={3} step={0.1} value={sliderValue} onChange={handleSliderValueChange}></input>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                        :
                                        <CImg
                                            className="imgLibPlaceHolder"
                                            alt="Child Profile Picture"
                                            src={imgPlaceholder} />
                                }

                                {/* </figure> */}
                                <div className="upload-photo">
                                    <div className="btn upload-btn">
                                        {t("uploadImg")}
                                        <CInputFile id="childImage" name="childImage"
                                            accept="image/*"
                                            type="file" onChange={handleImageUpload} />
                                        {
                                            ImageError && <CLabel className="field-error">{ImageError}</CLabel>
                                        }


                                    </div>
                                </div>
                                {/* </div> */}

                            </CCardBody>



                        </CCard>
                    </CCol>

                    <CCol xl={6}>
                        <CCard>

                            <CCardBody>
                                <CLabel>{t("imageTitle")}</CLabel>
                                <CInput
                                    placeholder={t("imageTitlePholder")}
                                    size="md"
                                    value={imageTitle}
                                    onChange={(e) => {
                                        setImageTitle(e.target.value)
                                        if (formFilled) {
                                            validateImageTitle(e.target.value)
                                        }
                                    }
                                    }
                                />
                                {
                                    imageTitleError && <CLabel className="field-error">{imageTitleError}</CLabel>
                                }
                                <br></br>

                                <CLabel>{t("skinTone")} </CLabel>

                                <SkinTonePicker 
                                    skinToneData={skinToneList} 
                                    skinToneId={currentSkinToneId}
                                    onSkinToneChange={onSkinToneChange}
                                />

                                <CLabel>{t("imageCat")} </CLabel>

                                <div className="check-filters my-0">
                                    {categories && categories.length !== 0 && categories.map(cat => {
                                        var exist = false;

                                        if (selectedCat && selectedCat.includes(cat.name)) {
                                            exist = true;
                                        }
                                        
                                        return (

                                            <div className="filter-items">

                                                <CInputCheckbox
                                                    id="checkbox1"
                                                    className="btn btn-pill btn-dark mr-1"
                                                    name={cat.name}
                                                    value={cat.name}
                                                    checked={exist}
                                                    onChange={(e) => {
                                                        var catSelected = selectedCat;
                                                        console.log("shruh main,", catSelected)
                                                        if (e.target.checked) {
                                                            catSelected.push(e.target.value)
                                                            setSelectedCat([...catSelected])
                                                            console.log("catSel", catSelected)
                                                        }
                                                        else {
                                                            catSelected = catSelected.filter(item => item !== e.target.value)
                                                            setSelectedCat([...catSelected])
                                                            console.log("Else catSel", catSelected)
                                                        }

                                                        if (formFilled) {
                                                            validateCategories(catSelected)
                                                        }

                                                    }}
                                                />
                                                <span>{cat.value}</span>


                                            </div>
                                        )
                                    })}
                                </div>
                                {
                                    selectedCatError && <CLabel className="field-error">{selectedCatError}</CLabel>
                                }


                                {// list selection control for categories
                                /* 
                                <div className="round-check-filters">
                                    {categories.length !== 0 && categories.map(cat => {
                                        return (
                                            <div className="check-items">
                                                <CInputCheckbox
                                                    id="checkbox1"
                                                    class="btn btn-pill btn-dark mr-1"
                                                    name={cat.value}
                                                    // defaultChecked={true}
                                                    selected={false}
                                                    onChange={(e) => {
                                                        var catSelected = selectedCat;
                                                        console.log("shruh main,", catSelected)
                                                        if (e.target.checked) {
                                                            catSelected.push(e.target.value)
                                                            setSelectedCat(catSelected)
                                                            console.log("catSel", catSelected)
                                                        }
                                                        else {
                                                            catSelected = catSelected.filter(item => item !== e.target.value)
                                                            setSelectedCat(catSelected)
                                                            console.log("Else catSel", catSelected)

                                                        }

                                                        if (formFilled) {
                                                            validateCategories(catSelected)
                                                        }

                                                    }}
                                                    value={cat.name}
                                                />
                                                <span>
                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024" role="img" className="c-check-icon">
                                                        <path fill="var(--ci-primary-color, currentColor)" d="M910.585 151.653l-561.834 577.221-234.61-240.968c-25.801-26.538-67.544-26.538-93.441 0-25.801 26.538-25.801 69.388 0 95.926l281.056 288.611c25.801 26.538 67.544 26.538 93.344 0l608.274-624.772c25.801-26.631 25.801-70.125-0.646-96.757-24.513-25.801-66.348-25.801-92.148 0.737z" class="ci-primary"></path>
                                                    </svg>
                                                </span>
                                                {cat.value}
                                            </div>
                                        )
                                    })}

                                </div>
                                {
                                    selectedCatError && <CLabel className="field-error">{selectedCatError}</CLabel>
                                } */}
                            </CCardBody>

                            <LeavePage openModal={leaveModal} handleModal={handleLeaveModal} leaveThis={LeaveThisPage} />



                        </CCard>
                    </CCol>
                </CRow>


            </CModalBody>
            <CModalFooter className="footer-has-primary-secondary-btn">
                <CButton color="primary" onClick={() => onSubmit()} > {t("add")} </CButton>{' '}
                <CButton color="primary" onClick={() => handleModalClear()} > {t("cancel")} </CButton>{' '}

            </CModalFooter>
        </CModal >

    );
};

export default AddImageModal;
