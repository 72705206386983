import React, { useState, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import {
    CCard,
    CCardBody,
    CCardHeader,
    CCol,
    CRow,
    CButton,
    CInput,
    CImg,
    CInputCheckbox,
    CInputFile,
    CLabel,
    CAlert,
} from '@coreui/react'
import RouteConstants from 'src/constants/RouteConstants'
import { useTranslation } from "react-i18next";
import AddFromImageLibModal from './components/AddFromImageLibModal'
import EditTaskItem from './components/EditTaskItem'
import { useDispatch, useSelector } from 'react-redux'
import { getCategories } from 'src/store/actions/Categories'
import Loader from '../common/Loader/Loader'
import { editActivity, getActivity } from 'src/store/actions/Activity'
import { validateActNameLength, validateMandatoryValue, validateObject } from 'src/helpers/Validation'
import { PRIV_CONSTS } from 'src/constants/StaticData'
import { getEditPermValue } from 'src/helpers/PermissionCalculator'
import LeavePage from '../common/LeavePageModal'
import { ActivityColor } from 'src/constants/GenericConstants'
import appleWatch from "src/assets/images/applewatchgray.png"
import { editSelfRegulation, getSelfRegulation } from 'src/store/actions/SelfRegulation';



const Activities = (props) => {
    const history = useHistory()
    const location = useLocation()
    const dispatch = useDispatch()
    const [categories, setCategories] = useState([])

    const [actId, setActId] = useState(-1)
    const [activityName, setActivityName] = useState("")
    const [resGot, setResGot] = useState({})
    const [subTasks, setSubtasks] = useState([{ "id": 1, "imageId": 0, "image": "https://i.stack.imgur.com/l60Hf.png", "name": "", enable: true }])

    const imageLibraryState = useSelector((state) => state.imageLibrary);
    const categoriesState = useSelector((state) => state.categories);
    const activityState = useSelector((state) => state.regulation);
    const user = useSelector((state) => state.authentication.user);


    const [openModalAdd, setOpenModalAdd] = useState(false)
    const [onHold, setOnHold] = useState(false)
    const [formFilled, setFormFilled] = useState(false);

    const { t } = useTranslation();

    const [actImage, setActImage] = useState({});

    const [activityColors, setActivityColors] = useState([
        { colorCode: '#f9be02', selected: false, code: ActivityColor.YELLOW },
        { colorCode: '#02c8a7', selected: false, code: ActivityColor.SEA_GREEN },
        { colorCode: '#103170', selected: false, code: ActivityColor.NAVY_BLUE },
        { colorCode: '#6d7278', selected: false, code: ActivityColor.GREY },
        { colorCode: '#6dd401', selected: false, code: ActivityColor.LIGHT_GREEN },
        { colorCode: '#32c5ff', selected: false, code: ActivityColor.SKY_BLUE },
        { colorCode: '#ef661b', selected: false, code: ActivityColor.ORANGE },
    ]);
    const [activityColorError, setActivityColorError] = useState("");


    const [activityNameError, setactivityNameError] = useState("");
    const [subTasksError, setSubTasksError] = useState("");
    const [actImageError, setActImageError] = useState("");
    const [apiError, setApiError] = useState("");

    const [viewEditAccess, setViewEditAccess] = useState(false)
    const [loaded, setLoaded] = useState(false);

    const [leaveModal, setLeaveModal] = useState(false);

    const checkChanged = () => {
        let changed = false;
        if (resGot.name !== activityName || (resGot.image && resGot.image.imageTitle !== actImage.imageTitle) || (resGot.subTasks && resGot.subTasks.length !== subTasks.length)) {
            changed = true
        }
        return changed;
    }
    const handleLeaveModal = () => {
        setLeaveModal(prev => !prev)
    }
    const LeaveThisPage = () => {
        history.goBack()
    }

    useEffect(() => {

        if (user && user.accounts) {
            let editPerm = getEditPermValue(PRIV_CONSTS.EMOTION_PRIV)
            setViewEditAccess(editPerm)

        }

        const regulationId = decodeURIComponent(props.match.params.regulationId);
        if (regulationId) {
            setActId(regulationId)
            dispatch(getSelfRegulation(regulationId, onLoadSuccess, onLoadFailure));
        }

        dispatch(getCategories(onSuccess, onFailure))
    }, [props.match.params.regulationId])

    const onSuccess = (res) => {
        setCategories(res)
    }

    const onFailure = (err) => {

    }

    const onLoadSuccess = (res) => {

        if (res) {

            setActivityName(res.name)
            let tempSubTask = []
            if (res.subTasks && res.subTasks.length !== 0) {
                let orderTemp = 1;
                res.subTasks.sort((a, b) => (a.order > b.order) ? 1 : -1).map(s => {
                    tempSubTask.push({ "id": s.id, "order": s.order ? s.order : orderTemp, "imageId": s.imageId, "image": s.imageURL, "name": s.name, enable: false })
                    orderTemp++
                })
            }
            setSubtasks(tempSubTask)
            if (tempSubTask.length >= 4) {
                setOnHold(true)
            }
            if (res.image) {
                setActImage({ id: res.image.id, imageURL: res.image.imageURL, imageTitle: res.image.imageTitle, categories: res.image.categories })
            }
            setResGot(res)
        }
    }

    const onLoadFailure = (err) => {

    }

    

    const handleModalAdd = () => {
        setOpenModalAdd(prev => !prev)
    }

    const setActivityImage = (id) => {
        let imgObj = imageLibraryState.imagesList.filter((i) => i.id === id)[0];
        setActImage(imgObj)
        setOpenModalAdd(prev => !prev)
        if (formFilled) {
            validateActImage(imgObj)
        }
    }

    const validateActImage = (imgObj) => {
        if (!validateObject(imgObj)) {
            setActImageError(t("imageRequired"))
            return false;
        }
        else {
            setActImageError("")
            return true;
        }
    }

    const validateActName = (actName) => {
        if (!validateMandatoryValue(actName)) {
            setactivityNameError(t("toolNameRequired"))
            return false;
        }
        else if (!validateActNameLength(actName)) {
            setactivityNameError(t("toolNameLength"))
        }
        else {
            setactivityNameError("")
            return true;
        }
    }
    const validateSubTask = (selectedSubTasks) => {
        if (selectedSubTasks.filter(i => i.enable === false).length === 0) {
            setSubTasksError(t("oneSubtaskRequired"))
            return false;
        }
        else {
            setSubTasksError("")
            return true;
        }
    }

    const isFormValid = () => {
        setFormFilled(true)
        return validateActImage(actImage) & validateActName(activityName);
    }

    const onSubmit = () => {

        if (isFormValid()) {
            var subTasksGot = []
            subTasks.map((subtask,index) => {
                if (!subtask.enable) {
                    if (subtask.id) {
                        subTasksGot.push({ id: subtask.id, name: subtask.name, imageId: subtask.imageId, order: index + 1  });
                    } else {
                        subTasksGot.push({name: subtask.name, imageId: subtask.imageId, order: index + 1 });
                    }
                }
            })
            const data = {
                id: Number(actId),
                name: activityName,
                // color: activityColors.filter((color) => color.selected)[0].code,
                imageId: actImage.id,
                accountId: user.accounts[0].id,
                subTasks: subTasksGot
            }
            setApiError("")
            dispatch(editSelfRegulation(data, onEdited, onFailed))
        }


    }

    const onEdited = (message) => {
        history.replace({
            pathname: RouteConstants.SELF_REGULATION,
            state: { msg: message }
        })
    }
    const onFailed = (err) => {
        setApiError(err)
    }

    return (
        <CRow>

            {activityState.loading && <Loader />}



            <CCol xl={12}>
                <CCard>
                    <CCardHeader>
                        <CRow className="align-items-center">
                            <CCol sm="5" xs="12">
                                <h5 className="page-title py-2">{t("editRegulationTool")}</h5>
                            </CCol>
                            {/* <small className="text-muted"> example</small> */}

                        </CRow>


                    </CCardHeader>
                    <CCardBody>
                        <CRow>
                            <CCol xl={4}>
                                <CCard>

                                    <CCardBody>

                                        <div className="profile-image-section">

                                        <figure className="appleWatch">
                                                <img className="appleWatch-container" src={appleWatch} />
                                                {!loaded ? <p className="loader"></p> : null}

                                                {Object.keys(actImage).length !== 0 && actImage.imageURL &&
                                                    <CImg
                                                        className="d-placeholder"
                                                        src={actImage.imageURL ? actImage.imageURL : "https://i.stack.imgur.com/l60Hf.png"}
                                                        onLoad={() => {
                                                            setLoaded(true)
                                                        }
                                                        }
                                                        alt="Child Profile Picture"
                                                    />
                                                }


                                            </figure>
                                            {
                                                actImageError && <CLabel className="field-error">{actImageError}</CLabel>
                                            }
                                            {viewEditAccess ?
                                                <div className="upload-photo">
                                                    <div className="btn upload-btn"
                                                        onClick={() => handleModalAdd()}>
                                                        {t("editImage")}
                                                </div>
                                                </div> : null}
                                            <br></br>
                                            <div className="secDisabled">
                                                <CLabel><strong>{t("imageName")}</strong></CLabel>
                                                <CInput
                                                    placeholder="Image Name"
                                                    size="md"
                                                    className="mb-20"
                                                    value={Object.keys(actImage).length !== 0 ? actImage.imageTitle : ""}
                                                    disabled={true}
                                                />

                                                <CLabel><strong>{t("imageCat")}</strong></CLabel>

                                                <div className="round-check-filters">
                                                    {categories.length !== 0 && categories.map(cat => {
                                                        var exist = false;

                                                        if (Object.keys(actImage).length !== 0 && actImage.categories.includes(cat.name)) {
                                                            exist = true;
                                                        }

                                                        return (
                                                            <div className="check-items">
                                                                <CInputCheckbox
                                                                    id="checkbox1"
                                                                    checked={exist}
                                                                    class="btn btn-pill btn-dark mr-1"
                                                                    name={cat.name}
                                                                    value={cat.value}
                                                                />
                                                                <span>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024" role="img" className="c-check-icon">
                                                                        <path fill="var(--ci-primary-color, currentColor)" d="M910.585 151.653l-561.834 577.221-234.61-240.968c-25.801-26.538-67.544-26.538-93.441 0-25.801 26.538-25.801 69.388 0 95.926l281.056 288.611c25.801 26.538 67.544 26.538 93.344 0l608.274-624.772c25.801-26.631 25.801-70.125-0.646-96.757-24.513-25.801-66.348-25.801-92.148 0.737z" class="ci-primary"></path>
                                                                    </svg>
                                                                </span>
                                                                {cat.value}
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                            </div>
                                        </div>

                                    </CCardBody>



                                </CCard>
                            </CCol>

                            <CCol xl={8}>
                                <CCard>

                                    <CCardBody>
                                        <CLabel>{t("toolName")}</CLabel>
                                        <CInput
                                            placeholder="Tool Name"
                                            size="md"
                                            disabled={!viewEditAccess}
                                            value={activityName}
                                            onChange={(e) => {
                                                setActivityName(e.target.value)
                                                if (formFilled) {
                                                    validateActName(e.target.value)
                                                }
                                            }}

                                        />
                                        {
                                            activityNameError && <CLabel className="field-error">{activityNameError}</CLabel>
                                        }
                                        <br></br>

                                        <CLabel>{t("instructions")}</CLabel>

                                        <div className="row column-expand col">
                                            {subTasks.length !== 0 ? subTasks.sort((a, b) => a.order - b.order).map((sub,index) => {
                                                return (
                                                    <EditTaskItem
                                                        dataGot={{...sub,index}}
                                                        formFilled={formFilled}
                                                        validateIt={validateSubTask}
                                                        setSubtasks={setSubtasks}
                                                        subTasks={subTasks}
                                                        setOnHold={setOnHold}
                                                        editAccess={viewEditAccess}
                                                    />
                                                )
                                            }):
                                            <CLabel>{t("noInstructions")}</CLabel>
                                            
                                            }
                                        </div>

                                        {
                                            subTasksError && <CLabel className="field-error">{subTasksError}</CLabel>
                                        }


                                        {viewEditAccess ?
                                            <CButton
                                                variant="outline" color="primary" className="my-2"
                                                disabled={onHold}
                                                onClick={() => {
                                                    let temp= [...subTasks];
                                                    
                                                    setSubtasks(prev => prev.concat({ "order": subTasks[subTasks.length - 1] ? subTasks[subTasks.length - 1].order + 1 : 1, "imageId": 0, "image": "https://i.stack.imgur.com/l60Hf.png", "name": "Empty", enable: true }))
                                                    setOnHold(true)
                                                }} >{t("addNewInstruction")}</CButton> : null
                                        }


                                        {apiError !== "" ?
                                            <CAlert show={10} color="danger">
                                                {apiError}
                                            </CAlert>
                                            : null}

                                        <br></br>
                                        <br></br>

                                        

                                        <CRow >
                                            <CCol className="footer-has-primary-secondary-btn pt-3">

                                                <CButton
                                                    variant="outline" color="dark" style={{ float: "right" }}
                                                    onClick={() => {
                                                        if (checkChanged()) {
                                                            setLeaveModal(true)
                                                        } else {
                                                            LeaveThisPage()
                                                        }

                                                    }}
                                                >{t("cancel")}</CButton>
                                                <CButton
                                                    variant="outline" color="dark" style={{ float: "right", marginRight: 10 }}
                                                    disabled={!viewEditAccess}
                                                    onClick={() => {
                                                        onSubmit()
                                                    }}
                                                >{t("editTool")}</CButton>
                                            </CCol>

                                        </CRow>




                                    </CCardBody>



                                </CCard>
                            </CCol>
                        </CRow>

                    </CCardBody>


                   {openModalAdd?  <AddFromImageLibModal openModal={openModalAdd} handleModal={handleModalAdd} categories={categories} setActivityImage={setActivityImage} /> :null}
                    <LeavePage openModal={leaveModal} handleModal={handleLeaveModal} leaveThis={LeaveThisPage} />



                </CCard>
            </CCol>
        </CRow>
    )
}

export default Activities
