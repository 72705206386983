import ImageLibService from "src/services/api/ImageLibService";
import UserService from "src/services/api/UserService";
import { request, success, failure } from ".";
import { ADD_IMAGE_LIB, GET_IMAGE_LIB_LIST, IMAGE_UPLOAD_ACTIONS, GET_IMAGE_SKINTONES_ACTIONS } from "../../constants/ActionConstants";


export const addImageLib = (data, onSuccess, onFailure) => {
    return async (dispatch) => {
        dispatch(request(ADD_IMAGE_LIB.ADD_IMAGE_LIB, {}));
        try {
            const response = await ImageLibService.addImageLib(data)

            // dispatch(getImageLibPageList());
            dispatch(success(ADD_IMAGE_LIB.ADD_IMAGE_LIB_SUCCESS, { data: response.data }));

            onSuccess(response.message);
        } catch (error) {
            dispatch(failure(ADD_IMAGE_LIB.ADD_IMAGE_LIB_FAILURE, {}));
            onFailure(error.data.message);
        }
    };
};

export const editImageLib = (data, onSuccess, onFailure) => {
    return async (dispatch) => {
        dispatch(request(ADD_IMAGE_LIB.ADD_IMAGE_LIB, {}));
        try {
            const response = await ImageLibService.editImageLib(data)

            // dispatch(getImageLibPageList());
            dispatch(success(ADD_IMAGE_LIB.ADD_IMAGE_LIB_SUCCESS, { data: response.data }));

            onSuccess(response.message);
        } catch (error) {
            dispatch(failure(ADD_IMAGE_LIB.ADD_IMAGE_LIB_FAILURE, {}));
            onFailure(error.data.message);
        }
    };
};


export const getImageLibList = (page, q, catList, onSuccess, onFailure) => {
    return async (dispatch) => {
        if (page === 1) {
            dispatch(request(GET_IMAGE_LIB_LIST.GET_IMAGE_LIB_LIST_SEARCH_CAT, {}));

        } else {
            dispatch(request(GET_IMAGE_LIB_LIST.GET_IMAGE_LIB_LIST, { page: page }));
        }
        try {
            const response = await ImageLibService.getImageList(page, q, catList)
            dispatch(success(GET_IMAGE_LIB_LIST.GET_IMAGE_LIB_LIST_SUCCESS, { data: response.data }));
            onSuccess(response.data);
        } catch (error) {
            dispatch(failure(GET_IMAGE_LIB_LIST.GET_IMAGE_LIB_LIST_FAILURE, {}));
            error.data && error.data.message && onFailure(error.data.message);
        }
    };
};

export const getImageLibPageList = (page, onSuccess, onFailure) => {
    return async (dispatch) => {
        dispatch(request(GET_IMAGE_LIB_LIST.GET_IMAGE_LIB_LIST, {}));
        try {
            const response = await ImageLibService.getImageLibPageList(page)
            dispatch(success(GET_IMAGE_LIB_LIST.GET_IMAGE_LIB_LIST_SUCCESS, { data: response.data }));
            onSuccess(response.data);
        } catch (error) {
            dispatch(failure(GET_IMAGE_LIB_LIST.GET_IMAGE_LIB_LIST_FAILURE, {}));
            error.data && error.data.message && onFailure(error.data.message);
        }
    };
};

export const getImageLibRefresh = () => {
    return async (dispatch) => {
        dispatch(request(GET_IMAGE_LIB_LIST.GET_IMAGE_LIB_LIST, {}));
        try {
            const response = await ImageLibService.getImageLibPageList(1)
            dispatch(success(GET_IMAGE_LIB_LIST.GET_IMAGE_LIB_LIST_SUCCESS, { data: response.data }));
        } catch (error) {
            dispatch(failure(GET_IMAGE_LIB_LIST.GET_IMAGE_LIB_LIST_FAILURE, {}));
        }
    };
};

export const getSearchedImageLibList = (q, onSuccess, onFailure) => {
    return async (dispatch) => {
        dispatch(request(GET_IMAGE_LIB_LIST.GET_IMAGE_LIB_LIST, {}));
        try {
            const response = await ImageLibService.getSearchedImageLibList(q)
            dispatch(success(GET_IMAGE_LIB_LIST.GET_IMAGE_LIB_LIST_SUCCESS, { data: response.data }));
            onSuccess(response.data);
        } catch (error) {
            dispatch(failure(GET_IMAGE_LIB_LIST.GET_IMAGE_LIB_LIST_FAILURE, {}));
            error.data && error.data.message && onFailure(error.data.message);
        }
    };
};

export const getCategoryFilterImageLibList = (catList, onSuccess, onFailure) => {
    return async (dispatch) => {
        dispatch(request(GET_IMAGE_LIB_LIST.GET_IMAGE_LIB_LIST, {}));
        try {
            const response = await ImageLibService.getCatFilterImageLibList(catList)
            dispatch(success(GET_IMAGE_LIB_LIST.GET_IMAGE_LIB_LIST_SUCCESS, { data: response.data }));
            onSuccess(response.data);
        } catch (error) {
            dispatch(failure(GET_IMAGE_LIB_LIST.GET_IMAGE_LIB_LIST_FAILURE, {}));
            error.data && error.data.message && onFailure(error.data.message);
        }
    };
};

export const getSearchedCategoryFilterImageLibList = (q, catList, onSuccess, onFailure) => {
    return async (dispatch) => {
        dispatch(request(GET_IMAGE_LIB_LIST.GET_IMAGE_LIB_LIST, {}));
        try {
            const response = await ImageLibService.getSearchedCatFilterImageLibList(q, catList)
            dispatch(success(GET_IMAGE_LIB_LIST.GET_IMAGE_LIB_LIST_SUCCESS, { data: response.data }));
            onSuccess(response.data);
        } catch (error) {
            dispatch(failure(GET_IMAGE_LIB_LIST.GET_IMAGE_LIB_LIST_FAILURE, {}));
            error.data && error.data.message && onFailure(error.data.message);
        }
    };
};


export const getPresignedURL = (fileName, onSuccess, onFailure) => {
    return async (dispatch) => {
        dispatch(request(IMAGE_UPLOAD_ACTIONS.IMAGE_UPLOAD, {}));
        try {
            const response = await ImageLibService.getPresignedURL(fileName)
            dispatch(request(IMAGE_UPLOAD_ACTIONS.IMAGE_UPLOAD_SUCCESS, {}));
            onSuccess(response)
        } catch (error) {
            dispatch(request(IMAGE_UPLOAD_ACTIONS.IMAGE_UPLOAD_FAILURE, {}));
            onFailure(error)

        }
    };
}

export const getImageURL = (fileName, onSuccess, onFailure) => {
    return async (dispatch) => {
        // dispatch(request(IMAGE_UPLOAD_ACTIONS.IMAGE_UPLOAD, {}));
        try {
            const response = await ImageLibService.getImageURL(fileName);
            // dispatch(request(IMAGE_UPLOAD_ACTIONS.IMAGE_UPLOAD_SUCCESS, {}));
            onSuccess(response)
        } catch (error) {
            // dispatch(request(IMAGE_UPLOAD_ACTIONS.IMAGE_UPLOAD_FAILURE, {}));
            onFailure(error)

        }
    };
}

export const uploadImage = (data, urlAws, onSuccess, onFailure) => {
    return async (dispatch) => {
        dispatch(request(IMAGE_UPLOAD_ACTIONS.IMAGE_UPLOAD, {}));

        try {
            const response = await ImageLibService.uploadImagePut(data, urlAws)
            dispatch(request(IMAGE_UPLOAD_ACTIONS.IMAGE_UPLOAD_SUCCESS, {}));
            onSuccess(response)
        } catch (error) {
            dispatch(request(IMAGE_UPLOAD_ACTIONS.IMAGE_UPLOAD_FAILURE, {}));
            onFailure(error)

        }
    };
}

export const getImageSkintoneList = (onSuccess, onFailure) => {
    return async (dispatch) => {
        dispatch(request(GET_IMAGE_SKINTONES_ACTIONS.GET_IMAGE_SKINTONES, {}));
        try {
            const response = await ImageLibService.getImageSkintones();
            dispatch(success(GET_IMAGE_SKINTONES_ACTIONS.GET_IMAGE_SKINTONES_SUCCESS, { data: response.data }));
            onSuccess(response.data);
        } catch (error) {
            dispatch(failure(GET_IMAGE_SKINTONES_ACTIONS.GET_IMAGE_SKINTONES_FAILURE, {}));
            onFailure(error);
        }
    };
}
