const RouteConstants = {
  BASE: "/",
  LOGIN: "/login",
  DASHBOARD: "/home",
  CUSTOMER_USER: '/home/customer-users',
  USER_LIST: '/home/users',
  IMAGE_LIB: '/home/image-library',

  ACTIVITY: '/home/activities',
  MY_ACCOUNT: '/home/my-account',
  ADD_ACTIVITY: '/home/add-activity',
  EDIT_ACTIVITY: '/home/edit-activity/:activityId',

  SELF_REGULATION: '/home/self-regulation',
  ADD_SELF_REGULATION: '/home/add-regulation',
  EDIT_SELF_REGULATION: '/home/edit-regulation/:regulationId',


  USER_ADD: '/home/users/add-user',
  CUSTOMER_USER_DETAIL: '/home/edit/customer-user',
  USER_DETAIL: '/home/edit/user',

  PAYMENTS:'/home/payments',
  VIEW_PAYMENTS: '/home/payments/:customerId',



};

export default RouteConstants;
