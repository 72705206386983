import { apiClient } from "./ApiClient";
import UrlConstants from "../../constants/UrlConstants";
import { handleResponse, handleError } from "./GenericResponseHandler";

class ImageCategories {


    getImageCategories() {
        let url = UrlConstants.GET_IMAGE_CATEGORIES
        return apiClient()
            .get(url)
            .then(handleResponse)
            .catch(handleError);
    }
    

}

export default new ImageCategories();