import { CLabel } from "@coreui/react";
import React from "react";

const PlaceHolder = (props) => {

  return (
    <div className="table-no-data">
      <CLabel>{props.title}</CLabel>
    </div>

  );
};

export default PlaceHolder;
