import ActivityService from "src/services/api/ActivityService";
import { request, success, failure } from ".";
import {ACTIVITY_ACTIONS, COLOR_LIST_ACTIONS } from "../../constants/ActionConstants";


export const addActivity = (data, onSuccess, onFailure) => {
    return async (dispatch) => {
        dispatch(request(ACTIVITY_ACTIONS.ADD_ACTIVITY, {}));
        try {
            const response = await ActivityService.addActivity(data)

            // dispatch(getImageLibPageList());
            onSuccess(response.message);
        } catch (error) {
            dispatch(failure(ACTIVITY_ACTIONS.ADD_ACTIVITY_FAILURE, {}));
            onFailure(error.data.message);
        }
    };
};

export const editActivity = (data, onSuccess, onFailure) => {
    return async (dispatch) => {
        dispatch(request(ACTIVITY_ACTIONS.ADD_ACTIVITY, {}));
        try {
            const response = await ActivityService.editActivity(data)

            // dispatch(getImageLibPageList());
            onSuccess(response.message);
        } catch (error) {
            dispatch(failure(ACTIVITY_ACTIONS.ADD_ACTIVITY_FAILURE, {}));
            onFailure(error.data.message);
        }
    };
};

export const getActivities = (page, take, q, field, order, onSuccess, onFailure) => {
    return async (dispatch) => {
        dispatch(request(ACTIVITY_ACTIONS.GET_ACTIVITIES, {}));
        try {
            const response = await ActivityService.getActivities(page, take, q, field, order);
            dispatch(success(ACTIVITY_ACTIONS.GET_ACTIVITIES_SUCCESS, { data: response.data }));
            onSuccess(response.data);
        } catch (error) {
            dispatch(failure(ACTIVITY_ACTIONS.GET_ACTIVITIES_FAILURE, {}));
            error.data && error.data.message && onFailure(error.data.message);
        }
    };
};

export const getActivity= (id, onSuccess, onFailure) => {
    return async (dispatch) => {
        dispatch(request(ACTIVITY_ACTIONS.VIEW_ACTIVITY, {}));
        try {
            const response = await ActivityService.getActivity(id);
            dispatch(success(ACTIVITY_ACTIONS.VIEW_ACTIVITY_SUCCESS, { data: response.data }));
            onSuccess(response.data);
        } catch (error) {
            dispatch(failure(ACTIVITY_ACTIONS.VIEW_ACTIVITY_FAILURE, {}));
            error.data && error.data.message && onFailure(error.data.message);
        }
    };
};

export const getColorList = () => {
  return async (dispatch) => {
      dispatch(request(COLOR_LIST_ACTIONS.GET_COLOR_LIST, {}));
      try {
        const colorList = await ActivityService.getColorList();
        dispatch(success(COLOR_LIST_ACTIONS.GET_COLOR_LIST_SUCCESS, { data: colorList.data }));
      } catch (error) {
        dispatch(failure(COLOR_LIST_ACTIONS.GET_COLOR_LIST_FAILURE, {}));
      }
    };
}

