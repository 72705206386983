import { CImg } from '@coreui/react'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';

const ImageProfile = ({ data, setSelectedImage }) => {

    const { id, imageTitle, categories, imageURL } = data;


    const dispatch = useDispatch();
    const [imgSrc, setImgSrc] = useState("https://i.stack.imgur.com/l60Hf.png");
    const [loaded, setLoaded] = useState(false);


    useEffect(() => {
        if (imageURL !== "") {
            setImgSrc(imageURL)
        }
    }, [data])


    return (

        <figure>
            {!loaded ? <p className="loader"></p> : null}

            <CImg
                src={imgSrc}
                alt={imageTitle}
                onLoad={
                    () => {
                        setLoaded(true)
                    }
                }
            />
            <span onClick={() => {
                setSelectedImage(id)
            }} ><span className="conText">{imageTitle}</span></span>
        </figure>
    )
}

export default ImageProfile
