import SelfRegulatioService from "src/services/api/SelfRegulatioService";
import { request, success, failure } from ".";
import {SELF_REGULATION_ACTIONS } from "../../constants/ActionConstants";


export const addSelfRegulation = (data, onSuccess, onFailure) => {
    return async (dispatch) => {
        dispatch(request(SELF_REGULATION_ACTIONS.ADD_SELF_REGULATION, {}));
        try {
            const response = await SelfRegulatioService.addRegulation(data)

            // dispatch(getImageLibPageList());
            onSuccess(response.message);
        } catch (error) {
            dispatch(failure(SELF_REGULATION_ACTIONS.ADD_SELF_REGULATION_FAILURE, {}));
            onFailure(error.data.message);
        }
    };
};

export const editSelfRegulation = (data, onSuccess, onFailure) => {
    return async (dispatch) => {
        dispatch(request(SELF_REGULATION_ACTIONS.ADD_SELF_REGULATION, {}));
        try {
            const response = await SelfRegulatioService.editRegulation(data)

            // dispatch(getImageLibPageList());
            onSuccess(response.message);
        } catch (error) {
            dispatch(failure(SELF_REGULATION_ACTIONS.ADD_SELF_REGULATION_FAILURE, {}));
            onFailure(error.data.message);
        }
    };
};

export const getSelfRegulationList = (page, take, q, field, order, onSuccess, onFailure) => {
    return async (dispatch) => {
        dispatch(request(SELF_REGULATION_ACTIONS.GET_SELF_REGULATION, {}));
        try {
            const response = await SelfRegulatioService.getRegulationList(page, take, q, field, order);
            dispatch(success(SELF_REGULATION_ACTIONS.GET_SELF_REGULATION_SUCCESS, { data: response.data }));
            onSuccess(response);
        } catch (error) {
            dispatch(failure(SELF_REGULATION_ACTIONS.GET_SELF_REGULATION_FAILURE, {}));
            error.data && error.data.message && onFailure(error.data.message);
        }
    };
};

export const getSelfRegulation= (id, onSuccess, onFailure) => {
    return async (dispatch) => {
        dispatch(request(SELF_REGULATION_ACTIONS.VIEW_SELF_REGULATION, {}));
        try {
            const response = await SelfRegulatioService.getRegulation(id);
            dispatch(success(SELF_REGULATION_ACTIONS.VIEW_SELF_REGULATION_SUCCESS, { data: response.data }));
            onSuccess(response.data);
        } catch (error) {
            dispatch(failure(SELF_REGULATION_ACTIONS.VIEW_SELF_REGULATION_FAILURE, {}));
            error.data && error.data.message && onFailure(error.data.message);
        }
    };
};

