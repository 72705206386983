import React, { useEffect, useState } from 'react'
import {
    CAlert,
    CBadge,
    CButton,
    CDataTable,
    CImg,
    CInputCheckbox,
    CLabel,
    CPagination,
    CSwitch,

} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { useHistory, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getUsers, getSearchedUsers, updateUserStatus, getSortedUsers, getSearchedSortedUsers } from 'src/store/actions/User'
import { APP_CONSTANTS } from 'src/constants/GenericConstants'
import { getUserStatus } from 'src/helpers/GenericHelper'
import Loader from 'src/views/common/Loader/Loader'
import RouteConstants from 'src/constants/RouteConstants'
import disable from '../../../assets/images/user-disable.svg';
import DateHelper from 'src/helpers/DateHelper'
import Confirmation from 'src/views/common/Confirmation'
import PlaceHolder from 'src/views/common/PlaceHolder'
import ShowAlert from 'src/views/common/ShowAlert'
import { freeSet } from '@coreui/icons'
import ImageItem from './ImageItem'
import ImageMiniItem from './ImageMiniItem'
import RemoveIcon from '../../../assets/images/remove.svg';

import { useTranslation } from "react-i18next";
import { getSelfRegulationList } from 'src/store/actions/SelfRegulation'



const getBadge = status => {
    switch (status) {
        case 'Active': return 'success'
        case 'InActive': return 'secondary'
        case 'Pending': return 'warning'
        case 'Banned': return 'danger'
        default: return 'primary'
    }
}

const DataTableActivities = ({ searchTextGot, editAccess }) => {

    const dispatch = useDispatch();
    const history = useHistory()
    const location = useLocation()
    const userState = useSelector((state) => state.user);
    const [activityData, setActivityData] = useState([]);
    const [selectedUser, setSelectedUser] = useState();
    const [page, setPage] = useState(1)
    const [closeModal, setCloseModal] = useState(false);
    const [confirmationDialogText, setConfirmationDialogText] = useState("Are you sure you want to change user status?");
    const [fieldG, setFieldG] = useState("")
    const [orderG, setOrderG] = useState("")

    const { t } = useTranslation();


    const [sortingFields, setSortingFields] = useState({
        name: "no",
        subTasksCount: "no",
        createdAt: "no",
        createdBy: "no",
    });

    const regulationState = useSelector((state) => state.regulation);

    const [updateApiMsg, setUpdateApiMsg] = useState("")

    const pageChange = newPage => {
        setPage(newPage)
        dispatch(getSelfRegulationList(newPage, 10, searchTextGot, fieldG, orderG, onSuccess, onFailure));

    }

    useEffect(() => {
        setPage(1)
        dispatch(getSelfRegulationList(1, 10, searchTextGot, fieldG, orderG, onSuccess, onFailure));

    }, [searchTextGot])

    useEffect(() => {
        dispatch(getSelfRegulationList(1, 10, "", "", "", onSuccess, onFailure));

    }, [])




    const onSuccess = (res) => {
        setActivityData(res.data)
    }

    const onFailure = (errorMessage) => {

    }



    const yesButton = () => {


    }

    const noButtonClicked = () => {
        setCloseModal(false)
    }



    const applySorter = (columnName, sorttype) => {
        if (columnName) {

            const field = columnName;
            setPage(1)
            setFieldG(field)
            setOrderG(sorttype)
            dispatch(getSelfRegulationList(1, 10, searchTextGot, field, sorttype, onSuccess, onFailure));

        }
        else {
            setPage(1)
            dispatch(getSelfRegulationList(1, 10, searchTextGot, columnName, sorttype, onSuccess, onFailure));
        }
    }



    const sortFunc = (colName, e) => {
        var fields = sortingFields;
        Object.keys(fields).forEach(e => {
            fields[e] = "no"
        });

        let order = "";
        if (e.target.value === "no") {
            order = "ASC"
        }
        else if (e.target.value === "ASC") {
            order = "DESC"
        }
        else if (e.target.value === "DESC") {
            order = "no"
        }


        fields[colName] = order
        setSortingFields(fields)
        applySorter(order === "no" ? "" : colName, order === "no" ? "" : order)
    }

    const capitalizeFirstLetter = (string) => {
      if (string.length){
        return string.charAt(0).toUpperCase() + string.slice(1);
      }
    }


    return (

        <div>
            {closeModal &&
                <Confirmation body={confirmationDialogText} yesButtonClicked={yesButton} noButtonClicked={noButtonClicked} />
            }
            {updateApiMsg &&
                <CAlert show={5} color="success">
                    {updateApiMsg}
                </CAlert>
            }

            {regulationState.loading ? <Loader /> : null}

            {regulationState.regulations.data &&
                <div className="table-responsive">
                    <table className="table table-striped table-hover">
                        <tr>
                            <th> </th>
                            <th>{t("toolNameTable")} <CButton value={sortingFields.name} className={sortingFields.name === "no" ? "check-no" : sortingFields.name === "ASC" ? "check-asc" : sortingFields.name === "DESC" ? "check-desc" : "check-no"} onClick={(e) => { sortFunc("name", e) }}
                            >{sortingFields.name}</CButton> </th>

                            <th>{t("instructionTable")} <CButton value={sortingFields.subTasksCount} className={sortingFields.subTasksCount === "no" ? "check-no" : sortingFields.subTasksCount === "ASC" ? "check-asc" : sortingFields.subTasksCount === "DESC" ? "check-desc" : "check-no"} onClick={(e) => { sortFunc("subTasksCount", e) }}
                            >{sortingFields.subTasksCount}</CButton> </th>

                            <th>{t("createdDateTable")} <CButton value={sortingFields.createdAt} className={sortingFields.createdAt === "no" ? "check-no" : sortingFields.createdAt === "ASC" ? "check-asc" : sortingFields.createdAt === "DESC" ? "check-desc" : "check-no"} onClick={(e) => { sortFunc("createdAt", e) }}
                            >{sortingFields.createdAt}</CButton> </th>

                            <th>{t("createdByTable")} <CButton value={sortingFields.createdBy} className={sortingFields.createdBy === "no" ? "check-no" : sortingFields.createdBy === "ASC" ? "check-asc" : sortingFields.createdBy === "DESC" ? "check-desc" : "check-no"} onClick={(e) => { sortFunc("createdBy", e) }}
                            >{sortingFields.createdBy}</CButton> </th>
                            <th>{t("edit")}</th>
                        </tr>
                        {regulationState.regulations.data && regulationState.regulations.data.map(item => {
                            return (

                                <tr>
                                    <td>
                                        <div className="has-center-v-middle">
                                            <ImageMiniItem key={item.id} imageURL={item.imageURL} />
                                        </div>
                                    </td>
                                    <td>{item.name}</td>
                                    <td>{item.subTasksCount}</td>
                                    <td>{DateHelper.formatDate(item.createdAt)}</td>
                                    <td>{item.createdBy}</td>
                                    <td className="has-action-icons">
                                        <div className="multi-icons">
                                            <CIcon name="cil-pencil"
                                                onClick={(e) => {
                                                    history.push({
                                                        pathname: RouteConstants.EDIT_SELF_REGULATION.replace(":regulationId", item.id),
                                                    });
                                                }
                                                }

                                            />

                                        </div>
                                    </td>
                                </tr>

                            )

                        })}

                        {regulationState.regulations.data && regulationState.regulations.data.length === 0 ?
                            <tr> <td colspan="6"> <PlaceHolder title="No emotion regulation found."></PlaceHolder></td> </tr>
                            : null}



                    </table>


                    < CPagination
                        dots={true}
                        limit={6}
                        activePage={page}
                        onActivePageChange={pageChange}
                        pages={regulationState.regulations.meta.pageCount}
                        doubleArrows={true}
                        align="center"
                    />
                </div>

            }


        </div>
    )
}

export default DataTableActivities
