import { CImg } from '@coreui/react';
import React, { useState } from 'react'

const ImageMiniItem = ({imageURL}) => {

    const [loaded, setLoaded] = useState(false);


    return (
        <div className="c-avatar">
            {!loaded ? <p className="loader"></p> : null}
            <CImg
                src={imageURL}
                onLoad={() => {
                    setLoaded(true)
                }
                }
                className="c-avatar-img"
                alt="Activity Image"
            />
        </div>
    )
}

export default ImageMiniItem
