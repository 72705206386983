export const APP_CONSTANTS = {
    EMPTY_STRING: "",
    MIN_PASSWORD_LENGTH: 8,
    MAX_PASSWORD_LENGTH: 30,
    USER_LIST_COUNT: 10
};

export const ActivityColor = {
    RED: 1,
    YELLOW: 2,
    BLUE: 3,
    GREEN: 4,
    BLACK: 5,
    GREY: 6,
    ORANGE: 7,
    SEA_GREEN: 8,
    NAVY_BLUE: 9,
    LIGHT_GREEN: 10,
    SKY_BLUE: 11,

    DARK_BLUE: 12,
    GREEN_BLUE: 13,
};

export const REGEX_CONSTANTS = {
    Email: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
};

export const LOCAL_STORAGE_KEYS = {
    ACCESS_TOKEN: "accessToken",
    USER: "user",
    SHOW_EXPIRED_TOKEN_ERROR: "showExpiredTokenError",

};

export const STATUS = {
    USER_INACTIVE: "InActive",
    USER_ACTIVE: "Active",
    USER_DISABLED: "Disabled"
}

export const PAYMENT_STATUS = {
    PAID: "Paid",
    UNPAID: "Unpaid",
}

export const PLAN_TYPE = {
    MONTH: "month",
    YEAR: "year",
    DAY: "day",
    NO_ANY: "--",
}

export const SUBSCRIPTION_STATUS = {
    UNSUBSCRIBED: "Unsubscribed",
    SUBSCRIBED: "Subscribed",
    CANCELLED: "Cancelled",
    EXPIRED: "Expired"
}

export const ADMIN_CONST = {
    ADMIN: "ADMIN",
    SUPER_ADMIN: "SUPER_ADMIN",
}


export const NOTIFICATION_TYPE = {

    CO_ADMIN_ACTIVITY_CREATED: "CO_ADMIN_ACTIVITY_CREATED",
    CO_ADMIN_ACTIVITY_UPDATED: "CO_ADMIN_ACTIVITY_UPDATED",
    CO_ADMIN_SR_CREATED: "CO_ADMIN_SR_CREATED",
    CO_ADMIN_SR_UPDATED: "CO_ADMIN_SR_UPDATED",
    CO_ADMIN_IMAGE_CREATED: "CO_ADMIN_IMAGE_CREATED",
    CO_ADMIN_IMAGE_UPDATED: "CO_ADMIN_IMAGE_UPDATED",
    PAYMENT_FAIL_ADMIN: "PAYMENT_FAIL_ADMIN",
    PAYMENT_SUCCESS_ADMIN: "PAYMENT_SUCCESS_ADMIN",

};

