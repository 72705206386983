
import CustomerService from "src/services/api/CustomerService";
import { request, success, failure } from ".";
import { GET_CHILDREN, GET_EXTERNAL_USER, GET_PARENT_EX_USERS, UPDATE_EXUSER_STATUS } from "../../constants/ActionConstants";
import { LOCAL_STORAGE_KEYS } from "../../constants/GenericConstants";


export const getCustomerUsers = (page, take, onSuccess, onFailure) => {
    return async (dispatch) => {
        dispatch(request(GET_EXTERNAL_USER.GET_EXTERNAL_USER, {}));
        try {
            const response = await CustomerService.getCustomerUsers(page, take)

            dispatch(success(GET_EXTERNAL_USER.GET_EXTERNAL_USER_SUCCESS, { data: response.data }));
            onSuccess(response.data);
        } catch (error) {
            dispatch(failure(GET_EXTERNAL_USER.GET_EXTERNAL_USER_FAILURE, {}));
            error.data && error.data.message && onFailure(error.data.message);

        }
    };
};


export const getSearchedCustomerUsers = (page, take, q, onSuccess, onFailure) => {
    return async (dispatch) => {
        dispatch(request(GET_EXTERNAL_USER.GET_EXTERNAL_USER, {}));
        try {
            const response = await CustomerService.getSearchedCustomerUsers(page, take, q)
            dispatch(success(GET_EXTERNAL_USER.GET_EXTERNAL_USER_SUCCESS, { data: response.data }));
            onSuccess(response.data);
        } catch (error) {
            dispatch(failure(GET_EXTERNAL_USER.GET_EXTERNAL_USER_FAILURE, {}));
            error.data && error.data.message && onFailure(error.data.message);

            // error.data && error.data.message && onFailure(error.data.message);
        }
    };
};

export const getSortedCustomerUsers = (page, take, field, order, onSuccess, onFailure) => {
    return async (dispatch) => {
        dispatch(request(GET_EXTERNAL_USER.GET_EXTERNAL_USER, {}));
        try {
            const response = await CustomerService.getSortedCustomerUsers(page, take, field, order)
            dispatch(success(GET_EXTERNAL_USER.GET_EXTERNAL_USER_SUCCESS, { data: response.data }));
            onSuccess(response.data);
        } catch (error) {
            dispatch(failure(GET_EXTERNAL_USER.GET_EXTERNAL_USER_FAILURE, {}));
            error.data && error.data.message && onFailure(error.data.message);
        }
    };
};

export const getSearchedSortedCustomerUsers = (page, take, q, field, order, onSuccess, onFailure) => {
    return async (dispatch) => {
        dispatch(request(GET_EXTERNAL_USER.GET_EXTERNAL_USER, {}));
        try {
            const response = await CustomerService.getSearchedSortedCustomerUsers(page, take, q, field, order)
            dispatch(success(GET_EXTERNAL_USER.GET_EXTERNAL_USER_SUCCESS, { data: response.data }));
            onSuccess(response.data);
        } catch (error) {
            dispatch(failure(GET_EXTERNAL_USER.GET_EXTERNAL_USER_FAILURE, {}));
            error.data && error.data.message && onFailure(error.data.message);
        }
    };
};


export const getChildren = (parentId, onSuccess, onFailure) => {
    return async (dispatch) => {
        dispatch(request(GET_CHILDREN.GET_CHILDREN, {}));

        try {
            const response = await CustomerService.getChildren(parentId)
            dispatch(success(GET_CHILDREN.GET_CHILDREN_SUCCESS, {}));
            onSuccess(response.data);
        } catch (error) {
            dispatch(failure(GET_CHILDREN.GET_CHILDREN_FAILURE, {}));
            onFailure(error);

        }
    };
};

export const getParentExUsers = (parentId, onSuccess, onFailure) => {
    return async (dispatch) => {
        dispatch(request(GET_PARENT_EX_USERS.GET_PARENT_EX_USERS, {}));

        try {
            const response = await CustomerService.getParentExUsers(parentId)
            dispatch(success(GET_PARENT_EX_USERS.GET_PARENT_EX_USERS_SUCCESS, {}));
            onSuccess(response.data);
        } catch (error) {
            dispatch(failure(GET_PARENT_EX_USERS.GET_PARENT_EX_USERS_FAILURE, {}));
            onFailure(error);
        }
    };
};

export const getImageURL = (fileName, onSuccess, onFailure) => {
    return async (dispatch) => {
        // dispatch(request(IMAGE_UPLOAD_ACTIONS.IMAGE_UPLOAD, {}));
        try {
            const response = await CustomerService.getImageURL(fileName);
            // dispatch(request(IMAGE_UPLOAD_ACTIONS.IMAGE_UPLOAD_SUCCESS, {}));
            onSuccess(response)
        } catch (error) {
            // dispatch(request(IMAGE_UPLOAD_ACTIONS.IMAGE_UPLOAD_FAILURE, {}));
            onFailure(error)

        }
    };
}


export const updateExternalUserStatus = (userId, accountId, status, onSuccess, onFailure) => {
    return async (dispatch) => {
        dispatch(request(UPDATE_EXUSER_STATUS.UPDATE_EXUSER_STATUS, {}));
        try {
            const response = await CustomerService.updateExternalUserStatus(userId, accountId, status);
            console.log(response);
            dispatch(success(UPDATE_EXUSER_STATUS.UPDATE_EXUSER_STATUS_SUCCESS, {}));
            onSuccess(response.message);
        } catch (error) {
            dispatch(failure(UPDATE_EXUSER_STATUS.UPDATE_EXUSER_STATUS_FAILURE, {}));
            onFailure(error)
        }
    };
}


