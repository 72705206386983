import React, { useEffect, useState } from 'react'
import { CAlert, CButton, CCard, CCardBody, CCardHeader, CCol, CFormGroup, CImg, CInput, CInputFile, CLabel, CRow } from '@coreui/react'
import { differenceYears } from "../../../helpers/GenericHelper";
import { useDispatch, useSelector } from 'react-redux';
import { getImageURL } from 'src/store/actions/Customer';
import CIcon from '@coreui/icons-react';
import AddFromImageLibModal from './AddFromImageLibModal';
import { validateMandatoryValue, validateObject, validateTaskNameLength } from 'src/helpers/Validation';
import Confirmation from 'src/views/common/Confirmation';
import AddIcon from '../../../assets/images/plus.svg';
import RemoveIcon from '../../../assets/images/remove.svg';
import { useTranslation } from "react-i18next";
import UpArrowIcon from '../../../assets/images/up-arrow.jpg';
import DownArrowIcon from '../../../assets/images/down-arrow.jpg';

const TaskItem = ({ dataGot, setSubtasks, subTasks, setOnHold, setChanged, formFilled, validateIt }) => {

    const { id, name, image, enable, index, order } = dataGot

    const [taskImage, setTaskImage] = useState("https://i.stack.imgur.com/l60Hf.png");
    const [taskName, setTaskName] = useState("");
    const [addtaskImage, setAddTaskImage] = useState({});
    const [openModalAdd, setOpenModalAdd] = useState(false)
    const categoriesState = useSelector((state) => state.categories);

    const imageLibraryState = useSelector((state) => state.imageLibrary);

    const [actImage, setActImage] = useState({});

    const { t } = useTranslation();


    const [taskNameError, setTaskNameError] = useState("");
    const [actImageError, setActImageError] = useState("");
    const [inFormFilled, setInFormFilled] = useState(false)

    const [closeModal, setCloseModal] = useState(false);

    useEffect(() => {
        if (image !== "") {
            setTaskImage(image)
        }
    }, [name, image])

    const handleModalAdd = () => {
        setOpenModalAdd(prev => !prev)
    }

    const setActivityImage = (id) => {
        let imgObj = imageLibraryState.imagesList.filter((i) => i.id === id)[0];
        setActImage(imgObj)
        if (Object.keys(imgObj).length !== 0) {
            setChanged(true)
        }
        else if (taskName !== "") {
            setChanged(true)
        }
        else {
            setChanged(false)
        }
        setOpenModalAdd(prev => !prev)

        if (inFormFilled) {
            validateActImage(imgObj)
        }
    }

    const validateActImage = (imgObj) => {
        if (!validateObject(imgObj)) {
            setActImageError(t("imageRequired"))
            return false;
        }
        else {
            setActImageError("")
            return true;
        }
    }

    const validateTaskName = (taskName) => {
        if (!validateMandatoryValue(taskName)) {
            setTaskNameError(t("instNameRequired"))
            return false;
        }
        if (!validateTaskNameLength(taskName)) {
            setTaskNameError(t("instNameLength"))
            return false;
        }
        else {
            setTaskNameError("")
            return true;
        }
    }

    const isFormValid = () => {
        setInFormFilled(true)
        return validateActImage(actImage) & validateTaskName(taskName);
    }

    const yesButton = () => {

        setOnHold(false)
        setSubtasks(prev => prev.filter(i => i.id !== id))
        setCloseModal(false)


    }
    const noButton = () => {
        setCloseModal(false)
    }



    return (
        <CCol xs={12} md={12}>
            <CCard>

                {closeModal &&
                    <Confirmation body={t("removeSubTaskMsg")} yesButtonClicked={yesButton} noButtonClicked={noButton} />
                }

                <CCardBody>
                    <CRow className="subTask-sec">
                        <CCol className="user-image">
                            <div className="c-avatar">
                                {!enable ?
                                    <CImg
                                        src={taskImage}
                                        style={{ width: "60px" }}
                                        className="c-avatar-img"
                                        alt="Task Image"
                                    />
                                    :

                                    <div className="upload-photo">
                                        <div onClick={() => handleModalAdd()}>

                                            <CImg
                                                style={{ width: "60px" }}
                                                className="c-avatar-img"
                                                alt="Task Picture"
                                                src={Object.keys(actImage).length !== 0 ? actImage.imageURL : "https://i.stack.imgur.com/l60Hf.png"}

                                            />

                                        </div>
                                        {
                                            actImageError && <CLabel className="field-error">{actImageError}</CLabel>
                                        }
                                    </div>

                                }
                            </div>

                        </CCol>
                        <CCol>
                            {!enable ?
                                <h6>{name ? name : "--"}</h6>

                                :
                                <CInput
                                    placeholder={t("instructionName")}
                                    size="md"
                                    value={taskName}
                                    onChange={(e) => {
                                        setTaskName(e.target.value)
                                        if (e.target.value !== "") {
                                            setChanged(true)
                                        }
                                        else if (Object.keys(actImage).length !== 0) {
                                            setChanged(true)
                                        }
                                        else {
                                            setChanged(false)
                                        }
                                        if (inFormFilled) {
                                            validateTaskName(e.target.value)
                                        }
                                    }}
                                />


                            }
                            {
                                taskNameError && <CLabel className="field-error">{taskNameError}</CLabel>
                            }

                        </CCol>
                        <CCol className="text-right">
                            {!enable ?
                                <CIcon className="st-icon" src={RemoveIcon}
                                    onClick={(e) => {
                                        setCloseModal(true)
                                    }}
                                />
                                :
                                <div
                                    onClick={(e) => {
                                        if (isFormValid()) {
                                            let tempSubtasks = subTasks;
                                            tempSubtasks = tempSubtasks.filter(i => i.id !== id)
                                            tempSubtasks = tempSubtasks.concat({ "id": id, "order": order, "imageId": actImage.id, "image": actImage.imageURL, "name": taskName, enable: false })
                                            setSubtasks(tempSubtasks)
                                            if (tempSubtasks.length <= 3) {
                                                setOnHold(false)
                                            }
                                            // if (formFilled) {
                                            //     validateIt(tempSubtasks)
                                            // }
                                        }
                                    }}>
                                    <CIcon className="st-icon" src={AddIcon} />

                                </div>


                            }
                            {!enable && subTasks.filter(s => s.enable === true).length === 0 && subTasks.length > 1 && <>

                                <CButton className="nobgoclor"
                                    disabled={index === 0}
                                    onClick={() => {
                                        let tempSubtasks = subTasks;
                                        let oldOrder = tempSubtasks[index].order
                                        let oldID = tempSubtasks[index].id
                                        tempSubtasks[index].order = tempSubtasks[index - 1].order
                                        tempSubtasks[index].id = tempSubtasks[index - 1].id
                                        tempSubtasks[index - 1].order = oldOrder
                                        tempSubtasks[index - 1].id = oldID

                                        setSubtasks([...tempSubtasks])
                                    }}
                                ><CIcon src={UpArrowIcon} /></CButton>

                                <CButton className="nobgoclor"
                                    disabled={(subTasks.length !== 0 && index === subTasks.length - 1) || subTasks.length === 0}
                                    onClick={() => {
                                        let tempSubtasks = subTasks;
                                        let oldOrder = tempSubtasks[index].order
                                        let oldID = tempSubtasks[index].id
                                        tempSubtasks[index].order = tempSubtasks[index + 1].order
                                        tempSubtasks[index].id = tempSubtasks[index + 1].id
                                        tempSubtasks[index + 1].order = oldOrder
                                        tempSubtasks[index + 1].id = oldID

                                        setSubtasks([...tempSubtasks])
                                    }}
                                ><CIcon src={DownArrowIcon} /></CButton>
                            </>}

                        </CCol>
                    </CRow>

                    {openModalAdd ? <AddFromImageLibModal openModal={openModalAdd} handleModal={handleModalAdd} categories={categoriesState.categories} setActivityImage={setActivityImage} /> : null}


                </CCardBody>

            </CCard>
        </CCol >
    )
}

export default TaskItem
