import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  CCreateElement,
  CSidebar,
  CSidebarBrand,
  CSidebarNav,
  CSidebarNavDivider,
  CSidebarNavTitle,
  CSidebarMinimizer,
  CSidebarNavDropdown,
  CSidebarNavItem,
} from '@coreui/react'

// import CIcon from '@coreui/icons-react'
import CIcon from "../assets/images/admin-logo-white.png";

// sidebar nav config
import navigation from './_nav'
import { getViewPermValue } from 'src/helpers/PermissionCalculator';
import { updateSideBar } from 'src/store/actions/SideBar';

const TheSidebar = () => {
  const dispatch = useDispatch()
  const show = useSelector(state => state.sidebarShow)
  const [navs, setNavs] = useState([]);

  const user = useSelector((state) => state.authentication.user);

  useEffect(() => {
    var navGot = [];
    navigation.map(n => {
      if (n.priv === 100) {  //No permissions Setup Done- like for Image Lib and acitivities
        navGot.push({ ...n, show: true })
      }
      else if (n.priv !== -1) {
        navGot.push({ ...n, show: getViewPermValue(n.priv) })
      }

    })
    setNavs(navGot)

  }, [user])

  return (
    <CSidebar
      show={show.sidebarShow}
      onShowChange={(val) => dispatch(updateSideBar(val))}
    >
      <CSidebarBrand className="d-md-down-none" to="/">
        <img className="admin-sidebar-logo" src={CIcon} />
      </CSidebarBrand>
      <CSidebarNav>

        <CCreateElement
          items={navs.filter(item => item.show === true)}
          components={{
            CSidebarNavDivider,
            CSidebarNavDropdown,
            CSidebarNavItem,
            CSidebarNavTitle
          }}
        />
      </CSidebarNav>
      <CSidebarMinimizer className="c-d-md-down-none d-none" />
    </CSidebar>
  )
}

export default React.memo(TheSidebar)
