import React, { useEffect, useState } from 'react'
import { CAlert, CButton, CCard, CCardBody, CCardHeader, CCol, CFormGroup, CInput, CLabel, CRow, CSwitch } from '@coreui/react'
import { useHistory, useLocation } from 'react-router-dom'
import Confirmation from '../common/Confirmation'
import RouteConstants from 'src/constants/RouteConstants'
import { useDispatch, useSelector } from 'react-redux'
import { removeUser, updateUser, updateUserStatus } from 'src/store/actions/User'
import Loader from '../common/Loader/Loader'
import { USER_PRIVILEGES } from 'src/constants/StaticData'
import UserPrivileges from '../users/UserPrivileges'
import { calculatePermissionOfEdit, calculatePermissionOfView, getPermissionOfEdit, getPermissionOfView } from 'src/helpers/PermissionCalculator'
import { useTranslation } from "react-i18next";


const EditUser = () => {

    const location = useLocation();
    const dispatch = useDispatch();
    const history = useHistory();

    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [permission, setPermission] = useState(0);

    const [apiError, setApiError] = useState("");

    const user = useSelector((state) => state.authentication.user);
    const { t } = useTranslation();



    console.log("User Permission", location.user);
    useEffect(() => {

        if (user) {
            setFirstName(user.firstName)
            setLastName(user.lastName)
            if (user.accounts) {
                setPermission(user.accounts[0].permissions)
            }

        }
        // if (location.user) {
        //     setFirstName(user.firstName);
        //     setLastName(user.lastName);
        //     permission = location.user.permissions;
        // }
        // else {
        //     history.replace(RouteConstants.USER_LIST)
        // }


    }, [])




    const onCancel = () => {
        history.replace({ pathname: RouteConstants.USER_LIST });
    }
    const onSubmit = () => {

        setApiError("");
        if (firstName.length > 0 && lastName.length > 0) {
            // dispatch(updateUser(user.id, firstName, lastName, user.accountId, permission, onUpdateUserSuccess, onRequestFailure));
        } else {
            setApiError("Please provide first name and last name.");
        }

    }

    const viewSwitchChanged = (value, object) => {

        if (value) {
            permission = permission + calculatePermissionOfView(value, object);
        }
        else {
            permission = permission - calculatePermissionOfView(value, object);
        }


    }

    const editSwitchChanged = (value, object) => {


        if (value) {
            permission = permission + calculatePermissionOfEdit(value, object);
        }
        else {
            permission = permission - calculatePermissionOfEdit(value, object);
        }


    }

    return (

        <CRow>
            {user.loading && <Loader />}
            {apiError &&
                <CAlert color="danger">
                    {apiError}
                </CAlert>
            }

            <CCol lg={12}>
                <CCard>
                    <CCardHeader>

                        <CRow>
                            <CCol sm={6} xs={12} className="d-flex align-items-center">

                                {t("myAccount")}
                            </CCol>
                            <CCol sm={6} xs={12} className="btn-primary-secondary">

                            </CCol>
                        </CRow>
                    </CCardHeader>

                    <CCardBody>
                        <CRow>
                            <CCol xs="12" md="6">
                                <CFormGroup>
                                    <CLabel htmlFor="name">First Name</CLabel>
                                    <CInput type="text" value={firstName} onChange={e => { setFirstName(e.target.value) }} autoComplete="firstName" />

                                </CFormGroup>
                            </CCol>

                            <CCol xs="12" md="6">

                                <CFormGroup>
                                    <CLabel htmlFor="name">Last Name</CLabel>
                                    <CInput id="name" value={lastName} onChange={e => { setLastName(e.target.value) }} />
                                </CFormGroup>


                            </CCol>
                            <CCol xs="12" md="6">
                                <CFormGroup>
                                    <CLabel htmlFor="ccnumber">Email Address</CLabel>
                                    <CInput id="ccnumber" value={user && user.emailAddress} disabled />
                                </CFormGroup>
                            </CCol>
                        </CRow>
                        <CRow className="rights-header">
                            <CCol md={4} xs={6} tag="label" className="col-form-label">

                            </CCol>

                            <CCol xs={2} tag="label" className="col-form-label">
                                View
                            </CCol>

                            <CCol xs={2} tag="label" className="col-form-label" >
                                View and Edit
                            </CCol>

                        </CRow>
                        <br />
                        <div className="switcher-list">
                            {USER_PRIVILEGES.map(privileges => {
                                return (<UserPrivileges permissions={permission} privileges={privileges} viewSwitchChanged={viewSwitchChanged} editSwitchChanged={editSwitchChanged} />)
                            })}
                        </div>

                        <CRow >
                            <CCol className="footer-has-primary-secondary-btn pt-3">

                                <CButton
                                    variant="outline" color="dark" style={{ float: "right" }} onClick={onCancel}  >Cancel</CButton>
                                <CButton
                                    variant="outline" color="dark" style={{ float: "right", marginRight: 10 }} onClick={onSubmit}>Save</CButton>
                            </CCol>

                        </CRow>

                    </CCardBody>
                </CCard>
            </CCol>
        </CRow >
    )
}

export default EditUser
