import authentication from "./Authentication";
import user from "./User";
import customer from "./Customer";
import categories from "./Categories";
import imageLibrary from "./ImageLib";
import activity from "./Activity";
import regulation from "./SelfRegulation";
import sidebarShow from "./Sidebar";
import notifications from "./Notifications";
import payment from "./Payment";

export default { authentication, user, customer, categories, imageLibrary, activity, regulation, sidebarShow, notifications,payment };
