import {
    CModal, CModalBody, CModalFooter, CModalHeader, CButton,
    CCard,
    CCardBody,
    CCardHeader,
    CCol,
    CRow,
    CInput,
    CAlert,
    CImg,
    CInputCheckbox,
    CInputFile,
    CLabel,
} from "@coreui/react";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import { getCategories } from "src/store/actions/Categories";
import { getImageLibList } from "src/store/actions/ImageLib";
import InfiniteScroll from 'react-infinite-scroller';
import ImageProfileActivity from "src/views/activity/components/ImageProfileActivity";
import Loader from "src/views/common/Loader/Loader";
import AddImageModal from "src/views/imageGallery/components/AddImageModal";
import PlaceHolder from "src/views/common/PlaceHolder";
import imgPlaceholder from "src/assets/images/image-placeholder.svg"


const AddFromImageLibModal = ({ openModal, handleModal, setActivityImage }) => {

    const [actImage, setActImage] = useState({});

    const location = useLocation()
    const dispatch = useDispatch();

    const imageLibraryState = useSelector((state) => state.imageLibrary);

    const [categories, setCategories] = useState([])
    const [selectedCat, setSelectedCat] = useState([]);
    const [imgSelected, setImgSelected] = useState({});
    const [searchText, setSearchText] = useState("")
    const [openModalAdd, setOpenModalAdd] = useState(false)
    const [ref, setRef] = useState(null);
    const [apiRes, setApiRes] = useState("");
    const [isEmpty, setIsEmpty] = useState(false);
    const [hideBool, setHideBool] = useState(false);

    const [localCategories, setLocalCategories] = useState([])

    const [loadMoreText, setLoadMoreText] = useState("Load more")



    const { t } = useTranslation();

    useEffect(() => {
        dispatch(getCategories(onSuccess, onFailure))
        dispatch(getImageLibList(1, searchText, selectedCat, onLibRetrieved, onFailure))

    }, [])

    // useEffect(() => {
    //     if (apiRes !== "") {
    //         dispatch(getImageLibList(1, searchText, selectedCat, onLibRetrieved, onFailure))
    //     }
    // }, [apiRes])

    useEffect(() => {
        if (imageLibraryState.addedImage) {
            dispatch(getImageLibList(1, searchText, selectedCat, onLibRetrieved, onFailure))
            setApiRes(imageLibraryState.addedImage.message)
        }

    }, [imageLibraryState.addedImage])


    const onSuccess = (res) => {
        setCategories(res)

        let temp = [];
        res.map(c => {
            temp.push({ name: c.name, value: c.value, selected: false })
        })
        temp = temp.concat({ name: "all", value: "Show all", selected: true })
        setLocalCategories(temp)


    }
    const onLibRetrieved = (res) => {
        if (res.meta && res.meta.page !== 1) {
            let objDiv = document.getElementById("scrollDiv");
            objDiv.scrollTop = objDiv.scrollHeight;
        }

        setLoadMoreText("Load more")

        if (res.data && res.data.length === 0) {
            setIsEmpty(true)
        }
    }

    const onFailure = (err) => {

    }

    const handleModalAdd = () => {
        setHideBool(prev => !prev)
        setOpenModalAdd(prev => !prev)
    }

    const setSelectedImage = (id) => {
        setImgSelected(imageLibraryState.imagesList.filter((i) => i.id === id)[0])
    }

    const fetchMoreImages = () => {
        if (!imageLibraryState.loading && !imageLibraryState.secondLoading && imageLibraryState.metaImagesList.hasNextPage) {
            setLoadMoreText("Loading...")

            dispatch(getImageLibList(imageLibraryState.metaImagesList.page + 1, searchText, selectedCat, onLibRetrieved, onFailure));
        }
    }
    const searchLib = (text) => {
        setIsEmpty(false)
        dispatch(getImageLibList(1, text, selectedCat, onLibRetrieved, onFailure))
    }

    const categoryFilter = (catList) => {
        setIsEmpty(false)
        dispatch(getImageLibList(1, searchText, catList, onLibRetrieved, onFailure))

    }

    return (
        <>

            {imageLibraryState.loading && <Loader />}

            <CModal
                className="create-new-lib-popup"
                hidden={hideBool}
                show={openModal}
                onClose={() => handleModal()}
                size="xl"
            >
                <CModalHeader closeButton>{t("manageLib")}</CModalHeader>
                <CModalBody>

                    <CRow>
                        <CCol className="d-flex align-items-center">
                            <h5 className="page-title py-2 mr-15">{t("imageLib")}</h5>
                            <CButton
                                onClick={() => handleModalAdd()}
                                variant="outline" color="primary" className="my-2">
                                {t("addNew")}
                            </CButton>
                        </CCol>
                        {/* <small className="text-muted"> example</small> */}
                        <CCol className="search-n-btn" >
                            <CInput
                                placeholder={t("searchPholder")}
                                size="sm"

                                onKeyUp={(e) => {
                                    if (e.key === 'Enter') {
                                        setSearchText(e.target.value)
                                        searchLib(e.target.value)
                                    }
                                    if (e.key === 'Backspace') {
                                        if (e.target.value === "") {
                                            setSearchText("")
                                            searchLib(e.target.value)
                                        }
                                    }
                                }}
                                size="md"
                            />
                        </CCol>

                    </CRow>
                    <CRow>
                        <CCol xl={8}>
                            <CCard class="mb-1-8rem">
                                <CCardHeader className="px-0">
                                    {imageLibraryState.loading && <Loader />}


                                    <CRow className="align-items-center mt-2">
                                        <CCol sm="12" xs="12" className="check-filters">
                                            {localCategories.map(cat => {

                                                return (
                                                    <div className="filter-items">
                                                        <CInputCheckbox
                                                            id="checkbox1"

                                                            name={cat.name}
                                                            checked={cat.selected}
                                                            value={cat.value}
                                                            onChange={(e) => {
                                                                var catSelected = selectedCat;
                                                                if (e.target.checked) {
                                                                    if (e.target.name === "all") {
                                                                        let temp = [];
                                                                        localCategories.map(c => {
                                                                            if (c.name !== "all") {
                                                                                temp.push({ ...c, selected: false })
                                                                            }
                                                                        })
                                                                        temp.push({ name: "all", value: "Show all", selected: true })
                                                                        setLocalCategories(temp)
                                                                        setSelectedCat([])
                                                                        categoryFilter([])

                                                                    }
                                                                    else {
                                                                        let temp = [];
                                                                        localCategories.map(c => {
                                                                            if (c.name === e.target.name) {
                                                                                temp.push({ ...c, selected: true })
                                                                            }
                                                                            else if (c.selected === true && c.name !== "all") {
                                                                                temp.push({ ...c, selected: true })
                                                                            }
                                                                            else if (c.name === "all") {
                                                                                temp.push({ ...c, selected: false })
                                                                            }
                                                                            else {
                                                                                temp.push({ ...c, selected: false })
                                                                            }
                                                                        })
                                                                        setLocalCategories(temp)

                                                                        catSelected = catSelected.filter(item => item !== "all")
                                                                        catSelected.push(e.target.name)
                                                                        setSelectedCat(catSelected)
                                                                        categoryFilter(catSelected)
                                                                    }
                                                                }
                                                                else {
                                                                    let temp = [];
                                                                    let allDeselect = true
                                                                    localCategories.map(c => {
                                                                        if (c.name === e.target.name) {
                                                                            temp.push({ ...c, selected: false })
                                                                        }
                                                                        else {
                                                                            temp.push({ ...c })
                                                                        }
                                                                        if (c.name !== e.target.name && c.selected === true) {
                                                                            allDeselect = false;
                                                                        }
                                                                    })
                                                                    if (allDeselect) {
                                                                        temp = temp.map(p =>
                                                                            p.name === 'all'
                                                                                ? { ...p, selected: true }
                                                                                : p
                                                                        );
                                                                    }

                                                                    setLocalCategories(temp)

                                                                    catSelected = catSelected.filter(item => item !== e.target.name)
                                                                    setSelectedCat(catSelected)
                                                                    categoryFilter(catSelected)


                                                                }
                                                            }}
                                                        />
                                                        <span>{cat.value}</span>
                                                    </div>
                                                    // <CButton type="button" class="btn btn-pill btn-dark mr-1">{cat.name}</CButton>
                                                )
                                            })}





                                        </CCol>

                                    </CRow>

                                    {apiRes &&
                                        <CAlert show={5} color="success">
                                            {apiRes}
                                        </CAlert>
                                    }

                                </CCardHeader>

                                <CCardBody className="image-thumb-list">

                                    {isEmpty ? <PlaceHolder title={t("noImageFound")}></PlaceHolder> : null}


                                    <div id="scrollDiv" ref={ref => { setRef(ref); }} className="scrollDiv2">
                                        <div className="px-15">
                                            <InfiniteScroll
                                                pageStart={1}
                                                // loadMore={fetchMoreImages}
                                                hasMore={imageLibraryState.metaImagesList.hasNextPage}
                                                useWindow={false}
                                                initialLoad={true}
                                                threshold={10}
                                                getScrollParent={() => ref}>
                                                <CRow>
                                                    {imageLibraryState.imagesList.map((iprofile, index) => {
                                                        return <ImageProfileActivity data={iprofile} key={iprofile.id} setSelectedImage={setSelectedImage} />
                                                    })}
                                                </CRow>
                                            </InfiniteScroll>
                                        </div>
                                        <div className="loadmore-imgLib mt-40">
                                            {imageLibraryState.metaImagesList.hasNextPage ?
                                                <CButton onClick={() => {
                                                    fetchMoreImages()
                                                }}
                                                >{loadMoreText}</CButton> : null}</div>

                                    </div>

                                </CCardBody>





                            </CCard>
                        </CCol>

                        <CCol xl={4}>
                            <CCard>

                                <CCardBody>

                                    <CImg
                                        style={{ width: "300px"}}
                                        alt="Child Profile Picture"
                                        src={Object.keys(imgSelected).length !== 0 ? imgSelected.imageURL : imgPlaceholder} />

                                    <br></br>
                                    <br></br>

                                    <CInput
                                        placeholder={t("imageName")}
                                        size="md"
                                        value={Object.keys(imgSelected).length !== 0 ? imgSelected.imageTitle : ""}
                                        disabled={true}
                                    />
                                    <br></br>

                                    <CLabel><strong>{t("imageCat")}</strong></CLabel>

                                    <CRow className="align-items-center mt-2">
                                        <CCol xs="12" className="check-filters nowrap">
                                            {categories.map(cat => {
                                                var exist = false;

                                                if (Object.keys(imgSelected).length !== 0 && imgSelected.categories.includes(cat.name)) {
                                                    exist = true;
                                                }

                                                return (
                                                    <div className="filter-items">
                                                        <CInputCheckbox
                                                            id="checkbox1"
                                                            checked={exist}
                                                            name={cat.name}
                                                            value={cat.value}
                                                        />
                                                        <span>{cat.value}</span>
                                                    </div>
                                                    // <CButton type="button" class="btn btn-pill btn-dark mr-1">{cat.name}</CButton>
                                                )
                                            })}

                                        </CCol>
                                    </CRow>





                                </CCardBody>



                            </CCard>
                        </CCol>
                    </CRow>


                </CModalBody >
                <CModalFooter className="footer-has-primary-secondary-btn">
                    <CButton color="primary" onClick={() => handleModal()} >{t("cancel")}</CButton>{' '}
                    <CButton color="primary" onClick={() => setActivityImage(imgSelected.id)} >{t("upload")}</CButton>{' '}

                </CModalFooter>
            </CModal >


            <AddImageModal openModal={openModalAdd} handleModal={handleModalAdd} categories={categories} setApiRes={setApiRes} />

        </>

    );
};

export default AddFromImageLibModal;
