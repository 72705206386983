import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useIdleTimer } from 'react-idle-timer';

import { LOCAL_STORAGE_KEYS } from "../constants/GenericConstants";
import RouteConstants from "../constants/RouteConstants";
import { logout } from "../store/actions/Authentication";
import { CAlert, CButton } from "@coreui/react";

const InactivityLogout = () => {

  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [showAlert, setShowAlert] = useState(false);


  useEffect(() => {

    if (localStorage.getItem(LOCAL_STORAGE_KEYS.SHOW_EXPIRED_TOKEN_ERROR)) {
      history.replace({
        pathname: RouteConstants.BASE,
        state: { sessionExpired: true }
      });
      localStorage.removeItem(LOCAL_STORAGE_KEYS.SHOW_EXPIRED_TOKEN_ERROR);
    }
  }, [])



  const handleOnIdle = (event) => {
    // console.log('user is idle', event);
    // console.log('last active', getLastActiveTime());
  }

  const onLogoutSuccess = () => {
    history.replace({
      pathname: RouteConstants.BASE,
      state: { sessionExpired: true }
    });
  }

  const handleOnActive = (event) => {
    const userGot = localStorage.getItem(LOCAL_STORAGE_KEYS.USER);
    if (getRemainingTime() <= 0 && userGot !== null) {
      // console.log('Clearing local storage');
      dispatch(logout(onLogoutSuccess, () => { }));
    }
  }

  const handleOnAction = (event) => {
  }

  const { getRemainingTime, getLastActiveTime } = useIdleTimer({
    timeout: 1000 * 60 * 15,
    onIdle: handleOnIdle,
    onActive: handleOnActive,
    onAction: handleOnAction,
    debounce: 500
  })

  return (
    <>
    </>
  );
}

export default InactivityLogout;