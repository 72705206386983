import React, { useEffect, useState } from 'react'
import {
    CBadge,
    CDropdown,
    CDropdownItem,
    CDropdownMenu,
    CDropdownToggle,
    CImg
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getNotifications, markAllRead } from 'src/store/actions/Notifications';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroller';
import DateHelper from 'src/helpers/DateHelper';
import { NOTIFICATION_TYPE } from 'src/constants/GenericConstants';
import RouteConstants from 'src/constants/RouteConstants';


const Notifications = () => {

    const { t } = useTranslation();
    const history = useHistory();
    const dispatch = useDispatch();
    const location = useLocation();
    const [ref, setRef] = useState(null);
    const notificationState = useSelector((state) => state.notifications);
    const [showError, setShowError] = useState(false);
    const [showIT, setShowIt] = useState(false);

    useEffect(() => {
        dispatch(getNotifications(1, onLoadSuccess));

    }, [])


    const onLoadSuccess = (data) => {
        // const index = data.data.findIndex(notification => notification.notificationType === NOTIFICATION_TYPE.PARENT_PORTAL_SIGNUP);
        // console.log(index);
        // if (index > -1 && !data.data[index].isRead) {
        //     dispatch(markNotificationAsRead(data.data[index].id, index));
        // }
        // alert(JSON.stringify(data))
    }

    const fetchMore = () => {
        if (!notificationState.loading && notificationState.notificationListMeta.hasNextPage) {
            dispatch(getNotifications(notificationState.notificationListMeta.page + 1));
        }
    }


    const getNotificationsList = () => {
        return notificationState.notificationList.map((notification, index) => {
            return (

                <CDropdownItem
                    onClick={() => {
                        openRelevantScreen(index, notification)
                    }}>
                    <div className="message">
                        <div className="mr-3 float-left">
                            <div className="c-avatar">
                                <CImg
                                    src={notification.imageURL.length > 0 ? notification.imageURL : "https://i.stack.imgur.com/l60Hf.png"}
                                    className="c-avatar-img"
                                />
                            </div>
                        </div>
                        <div className="notify-right-container">
                            <div className="text-truncate font-weight-bold notify-title">
                                <span className="fa fa-exclamation text-danger"></span> <h3>{notification.title}</h3>
                                {!notification.isRead && <span className="c-avatar-status bg-danger"></span>}
                            </div>
                            <div className="small text-muted notify-text">
                                {notification.message}
                                <div className="just-now">
                                    <small className="text-muted mt-1">{DateHelper.formatDateForNotifications(notification.createdAt)}</small>
                                </div>
                            </div>
                        </div>
                    </div>
                </CDropdownItem>

            );
        });
    }

    const openRelevantScreen = (index, notification) => {
        dispatch(markAllRead());

        console.log("Opening relevant screen....");
        console.log(notification);

        let id = notification.additionalData && JSON.parse(notification.additionalData).id ? JSON.parse(notification.additionalData).id : -1;

        switch (notification.notificationType) {
            case NOTIFICATION_TYPE.CO_ADMIN_ACTIVITY_CREATED:
                id !== -1 ? history.push({ pathname: RouteConstants.EDIT_ACTIVITY.replace(":activityId", id), }) : history.push({ pathname: RouteConstants.ACTIVITY })
                break;

            case NOTIFICATION_TYPE.CO_ADMIN_ACTIVITY_UPDATED:
                id !== -1 ? history.push({ pathname: RouteConstants.EDIT_ACTIVITY.replace(":activityId", id), }) : history.push({ pathname: RouteConstants.ACTIVITY });
                break;

            case NOTIFICATION_TYPE.CO_ADMIN_IMAGE_CREATED:
                if (location.pathname === RouteConstants.IMAGE_LIB) {
                    id !== -1 ? history.replace({ pathname: RouteConstants.IMAGE_LIB, id: id }) : history.replace({ pathname: RouteConstants.IMAGE_LIB });
                }
                else {
                    id !== -1 ? history.push({ pathname: RouteConstants.IMAGE_LIB, id: id }) : history.push({ pathname: RouteConstants.IMAGE_LIB });
                }
                break;
            case NOTIFICATION_TYPE.CO_ADMIN_IMAGE_UPDATED:
                if (location.pathname === RouteConstants.IMAGE_LIB) {
                    id !== -1 ? history.replace({ pathname: RouteConstants.IMAGE_LIB, id: id }) : history.replace({ pathname: RouteConstants.IMAGE_LIB });
                }
                else {
                    id !== -1 ? history.push({ pathname: RouteConstants.IMAGE_LIB, id: id }) : history.push({ pathname: RouteConstants.IMAGE_LIB });
                }
                break;

            case NOTIFICATION_TYPE.CO_ADMIN_SR_CREATED:
                id !== -1 ? history.push({ pathname: RouteConstants.EDIT_SELF_REGULATION.replace(":regulationId", id), }) : history.push({ pathname: RouteConstants.SELF_REGULATION });
                break;
            case NOTIFICATION_TYPE.CO_ADMIN_SR_UPDATED:
                id !== -1 ? history.push({ pathname: RouteConstants.EDIT_SELF_REGULATION.replace(":regulationId", id), }) : history.push({ pathname: RouteConstants.SELF_REGULATION });
                break;

            case NOTIFICATION_TYPE.PAYMENT_FAIL_ADMIN:
                let userId = notification.additionalData && JSON.parse(JSON.parse(notification.additionalData)).userId ? JSON.parse(JSON.parse(notification.additionalData)).userId : -1
                let obj = notification.additionalData && JSON.parse(JSON.parse(notification.additionalData))
                if (userId !== -1) {
                    history.push({
                        pathname: RouteConstants.VIEW_PAYMENTS.replace(":customerId", userId),
                        data: obj
                    });
                }
                break;

            case NOTIFICATION_TYPE.PAYMENT_SUCCESS_ADMIN:
                let userIdSuccss = notification.additionalData && JSON.parse(JSON.parse(notification.additionalData)).userId ? JSON.parse(JSON.parse(notification.additionalData)).userId : -1
                let objSuccess = notification.additionalData && JSON.parse(JSON.parse(notification.additionalData))
                if (userIdSuccss !== -1) {
                    history.push({
                        pathname: RouteConstants.VIEW_PAYMENTS.replace(":customerId", userIdSuccss),
                        data: objSuccess
                    });
                }
                break;


        };
    }


    const itemsCount = 4
    return (
        <CDropdown
            inNav
            className="c-header-nav-item mx-2"
            direction="down"
        >
            <CDropdownToggle className="c-header-nav-link" caret={false}
                onClick={() => {
                    let showBool = showIT;
                    if (showBool === false) {
                        setTimeout(() => {
                            dispatch(markAllRead());
                        }, 2000);
                    }
                    setShowIt(prev => !prev)
                }}>
                <CIcon name="cil-bell" /><CBadge shape="pill" color="danger">
                    {notificationState && notificationState.notificationList && notificationState.notificationList.filter((item) => item.isRead === false).length > 0 ?
                        <em></em>
                        : null}
                </CBadge>
            </CDropdownToggle>
            <CDropdownMenu className="pt-0 notifications" placement="bottom-end">
                <CDropdownItem
                    header
                    tag="div"
                    color="light"
                >
                    <h2>Notifications</h2>
                </CDropdownItem>
                <div ref={ref => { setRef(ref); }} className="notificationsScroll" >
                    <div className="pt-3">
                        <InfiniteScroll
                            pageStart={1}
                            loadMore={fetchMore}
                            hasMore={notificationState.notificationListMeta.hasNextPage}
                            useWindow={false}
                            initialLoad={true}
                            threshold={30}
                            getScrollParent={() => ref}>
                            {notificationState.notificationList.length === 0 && !notificationState.loading ?
                                <div>
                                    <p>{t("noNotifications")}</p>
                                </div> :
                                getNotificationsList()
                            }
                            {
                                notificationState.loading &&
                                <div className="loader mr-auto"></div>
                            }
                        </InfiniteScroll>
                    </div>
                </div>
            </CDropdownMenu>
        </CDropdown>
    )
}

export default Notifications
