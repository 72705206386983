export const LOGIN_ACTIONS = {
  LOGIN: "LOGIN",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOGIN_FAILURE: "LOGIN_FAILURE",
  RESET_LOGIN_ERROR: "RESET_LOGIN_ERROR",
};


export const LOGOUT_ACTIONS = {
  LOGOUT_ACTIONS: "LOGIN",
  LOGOUT_ACTIONS_SUCCESS: "LOGIN_SUCCESS",
  LOGOUT_ACTIONS_FAILURE: "LOGIN_FAILURE"
};

export const SET_PASSWORD_ACTIONS = {
  SET_PASSWORD: "SET_PASSWORD",
  SET_PASSWORD_SUCCESS: "SET_PASSWORD_SUCCESS",
  SET_PASSWORD_FAILURE: "SET_PASSWORD_FAILURE"
};

export const VERIFY_CODE_ACTIONS = {
  VERIFY_CODE: "VERIFY_CODE",
  VERIFY_CODE_SUCCESS: "VERIFY_CODE_SUCCESS",
  VERIFY_CODE_FAILURE: "VERIFY_CODE_FAILURE"
};

export const SIGN_UP_ACTIONS = {
  SIGN_UP: "SIGN_UP",
  SIGN_UP_SUCCESS: "SIGN_UP_SUCCESS",
  SIGN_UP_FAILURE: "SIGN_UP_FAILURE",
};

export const CONFIRM_EMAIL_ACTIONS = {
  CONFIRM_EMAIL: "CONFIRM_EMAIL",
  CONFIRM_EMAIL_SUCCESS: "CONFIRM_EMAIL_SUCCESS",
  CONFIRM_EMAIL_FAILURE: "CONFIRM_EMAIL_FAILURE",
};

export const SIGN_OUT_ACTIONS = {
  SIGN_OUT: "SIGN_OUT",
  SIGN_OUT_SUCCESS: "SIGN_OUT_SUCCESS",
  SIGN_OUT_FAILURE: "SIGN_OUT_FAILURE",
};

export const ADD_USER = {
  ADD_USER: "ADD_USER",
  ADD_USER_SUCCESS: "ADD_USER_SUCCESS",
  ADD_USER_FAILURE: "ADD_USER_FAILURE",
};

export const GET_USER = {
  GET_USER: "GET_USER",
  GET_USER_SUCCESS: "GET_USER_SUCCESS",
  GET_USER_FAILURE: "GET_USER_FAILURE",
};

export const GET_CHILDREN = {
  GET_CHILDREN: "GET_CHILDREN",
  GET_CHILDREN_SUCCESS: "GET_CHILDREN_SUCCESS",
  GET_CHILDREN_FAILURE: "GET_CHILDREN_FAILURE",
};

export const GET_PARENT_EX_USERS = {
  GET_PARENT_EX_USERS: "GET_PARENT_EX_USERS",
  GET_PARENT_EX_USERS_SUCCESS: "GET_PARENT_EX_USERS_SUCCESS",
  GET_PARENT_EX_USERS_FAILURE: "GET_PARENT_EX_USERS_FAILURE",
};

export const GET_EXTERNAL_USER = {
  GET_EXTERNAL_USER: "GET_EXTERNAL_USER",
  GET_EXTERNAL_USER_SUCCESS: "GET_EXTERNAL_USER_SUCCESS",
  GET_EXTERNAL_USER_FAILURE: "GET_EXTERNAL_USER_FAILURE",
};

export const UPDATE_USER_STATUS = {
  UPDATE_USER_STATUS: "UPDATE_USER_STATUS",
  UPDATE_USER_STATUS_SUCCESS: "UPDATE_USER_STATUS_SUCCESS",
  UPDATE_USER_STATUS_FAILURE: "UPDATE_USER_STATUS_FAILURE",
};

export const UPDATE_EXUSER_STATUS = {
  UPDATE_EXUSER_STATUS: "UPDATE_EXUSER_STATUS",
  UPDATE_EXUSER_STATUS_SUCCESS: "UPDATE_EXUSER_STATUS_SUCCESS",
  UPDATE_EXUSER_STATUS_FAILURE: "UPDATE_EXUSER_STATUS_FAILURE",
};

export const UPDATE_USER = {
  UPDATE_USER: "UPDATE_USER",
  UPDATE_USER_SUCCESS: "UPDATE_USER_SUCCESS",
  UPDATE_USER_FAILURE: "UPDATE_USER_FAILURE",
};

export const UPDATE_CUST_USER = {
  UPDATE_CUST_USER: "UPDATE_CUST_USER",
  UPDATE_CUST_USER_SUCCESS: "UPDATE_CUST_USER_SUCCESS",
  UPDATE_CUST_USER_FAILURE: "UPDATE_CUST_USER_FAILURE",
};

export const REMOVE_USER = {
  REMOVE_USER: "REMOVE_USER",
  REMOVE_USER_SUCCESS: "REMOVE_USER_SUCCESS",
  REMOVE_USER_FAILURE: "REMOVE_USER_FAILURE",
};

//IMAGE LIB
export const ADD_IMAGE_LIB = {
  ADD_IMAGE_LIB: "ADD_IMAGE_LIB",
  ADD_IMAGE_LIB_SUCCESS: "ADD_IMAGE_LIB_SUCCESS",
  ADD_IMAGE_LIB_FAILURE: "ADD_IMAGE_LIB_FAILURE",
};
export const GET_IMAGE_LIB_LIST = {
  GET_IMAGE_LIB_LIST: "GET_IMAGE_LIB_LIST",
  GET_IMAGE_LIB_LIST_SEARCH_CAT: "GET_IMAGE_LIB_LIST_SEARCH_CAT",
  GET_IMAGE_LIB_LIST_SUCCESS: "GET_IMAGE_LIB_LIST_SUCCESS",
  GET_IMAGE_LIB_LIST_FAILURE: "GET_IMAGE_LIB_LIST_FAILURE",
};

export const GET_CATEGORIES = {
  GET_CATEGORIES: "GET_CATEGORIES",
  GET_CATEGORIES_SUCCESS: "GET_CATEGORIES_SUCCESS",
  GET_CATEGORIES_FAILURE: "GET_CATEGORIES_FAILURE",
};

export const GET_IMAGE_SKINTONES_ACTIONS = {
  GET_IMAGE_SKINTONES: "GET_IMAGE_SKINTONES",
  GET_IMAGE_SKINTONES_SUCCESS: "GET_IMAGE_SKINTONES_SUCCESS",
  GET_IMAGE_SKINTONES_FAILURE: "GET_IMAGE_SKINTONES_FAILURE",
};

export const IMAGE_UPLOAD_ACTIONS = {
  GET_PRESIGNED_URL: "GET_PRESIGNED_URL",
  IMAGE_UPLOAD: "IMAGE_UPLOAD",
  IMAGE_UPLOAD_SUCCESS: "IMAGE_UPLOAD_SUCCESS",
  IMAGE_UPLOAD_FAILURE: "IMAGE_UPLOAD_FAILURE",
};

export const ACTIVITY_ACTIONS = {
  ADD_ACTIVITY: "ADD_ACTIVITY",
  ADD_ACTIVITY_SUCCESS: "ADD_ACTIVITY_SUCCESS",
  ADD_ACTIVITY_FAILURE: "ADD_ACTIVITY_FAILURE",
  GET_ACTIVITIES :"GET_ACTIVITIES",
  GET_ACTIVITIES_SUCCESS :"GET_ACTIVITIES_SUCCESS",
  GET_ACTIVITIES_FAILURE :"GET_ACTIVITIES_FAILURE",
  VIEW_ACTIVITY :"VIEW_ACTIVITY",
  VIEW_ACTIVITY_SUCCESS :"VIEW_ACTIVITY_SUCCESS",
  VIEW_ACTIVITY_FAILURE :"VIEW_ACTIVITY_FAILURE",
  UPDATE_ACTIVITY :"UPDATE_ACTIVITY",
  UPDATE_ACTIVITY_SUCCESS :"UPDATE_ACTIVITY_SUCCESS",
  UPDATE_ACTIVITY_FAILURE :"UPDATE_ACTIVITY_FAILURE",

};

export const COLOR_LIST_ACTIONS = {
  GET_COLOR_LIST: "GET_COLOR_LIST",
  GET_COLOR_LIST_SUCCESS: "GET_COLOR_LIST_SUCCESS",
  GET_COLOR_LIST_FAILURE: "GET_COLOR_LIST_FAILURE",
}

export const SELF_REGULATION_ACTIONS = {
  ADD_SELF_REGULATION: "ADD_SELF_REGULATION",
  ADD_SELF_REGULATION_SUCCESS: "ADD_SELF_REGULATION_SUCCESS",
  ADD_SELF_REGULATION_FAILURE: "ADD_SELF_REGULATION_FAILURE",
  GET_SELF_REGULATION :"GET_SELF_REGULATION",
  GET_SELF_REGULATION_SUCCESS :"GET_SELF_REGULATION_SUCCESS",
  GET_SELF_REGULATION_FAILURE :"GET_SELF_REGULATION_FAILURE",
  VIEW_SELF_REGULATION :"VIEW_SELF_REGULATION",
  VIEW_SELF_REGULATION_SUCCESS :"VIEW_SELF_REGULATION_SUCCESS",
  VIEW_SELF_REGULATION_FAILURE :"VIEW_SELF_REGULATION_FAILURE",
  UPDATE_SELF_REGULATION :"UPDATE_SELF_REGULATION",
  UPDATE_SELF_REGULATION_SUCCESS :"UPDATE_SELF_REGULATION_SUCCESS",
  UPDATE_SELF_REGULATION_FAILURE :"UPDATE_SELF_REGULATION_FAILURE",
};

export const PAYMENT_ACTIONS = {

  GET_PAYMENT_LIST :"GET_PAYMENT_LIST",
  GET_PAYMENT_LIST_SUCCESS :"GET_PAYMENT_LIST_SUCCESS",
  GET_PAYMENT_LIST_FAILURE :"GET_PAYMENT_LIST_FAILURE",

  GET_PAYMENT_HISTORY :"GET_PAYMENT_HISTORY",
  GET_PAYMENT_HISTORY_SUCCESS :"GET_PAYMENT_HISTORY_SUCCESS",
  GET_PAYMENT_HISTORY_FAILURE :"GET_PAYMENT_HISTORY_FAILURE",

  CANCEL_SUBS:"CANCEL_SUBS",
  CANCEL_SUBS_SUCCESS:"CANCEL_SUBS_SUCCESS",
  CANCEL_SUBS_FAILURE:"CANCEL_SUBS_FAILURE",


  GENERAL:"GENERAL",
  GENERAL_SUCCESS:"GENERAL_SUCCESS",
  GENERAL_FAILURE:"GENERAL_FAILURE",




};



export const GET_NOTIFICATIONS_ACTIONS = {
  GET_NOTIFICATIONS: "GET_NOTIFICATIONS",
  GET_NOTIFICATIONS_CLEAR_OLD_DATA: "GET_NOTIFICATIONS_CLEAR_OLD_DATA",
  GET_NOTIFICATIONS_SUCCESS: "GET_NOTIFICATIONS_SUCCESS",
  GET_NOTIFICATIONS_FAILURE: "GET_NOTIFICATIONS_FAILURE",
};

export const MARK_NOTIFICATION_AS_READ_ACTIONS = {
  MARK_NOTIFICATION_AS_READ_SUCCESS: "MARK_NOTIFICATION_AS_READ_SUCCESS",
  MARK_ALL_READ_SUCCESS: "MARK_ALL_READ_SUCCESS",
};
