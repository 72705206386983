import { ADD_IMAGE_LIB, GET_IMAGE_LIB_LIST, IMAGE_UPLOAD_ACTIONS, GET_IMAGE_SKINTONES_ACTIONS } from "../../constants/ActionConstants";

let initialState = {
    loading: false,
    secondLoading: false,
    imageCategories: [],
    imageSkinTones: [],
    imagesList: [],
    metaImagesList: {},
    addedImage: {},
};

const imageLibrary = (state = initialState, action) => {

    switch (action.type) {
        case ADD_IMAGE_LIB.ADD_IMAGE_LIB:
            return {
                ...state,
                loading: true,
            };

        case ADD_IMAGE_LIB.ADD_IMAGE_LIB_FAILURE:
            return {
                ...state,
                loading: false
            };

        case ADD_IMAGE_LIB.ADD_IMAGE_LIB_SUCCESS:
            return {
                ...state,
                loading: false,
                addedImage: action.payload.data
            };



        case GET_IMAGE_LIB_LIST.GET_IMAGE_LIB_LIST_SEARCH_CAT:
            return {
                ...state,
                imagesList:[],
                loading:true,
            };

        case GET_IMAGE_LIB_LIST.GET_IMAGE_LIB_LIST:
            return {
                ...state,
                loading: action.payload.page > 1 ? false : true,
                secondLoading: action.payload.page > 1 ? true : false,
            };

        case GET_IMAGE_LIB_LIST.GET_IMAGE_LIB_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                secondLoading: false,
                imagesList: action.payload.data.meta.page > 1 ? [...state.imagesList, ...action.payload.data.data] : action.payload.data.data,
                metaImagesList: action.payload.data.meta,  
                
            };

        case GET_IMAGE_LIB_LIST.GET_IMAGE_LIB_LIST_FAILURE:
            return {
                ...state,
                loading: false,
                secondLoading: false,
            };

        case IMAGE_UPLOAD_ACTIONS.IMAGE_UPLOAD:
            return {
                ...state,
                loading: true,
            };
        case IMAGE_UPLOAD_ACTIONS.IMAGE_UPLOAD_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case IMAGE_UPLOAD_ACTIONS.IMAGE_UPLOAD_FAILURE:
            return {
                ...state,
                loading: false,
            };

        //-----------------

        case GET_IMAGE_SKINTONES_ACTIONS.GET_IMAGE_SKINTONES:
            return {
                ...state,
                loading: true,
            };

        case GET_IMAGE_SKINTONES_ACTIONS.GET_IMAGE_SKINTONES_SUCCESS:
            return {
                ...state,
                loading: false,
                imageSkinTones: action.payload.data,
            };

        case GET_IMAGE_SKINTONES_ACTIONS.GET_IMAGE_SKINTONES_FAILURE:
            return {
                ...state,
                loading: false,
            };

        //-----------------



        default:
            return state;
    }
};

export default imageLibrary;
