import { apiClient } from "./ApiClient";
import UrlConstants from "../../constants/UrlConstants";
import { handleResponse, handleError } from "./GenericResponseHandler";

const ITEMS_NO = 10;

class PaymentService {


    getPaymentList(page, q, field, order) {

        const encodedQuery = encodeURIComponent(q);
        var url = "";


        if (encodedQuery !== "" && field!=="" && order!=="") {
            url = UrlConstants.GET_PAYMENT_LIST + "?q=" + encodedQuery+ "&take=" + ITEMS_NO+"&page="+page+"&field="+field+"&order="+order;
        }
        else if (encodedQuery === "" && field!=="" && order!=="") {
            url = UrlConstants.GET_PAYMENT_LIST + "?take=" + ITEMS_NO+"&page="+page+"&field="+field+"&order="+order;
        }
        else if (encodedQuery !== "" && field==="") {
            url = UrlConstants.GET_PAYMENT_LIST + "?q="+encodedQuery+"&take=" + ITEMS_NO+"&page="+page;
        }
        else if (encodedQuery === "" && field==="" && order==="") {
            url = UrlConstants.GET_PAYMENT_LIST + "?take=" + ITEMS_NO+"&page="+page;
        }
        else {
            url = UrlConstants.GET_PAYMENT_LIST+ "?take=" + ITEMS_NO+"&page="+page
        }


        return apiClient()
            .get(url)
            .then(handleResponse)
            .catch(handleError);
    }
    
    getPaymentHistory(userId,subscriptionId, page) {

        let url = UrlConstants.GET_PAYMENT_HISTORY+"?userId="+userId+"&subscriptionId="+subscriptionId;
        // let url = UrlConstants.GET_PAYMENT_HISTORY+"?userId="+userId+"&subscriptionId="+subscriptionId+"&take="+ ITEMS_NO+"&page="+page;

        return apiClient()
            .get(url)
            .then(handleResponse)
            .catch(handleError);
    }
    
    cancelSubscription(data) {
        let url = UrlConstants.CANCEL_SUBSCRIPTION;
        
        return apiClient()
            .put(url, data)
            .then(handleResponse)
            .catch(handleError);
    }
    
    downloadInvoice(id) {
        let url = UrlConstants.DOWNLOAD_INVOICE+"?id="+id;
        
        return apiClient()
            .downloadGet(url)
            .then(handleResponse)
            .catch(handleError);
    }

}

export default new PaymentService();