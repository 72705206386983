import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  CHeader,
  CToggler,
  CHeaderNav
} from '@coreui/react'

import {
  TheHeaderDropdown
} from './index'
import { updateSideBar } from 'src/store/actions/SideBar'
import Notifications from 'src/views/components/Notifications'
import { ADMIN_CONST } from 'src/constants/GenericConstants'

const TheHeader = () => {
  const dispatch = useDispatch()
  const sidebarShow = useSelector(state => state.sidebarShow)
  const userState = useSelector((state) => state.authentication.user);

  // useEffect(() => {
  //   alert(JSON.stringify(userState.accounts.filter(u => u.role === ADMIN_CONST.SUPER_ADMIN).length))
  // }, [])

  const toggleSidebar = () => {
    const val = [true, 'responsive'].includes(sidebarShow.sidebarShow) ? false : 'responsive'
    dispatch(updateSideBar(val))
  }

  const toggleSidebarMobile = () => {
    const val = [false, 'responsive'].includes(sidebarShow.sidebarShow) ? true : 'responsive'
    dispatch(updateSideBar(val))
  }

  return (
    <CHeader withSubheader>
      <CToggler
        inHeader
        className="ml-md-3 d-lg-none"
        onClick={toggleSidebarMobile}
      />

      <CToggler
        inHeader
        className="ml-3 d-md-down-none"
        onClick={toggleSidebar}
      />


      <CHeaderNav className="px-3">
        {userState?.accounts.filter(u => u.role === ADMIN_CONST.SUPER_ADMIN).length > 0 ?
          <Notifications /> : null}
        <TheHeaderDropdown />
      </CHeaderNav>

    </CHeader>
  )
}

export default TheHeader
