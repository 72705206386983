import React from "react";

const Loader = (props) => {
    return (
        <div className="page-loader">
            <div className="loader"></div>
        </div>
    );
};

export default Loader;