import { LOCAL_STORAGE_KEYS } from 'src/constants/GenericConstants';
import { STATUS_CODES } from '../../constants/HttpStatusCodes';

export function handleResponse(response) {
    if (response.data.status && response.data.statusCode === STATUS_CODES.SUCCESS) {
        return response.data;
    } else {
        handleError(response);
    }
}

export function handleResponseSelfRegulation(response) {
    if (response.data.status && (response.data.statusCode === STATUS_CODES.FOR_SELF_CODE || response.data.statusCode === STATUS_CODES.SUCCESS)) {
        return response.data;
    } else {
        handleError(response);
    }
}

export function handleResponseSelfRegulationEdit(response) {
    if (response.data.status && (response.data.statusCode === STATUS_CODES.FOR_SELF_CODE_EDIT || response.data.statusCode === STATUS_CODES.SUCCESS)) {
        return response.data;
    } else {
        handleError(response);
    }
}

export function handleResponseCode(response) {
    if (response.data.status && response.data.statusCode === STATUS_CODES.VERIFY_CODE) {
        return response.data;
    } else {
        handleError(response);
    }
}

export function handleS3ImageUploadResponse(response) {
    if (response.status == 200) {
        return response.config.data.name;
    } else {
        handleError(response);
    }
}

export function handleError(errorResponse) {
    if (errorResponse?.response?.status === STATUS_CODES.UNAUTHORIZED) {
        const userGot = localStorage.getItem(LOCAL_STORAGE_KEYS.USER);

        if (userGot!==null) {
            localStorage.clear();
            window.location.reload();
            localStorage.setItem(LOCAL_STORAGE_KEYS.SHOW_EXPIRED_TOKEN_ERROR, true);
        }

    }
    throw errorResponse;
}