import {
    CModal, CModalBody, CModalFooter, CModalHeader, CButton,
    CCard,
    CCardBody,
    CCardHeader,
    CCol,
    CRow,
    CInput,
    CAlert,
    CImg,
    CInputCheckbox,
    CInputFile,
    CLabel,
} from "@coreui/react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { validateMandatoryValue, validateTextLength } from "src/helpers/Validation";
import { addImageLib, uploadImage, editImageLib, getPresignedURL, getImageSkintoneList } from "src/store/actions/ImageLib";
import LeavePage from "src/views/common/LeavePageModal";
import Cropper from 'react-easy-crop';
import { getCroppedImg } from "src/helpers/ImageCrop";
import { useTranslation } from "react-i18next";
import imageCompression from 'browser-image-compression';
import Loader from "src/views/common/Loader/Loader";
import { getTimeInMS } from "src/helpers/GenericHelper";

import SkinTonePicker from "./SkinTonePicker";


const EditImageModal = ({ openModal, handleModal, categoriesGot, data, setApiRes, editPerm }) => {

    const dispatch = useDispatch();


    const [childImage, setChildImage] = useState({});
    const [imageGot, setImageGot] = useState("");
    const [imageID, setImageID] = useState(0);
    const [imageTitleGot, setImageTitleGot] = useState("");
    const [imagePath, setImagePath] = useState("");
    const [selectedCat, setSelectedCat] = useState([]);
    const [categoriesList, setCategoriesList] = useState([]);
    const [leaveModal, setLeaveModal] = useState(false);
    const [compressing, setCompressing] = useState(false);


    const [imageTitleError, setImageTitleError] = useState("");
    const [selectedCatError, setSelectedCatError] = useState("");
    const [ImageError, setImageError] = useState("");
    const [formFilled, setFormFilled] = useState(false);
    const [change, setChange] = useState(false);

    const [loaded, setLoaded] = useState(false);


    const { t } = useTranslation();

    let croppedImageFile;
    var compressedFileTemp = {};



    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1.5);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
    const [sliderValue, setSliderValue] = useState(1.5);

    const [currentSkinToneId, setCurrentSkinToneId] = useState(data && data.skinToneId? data.skinToneId: 7); // default skin tone id 7
    const skinToneList = useSelector(state => state.imageLibrary.imageSkinTones);


    useEffect(() => {
        dispatch(getImageSkintoneList(onSkinToneFetchSuccess, onSkinToneFetchFailure));
    },[]);


    const handleSliderValueChange = (e) => {
        setSliderValue(e.target.value);
        setZoom(e.target.value);
    }

    const onCropComplete = (croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels);
    }


    const compressImage = (croppedImageFile) => {
        // setCompressing(true)
        var options = {
            maxSizeMB: 1,
            maxWidthOrHeight: 200,
            useWebWorker: true
        }
        if (croppedImageFile) {
            imageCompression(croppedImageFile, options)
                .then(function (compressedFile) {

                    setChildImage(prev => ({ ...prev, file: compressedFile }));
                    setImageError("")
                    setCompressing(false)
                    compressedFileTemp = compressedFile;
                    compressedFileTemp.name = getTimeInMS(compressedFileTemp.name);

                    dispatch(getPresignedURL(compressedFileTemp.name, onGetUrl, onFailedUrl))


                })
                .catch(function (error) {
                    setImageError("Compression failed!")
                });
        }
    }


    const checkChanged = () => {
        let changed = false;
        let sameValues = true;

        if (data) {
            const arr1 = selectedCat.concat().sort();
            const arr2 = data.categories.concat().sort();
            // for (let i = 0; i < arr1.length; i++) {
            //     if (arr1[i] !== arr2[i]) {
            //         sameValues = false
            //     }
            // }
            if (arr1.length !== arr2.length) {
                sameValues = false
            }

            if (imageTitleGot !== data.imageTitle || !sameValues || imageGot === "") {
                changed = true
            }
        }
        return changed;
    }

    const handleLeaveModal = () => {
        setLeaveModal(prev => !prev)
    }

    const LeaveThisPage = () => {
        clearAll()
        handleModal()
    }


    const handleModalClear = () => {
        if (checkChanged()) {
            setLeaveModal(true)
        } else {
            LeaveThisPage()
        }

    }



    const handleModalFromLeave = () => {

        clearAll()
        handleModal()

    }


    const clearAll = () => {

        setImageTitleGot("")
        setImageTitleError(null)

        setSelectedCat([])
        setSelectedCatError(null)

        setChildImage(null)
        setImageError(null)

        setFormFilled(null)
        setChange(null)

        setCategoriesList(null)

    }


    useEffect(() => {

        if (openModal && data) {
            setImageGot(data.imageURL)
            setImageID(data.id)
            setImageTitleGot(data.imageTitle)
            setSelectedCat([...data.categories])
            setCategoriesList(categoriesGot)
            setImagePath(data.imagePath)
        }
        return () => {

        }
    }, [data])



    useEffect(() => {
        setCategoriesList(categoriesGot)

    }, [change])

    const handleImageUpload = (event) => {
        event.preventDefault();
        let imageObject = null;
        let fileObject = null;

        if (event.target.files.length > 0) {
            fileObject = event.target.files[0];

            let fileName = fileObject.name
            var idxDot = fileName.lastIndexOf(".") + 1;
            var extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
            if (extFile == "jpg" || extFile == "jpeg" || extFile == "png") {
                setCompressing(true)
                const fileURL = URL.createObjectURL(fileObject);
                imageObject = new Image();
                imageObject.src = fileURL;
                imageObject.onload = () => {
                    setCompressing(false)
                }
                setChildImage({ file: fileObject, image: imageObject });
                setImageError("")
                setImageGot("")
                event.target.value = null

            }
            else {
                setImageError(t("typeInvalid"))
                setChildImage({});

            }

        }
        else {
            setImageError(t("imageRequired"))
        }

    }

    const validateImage = () => {
        if (imageGot === "") {
            if (Object.keys(childImage).length === 0) {
                setImageError(t("imageRequired"))

                return false;
            }
            else {
                setImageError("")
                return true;
            }
        }
        else {
            return true;
        }
    }
    const validateImageTitle = (imageTitleGot) => {
        if (!validateMandatoryValue(imageTitleGot)) {
            setImageTitleError(t("imageTitleRequired"))

            return false;
        }
        else if (!validateTextLength(imageTitleGot)) {
            setImageTitleError(t("imageTitleLength"))

            return false;
        }
        else {
            setImageTitleError("")
            return true;
        }
    }
    const validateCategories = (selectedCat) => {
        if (selectedCat.length === 0) {
            setSelectedCatError(t("catRequired"))

            return false;
        }
        else {
            setSelectedCatError("")
            return true;
        }
    }


    const isFormValid = () => {
        setFormFilled(true)
        return validateImage() & validateImageTitle(imageTitleGot) & validateCategories(selectedCat);
    }



    const onSubmit = async () => {
        if (isFormValid()) {

            if (imageGot === "") {
                setCompressing(true)
                getCroppedImg(childImage.image.src, croppedAreaPixels, childImage.file.type, 0, afterCrop);


            }
            else {
                dispatch(editImageLib({
                    "id": imageID,
                    "imageTitle": imageTitleGot,
                    "imagePath": imagePath,
                    "categories": selectedCat,
                    "skinToneId": currentSkinToneId
                }, onAddImageSuccess, onAddImageFailure));
            }
        }
        else {
        }
    }
    const afterCrop = (blob) => {
        croppedImageFile = new File([blob], childImage.file.name, { type: blob.type });
        compressImage(croppedImageFile)
    }


    const onGetUrl = (res) => {
        dispatch(uploadImage(compressedFileTemp, res.data.url,
            (res) => {
                console.log("Success Called", res);
                dispatch(editImageLib({
                    "id": imageID,
                    "imagePath": compressedFileTemp.name,
                    "imageTitle": imageTitleGot,
                    "categories": selectedCat,
                }, onAddImageSuccess, onAddImageFailure));
            }
            ,
            (err) => {
                console.log("Error Called", err);
            }
        ))
    }




    const onAddImageSuccess = (res) => {
        setApiRes("Image edited successfully!")
        LeaveThisPage()
    }

    const onAddImageFailure = (err) => {
        setApiRes("Error: Operation Failed! " + err)
    }
    const onFailedUrl = (err) => {
    }

    const onSkinToneFetchSuccess = (res) => {}

    const onSkinToneFetchFailure = (res) => {}

    const onSkinToneChange = (skinToneId) => {
        setCurrentSkinToneId(skinToneId);
    }


    return (
        <CModal
            show={openModal}
            onClose={() => handleModalClear()}
            size="xl"
            closeOnBackdrop={false}
        >
            <CModalHeader > {t("editImage")} </CModalHeader>
            <CModalBody>

                {compressing && <Loader />}

                <CRow>
                    <CCol xl={6}>
                        <CCard>

                            <CCardBody className="text-center imgLoader">
                                {/* <div className="profile-image-section"> */}
                                {/* <figure> */}

                                {
                                    childImage && childImage.image ?
                                        <>
                                            <div className="imgCropContainer">
                                                <Cropper
                                                    image={childImage.image.src}
                                                    crop={crop}
                                                    zoom={zoom}
                                                    aspect={1}
                                                    showGrid={false}
                                                    onCropChange={(value) => {
                                                        setCrop(value)
                                                    }}
                                                    onCropComplete={onCropComplete}
                                                />
                                            </div>
                                            <div className="imgCropSlider">
                                                <div className="slider">
                                                    <div className="c-slider">
                                                        <input type="range" min={1} max={3} step={0.1} value={sliderValue} onChange={handleSliderValueChange}></input>
                                                    </div>
                                                </div>
                                            </div>

                                        </>

                                        :
                                        imageGot !== "" ?
                                            <>
                                                {!loaded ? <p className="loader"></p> : null}

                                                <CImg
                                                    style={{ width: "350px", marginBottom: "20px" }}
                                                    alt="Image Lib picture"
                                                    onLoad={
                                                        () => {
                                                            setLoaded(true)
                                                        }
                                                    }
                                                    src={imageGot} />
                                            </>
                                            :
                                            <CImg
                                                style={{ width: "350px" }}
                                                alt="Image Lib picture"
                                                src="https://i.stack.imgur.com/l60Hf.png" />
                                }

                                {/* </figure> */}
                                {editPerm &&
                                    <div className="upload-photo">
                                        <div className="btn upload-btn">
                                            {t("updateImage")}
                                            <CInputFile id="childImage" name="childImage"
                                                disabled={!editPerm}
                                                accept="image/*"
                                                type="file" onChange={handleImageUpload} />
                                            {
                                                ImageError && <CLabel className="field-error">{ImageError}</CLabel>
                                            }
                                        </div>
                                    </div>
                                }
                                {/* </div> */}


                            </CCardBody>



                        </CCard>
                    </CCol>

                    <CCol xl={6}>
                        <CCard>

                            <CCardBody>
                                <CLabel>{t("imageTitle")}</CLabel>
                                <CInput
                                    placeholder="Image Name"
                                    size="md"
                                    disabled={!editPerm}
                                    className={editPerm ? "" : "disabled-item"}
                                    value={imageTitleGot}
                                    onChange={(e) => {
                                        setImageTitleGot(e.target.value)
                                        if (formFilled) {
                                            validateImageTitle(e.target.value)
                                        }
                                    }}

                                />
                                {
                                    imageTitleError && <CLabel className="field-error">{imageTitleError}</CLabel>
                                }
                                <br></br>

                                <CLabel>{t("skinTone")} </CLabel>

                                <SkinTonePicker 
                                    skinToneData={skinToneList} 
                                    skinToneId={currentSkinToneId}
                                    onSkinToneChange={onSkinToneChange}
                                />

                                <CLabel>{t("imageCat")} </CLabel>

                                <div className="check-filters my-0">
                                    {categoriesList && categoriesList.length !== 0 && categoriesList.map(cat => {
                                        var exist = false;

                                        if (selectedCat && selectedCat.includes(cat.name)) {
                                            exist = true;
                                        }


                                        return (

                                            <div className="filter-items">

                                                <CInputCheckbox
                                                    id="checkbox1"
                                                    className={editPerm ? "btn btn-pill btn-dark mr-1" : "btn btn-pill btn-dark mr-1 disabled-item"}
                                                    name={cat.name}
                                                    disabled={!editPerm}
                                                    value={cat.name}
                                                    checked={exist}
                                                    onChange={(e) => {
                                                        var catSelected = selectedCat;

                                                        if (e.target.checked === true) {
                                                            catSelected.push(e.target.value)
                                                            setSelectedCat(catSelected)
                                                            setChange(prev => !prev)
                                                        }
                                                        else {
                                                            catSelected = catSelected.filter(item => item !== e.target.value)
                                                            setSelectedCat(catSelected)
                                                            setChange(prev => !prev)
                                                        }

                                                        if (formFilled) {
                                                            validateCategories(catSelected)
                                                        }

                                                    }}
                                                />
                                                <span>{cat.value}</span>


                                            </div>
                                        )
                                    })}
                                </div>
                                {
                                    selectedCatError && <CLabel className="field-error">{selectedCatError}</CLabel>
                                }
                            </CCardBody>



                        </CCard>
                    </CCol>
                </CRow>


                <LeavePage openModal={leaveModal} handleModal={handleLeaveModal} leaveThis={LeaveThisPage} />


            </CModalBody>
            <CModalFooter className="footer-has-primary-secondary-btn">
                <CButton color="primary" disabled={!editPerm} onClick={() => onSubmit()} > {t("save")} </CButton>{' '}
                <CButton color="primary" onClick={() => handleModalClear()} > {t("cancel")} </CButton>{' '}

            </CModalFooter>






        </CModal>

    );
};

export default EditImageModal;